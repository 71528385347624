import {
  GET_ADMIN_MANAGEMENT,
  GET_ADMIN_MANAGEMENT_LIST,
  GET_DETAIL_ADMIN_MANAGEMENT,
} from "../constant.js";

const initialState = {
  dataAdmin: [],
  dataAdminList: [],
  dataAdminPagination: {},
  detailAdminManagement: {},
};

const AdminManagementReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_ADMIN_MANAGEMENT: {
      return {
        ...state,
        dataAdmin: action?.payload.data || [],
        dataAdminPagination: action?.payload.pagination || {}
      };
    }
    case GET_ADMIN_MANAGEMENT_LIST: {
      return {
        ...state,
        dataAdminList: action?.payload.data || [],
      };
    }
    case GET_DETAIL_ADMIN_MANAGEMENT: {
      return {
        ...state,
        detailAdminManagement: action?.payload.data || {}
      };
    }
    default: {
      return state;
    }
  }
};

export default AdminManagementReducer;
