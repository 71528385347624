import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { getAllCity } from 'app/redux/actions/CityActions';
import MenuActions from "../../components/MenuActions";
import CustomLayout from "../../components/CustomLayout";

const TableKotaKab = () => {
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();

  const { dataAllCity } = useSelector(
    (state) => state.city
  )

  const getData =() => {
    dispatch(getAllCity());
  };

  useEffect(() => {
    getData();
  }, [dispatch]);

  const cityColumns = [
    { header: "No", field: "id" },
    { header: "Nama Provinsi", field: "province" },
    { header: "Nama Kota/Kabupaten", field: "name" },
    {
      header: "Aksi",
      render: (rowData) => (
        <MenuActions
          editPath="/master_data/data_lainnya/data_kota_kabupaten/edit"
          data={rowData}
          item={rowData}
          state={rowData}
        />
      ),
      field: 'aksi'
    },
  ];

  const searchedDataAllCity = dataAllCity?.data?.data?.data.filter(({ name, province }) =>
    name.toLowerCase().includes(search.toLowerCase()) ||
    province.toLowerCase().includes(search.toLowerCase())
  );


  return (
    <CustomLayout
      subtitle="Kota/Kabupaten"
      columns={cityColumns}
      data={searchedDataAllCity}
      button="Tambah Kota/Kab"
      addPath="/master_data/data_lainnya/data_kota_kabupaten/tambah"
      search={search}
      setSearch={setSearch}
    />
  );
};

export default TableKotaKab;
