import {
    GET_ALL_PROVINCE,
    GET_PROVINCE,
    GET_PROVINCE_NAME
} from "../constant.js";

const initialState = {
    dataProvince: [],
    dataProvinceName: {},
    dataAllProvince: [],
};

const ProvinceReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_PROVINCE: {
            return {
                ...state,
                dataProvince: action.payload,
            };
        }
        case GET_PROVINCE_NAME: {
            return {
                ...state,
                dataProvinceName: action.payload,
            };
        }
        case GET_ALL_PROVINCE: {
            return {
                ...state,
                dataAllProvince: action.payload,
            }
        }
        default: {
            return state;
        }
    }
};

export default ProvinceReducer;
