import { authRoles } from 'app/auth/authRoles'
import UserManagement from './UserManagement'
import AddUser from './AddUser'
import DetailUser from './DetailUser'
import EditUser from './EditUser'

const UserManagementRoutes = [
    {
        path: '/user_management',
        exact: true,
        component: UserManagement,
        auth: authRoles.superadm
    },
    {
        path: '/user_management/tambah',
        exact: true,
        component: AddUser,
        auth: authRoles.superadm
    },
    {
        path: '/user_management/detail/:users_code',
        exact: true,
        component: DetailUser,
        auth: authRoles.superadm
    },
    {
        path: '/user_management/edit/:users_code',
        exact: true,
        component: EditUser,
        auth: authRoles.superadm
    },
]

export default UserManagementRoutes
