import { authRoles } from 'app/auth/authRoles'
import AdminManagement from './AdminManagement'
import AddAdmin from './AddAdmin'
import DetailAdmin from './DetailAdmin'
import EditAdmin from './EditAdmin'

const AdminManagementRoutes = [
    {
        path: '/adm_management',
        exact: true,
        component: AdminManagement,
        auth: authRoles.superadm
    },
    {
        path: '/adm_management/tambah',
        exact: true,
        component: AddAdmin,
        auth: authRoles.superadm
    },
    {
        path: '/adm_management/detail/:users_code',
        exact: true,
        component: DetailAdmin,
        auth: authRoles.superadm
    },
    {
        path: '/adm_management/edit/:users_code',
        exact: true,
        component: EditAdmin,
        auth: authRoles.superadm
    },
]

export default AdminManagementRoutes
