/* eslint-disable react-hooks/exhaustive-deps */
import {
    Card,
    Grid,
    TextField,
    InputLabel,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import GreenButton from "app/components/GreenButton";
  import { getDetailPembayaran } from "app/redux/actions/PembayaranActions";

const DetailBankPembayaran = (props) => {
    const bank_code = props.match.params.bank_code;
    const dispatch = useDispatch();
    const history = useHistory();
    const [state, setState] = useState({
        bank_code: bank_code,
        bank_image: '',
        bank_image_preview: '',
        bank_name: '',
        bank_user_fullname: '',
        bank_rekening_number: '',
        bank_total_amount: '',
    });
    const [errorField, setErrorField] = useState({});
    const { detailPembayaran } = useSelector(
        (state) => state.pembayaran
    );

    const getData = () => {
        dispatch(getDetailPembayaran(bank_code));
    };

    useEffect(() => {
        getData()
        setState((prevState) => ({
            ...prevState,
            bank_name: detailPembayaran.bank_name,
            bank_image: detailPembayaran.bank_image,
            bank_image_preview: detailPembayaran.bank_image_preview,
            bank_user_fullname: detailPembayaran.bank_user_fullname,
            bank_rekening_number: detailPembayaran.bank_rekening_number,
            bank_total_amount: detailPembayaran.bank_total_amount,
        }));
    }, [detailPembayaran.bank_code]);

    return (
        <div className="p-40">
            <Grid
                container
                spacing={3}
                className="mb-2 d-flex justify-content-center align-items-center"
            >
                <Grid item lg={6} md={6} xs={12} sm={6}>
                    <h2 className="font-size-32 fw-700 fw-bold">Detail Bank</h2>
                </Grid>
                <Grid
                    item
                    lg={6}
                    md={6}
                    xs={12}
                    sm={6}
                    spacing={2}
                    container
                    justify="flex-end"
                >
                    <Grid item>
                        <GreenButton
                            text="Kembali"
                            onClick={() => history.push("/master_data/setting")}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Card style={{ padding: "20px" }}>
                <Grid container className="p-4" spacing={2}>
                    <Grid item lg={12} md={12} xs={12} sm={12}>
                        <h3 className="fw-600 fs-24">Detail Bank</h3>
                    </Grid>
                    <Grid item className="mb-2" lg={6} md={6} xs={12} sm={12}>
                        <InputLabel htmlFor="bank_image">
                            <h5 className="font-size-14">Foto</h5>
                            <img src={state.bank_image} alt={state.bank_user_fullname} />
                            <small className="text-danger">{errorField?.bank_image}</small>
                        </InputLabel>
                    </Grid>

                    <Grid item className="mb-2" lg={6} md={6} xs={12} sm={12}></Grid>

                    <Grid item className="mb-2" lg={6} md={6} xs={12} sm={12}>
                        <InputLabel htmlFor="bank_name">
                            <h5 className="font-size-14">Nama Bank</h5>
                        </InputLabel>
                        <TextField
                            required={true}
                            size="small"
                            value={state.bank_name}
                            name="bank_name"
                            className={`w-100`}
                            InputProps={{
                                style: {
                                    borderRadius: 5,
                                },
                            }}
                            placeholder="Nama Bank"
                            variant="outlined"
                            type="text"
                        />
                        <small className="text-danger">{errorField?.bank_name}</small>
                    </Grid>
                    <Grid item className="mb-2" lg={6} md={6} xs={12} sm={12}>
                        <InputLabel htmlFor="bank_user_fullname">
                            <h5 className="font-size-14">Pemilik</h5>
                        </InputLabel>
                        <TextField
                            required={true}
                            size="small"
                            value={state.bank_user_fullname}
                            name="bank_user_fullname"
                            className={`w-100`}
                            InputProps={{
                                style: {
                                    borderRadius: 5,
                                },
                            }}
                            placeholder="Pemilik"
                            variant="outlined"
                            type="text"
                        />
                        <small className="text-danger">{errorField?.bank_user_fullname}</small>
                    </Grid>

                    <Grid item className="mb-2" lg={6} md={6} xs={12} sm={12}>
                        <InputLabel htmlFor="bank_rekening_number">
                            <h5 className="font-size-14">Nomor Rekening</h5>
                        </InputLabel>
                        <TextField
                            required={true}
                            size="small"
                            value={state.bank_rekening_number}
                            name="bank_rekening_number"
                            className={`w-100`}
                            InputProps={{
                                style: {
                                    borderRadius: 5,
                                },
                            }}
                            placeholder="Nomor Rekening"
                            variant="outlined"
                            type="text"
                        />
                        <small className="text-danger">{errorField?.bank_rekening_number}</small>
                    </Grid>
                    <Grid item className="mb-2" lg={6} md={6} xs={12} sm={12}>
                        <InputLabel htmlFor="bank_total_amount">
                            <h5 className="font-size-14">Masukan Jumlah</h5>
                        </InputLabel>
                        <TextField
                            required={true}
                            size="small"
                            value={state.bank_total_amount}
                            name="bank_total_amount"
                            className={`w-100`}
                            InputProps={{
                                style: {
                                    borderRadius: 5,
                                },
                            }}
                            placeholder="Masukan Jumlah"
                            variant="outlined"
                            type="text"
                        />
                        <small className="text-danger">{errorField?.bank_total_amount}</small>
                    </Grid>

                </Grid>
            </Card>
        </div>
    );
};

export default DetailBankPembayaran;
