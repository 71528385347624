/* eslint-disable react-hooks/exhaustive-deps */
import { Card } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MenuActions from "../components/MenuActions";
import CustomLayout from "../components/CustomLayout";
import {
  getDataCategoryLogistic,
  deleteCategoryLogistic,
} from "app/redux/actions/CategoryLogisticActions";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import {
  getDataLogistikSatuanUnit,
  deleteLogistikSatuanUnit,
} from "app/redux/actions/LogistikSatuanUnitActions";

const MasterLogistik = () => {
  const dispatch = useDispatch();
  const { dataKategoriLogistik } = useSelector(
    (state) => state.categoryLogistic
  );
  const { dataLogistikSatuanUnit } = useSelector(
    (state) => state.logistikSatuanUnit
  );

  const deleteDataLogistikSatuanUnit = (code) => {
    const payload = {
      kategori_unit_code: code,
    };
    deleteLogistikSatuanUnit(payload)
      .then((res) => {
        console.log(res);
        Swal.fire({
          title: '<div class="custom-swal-title">Data berhasil dihapus!</div>',
          buttonsStyling: false,
          icon: "success",
          customClass: {
            confirmButton: "custom-success-button",
          },
        });
        getData();
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: '<div class="custom-swal-title">Data gagal dihapus!</div>',
          icon: 'error',
          buttonsStyling: false,
          customClass: {
            confirmButton: "custom-error-button",
          },
        });
      });
  };
  const deleteDataCategoryLogistic = (code) => {
    deleteCategoryLogistic(code)
      .then((res) => {
        console.log(res);
        Swal.fire({
          title: '<div class="custom-swal-title">Data berhasil dihapus!</div>',
          buttonsStyling: false,
          icon: "success",
          customClass: {
            confirmButton: "custom-success-button",
          },
        });
        getData();
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: '<div class="custom-swal-title">Data gagal dihapus!</div>',
          buttonsStyling: false,
          icon: "error",
          customClass: {
            confirmButton: "custom-error-button",
          },
        });
      });
  };

  const logistikColumns = [
    { header: "No", field: "no" },
    { header: "Nama Kategori Logistik", field: "kategori_logistik_nama" },
    {
      header: "Aksi",
      render: (rowData) => (
        <MenuActions
          editPath={`/master_data/data_lainnya/logistik/edit/${rowData.kategori_logistik_code}`}
          data={rowData}
          // item={rowData}
          // state={rowData}
          // onDelete={(item) =>
          //   deleteDataCategoryLogistic(item.kategori_logistik_code)
          // }
          deleteActions={() => deleteDataCategoryLogistic(rowData.kategori_logistik_code)}
          item={rowData}
          state={rowData}
        />
      ),
      field: "aksi",
    },
  ];

  const satuanColumns = [
    { header: "No", field: "no" },
    { header: "Nama Satuan Unit", field: "kategori_unit_nama" },
    {
      header: "Aksi",
      render: (rowData) => (
        <MenuActions
          editPath={`/master_data/data_lainnya/logistik_satuan/edit/${rowData.kategori_unit_code}`}
          data={rowData}
          // item={rowData}
          // state={rowData}
          // onDelete={(item) =>
          //   deleteDataLogistikSatuanUnit(item.kategori_unit_code)
          // }
          deleteActions={() => deleteDataLogistikSatuanUnit(rowData.kategori_unit_code)}
          item={rowData}
          state={rowData}
        />
      ),
      field: "aksi",
    },
  ];

  const getData = () => {
    dispatch(getDataCategoryLogistic());
    dispatch(getDataLogistikSatuanUnit());
  };

  const [searchKategoriLogistik, setSearchKategoriLogistik] = useState("");
  const [searchSatuanUnit, setSearchSatuanUnit] = useState("");

  const dataKategoriLogistikWithNo = dataKategoriLogistik
    .filter((item) =>
      item.kategori_logistik_nama
        .toLowerCase()
        .includes(searchKategoriLogistik.toLowerCase())
    )
    .map((item, index) => ({
      ...item,
      no: index + 1,
    }));

  const dataLogistikSatuanUnitWithNo = dataLogistikSatuanUnit
    .filter((item) =>
      item.kategori_unit_nama
        .toLowerCase()
        .includes(searchSatuanUnit.toLowerCase())
    )
    .map((item, index) => ({
      ...item,
      no: index + 1,
    }));

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Card className="w-100 overflow-auto p-24 mb-3" elevation={6}>
        <CustomLayout
          title="Data Logistik"
          subtitle="Kategori"
          columns={logistikColumns}
          data={dataKategoriLogistikWithNo}
          button="Tambah Kategori"
          addPath="/master_data/data_lainnya/logistik/tambah"
          search={searchKategoriLogistik}
          setSearch={setSearchKategoriLogistik}
        />
        <CustomLayout
          subtitle="Satuan Unit"
          columns={satuanColumns}
          data={dataLogistikSatuanUnitWithNo}
          button="Tambah Satuan Unit"
          addPath="/master_data/data_lainnya/logistik_satuan/tambah"
          search={searchSatuanUnit}
          setSearch={setSearchSatuanUnit}
        />
      </Card>
    </>
  );
};

export default MasterLogistik;
