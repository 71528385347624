import React from 'react'
import ReactEcharts from 'echarts-for-react'
import { withStyles } from '@material-ui/styles'
import { Grid, Card } from '@material-ui/core'
import { FiberManualRecord } from '@material-ui/icons'

const CardGrafikDoughnut = ({ height, color = [], theme, data = [], title, cut = 2 }) => {
  const option = {
    legend: {
      show: false,
      itemGap: 20,
      icon: 'circle',
      bottom: 0,
      textStyle: {
        color: theme.palette.text.secondary,
        fontSize: 13,
        fontFamily: 'Inter'
      }
    },
    tooltip: {
      show: false,
      trigger: 'item',
      formatter: '{a} <br/>{b}: {c} ({d}%)'
    },
    xAxis: [
      {
        axisLine: {
          show: false
        },
        splitLine: {
          show: false
        }
      }
    ],
    yAxis: [
      {
        axisLine: {
          show: false
        },
        splitLine: {
          show: false
        }
      }
    ],

    series: [
      {
        name: 'Traffic Rate',
        type: 'pie',
        radius: ['30%', '72.55%'],
        center: ['50%', '50%'],
        avoidLabelOverlap: false,
        hoverOffset: 5,
        stillShowZeroSum: false,
        label: {
          normal: {
            show: false,
            position: 'center', // shows the description data to center, turn off to show in right side
            textStyle: {
              color: theme.palette.text.secondary,
              fontSize: 13,
              fontFamily: 'Inter'
            },
            formatter: '{a}'
          },
          emphasis: {
            show: true,
            textStyle: {
              fontSize: '14',
              fontWeight: 'normal'
              // color: "rgba(15, 21, 77, 1)"
            },
            formatter: '{b} \n{c} ({d}%)'
          }
        },
        labelLine: {
          normal: {
            show: false
          }
        },
        data: data,
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  }

  return (
    <Card className='p-3'>
      <div>
        <p className="text-grey">Statistics</p>
        <h2 className="fs-20 fw-600">{title}</h2>
      </div>
      <hr />
      <Grid
        container
        spacing={3}
        className='mb-4 px-2'
        alignItems='center'
        // justifyContent='space-between'
      >
        <Grid item lg={12} md={12} sm={12} xs={12} className='p-0'>
          <ReactEcharts
            style={{ height: height }}
            option={{
              ...option,
              color: [...color]
            }}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className='p-0'>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={6} xs={6} className='p-0'>
              {data.slice(0, cut).map((item, index) => (
                <React.Fragment key={index}>
                  <div className='d-flex flex-row align-items-center' key={item}>
                    <FiberManualRecord htmlColor={color.slice(0, cut)[index]} />
                    <h5 className='fs-14 ms-2 mb-0'>{item.name}</h5>
                  </div>
                  <h4 className='ml-33 mt-1 fs-20 fw-600'>{item.value}</h4>
                </React.Fragment>
              ))}
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6} className='p-0'>
              {data.slice(cut).map((item, index) => (
                <React.Fragment key={index}>
                  <div className='d-flex flex-row align-items-center' key={item}>
                    <FiberManualRecord htmlColor={color.slice(cut)[index]} />
                    <h5 className='fs-14 ms-2 mb-0'>{item.name}</h5>
                  </div>
                  <h4 className='ml-33 mt-1 fs-20 fw-600'>{item.value}</h4>
                </React.Fragment>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}

export default withStyles({}, { withTheme: true })(CardGrafikDoughnut)
