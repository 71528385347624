import {
    GET_DPT,
} from "../constant.js";

const initialState = {
    dataDpt: [],
    dataDptPagination: {},
};

const DptReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_DPT: {
            return {
                ...state,
                dataDpt: action.payload.data?.data,
                dataDptPagination: action.payload.pagination,
            };
        }
        default: {
            return state;
        }
    }
};

export default DptReducer;
