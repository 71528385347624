import { API, setAuthToken } from 'config/API.js'
import { GET_USER_MANAGEMENT, GET_DETAIL_USER_MANAGEMENT, GET_USER_MANAGEMENT_LIST } from '../constant.js'

export const getDataUserManagement = (params = '') => {
  return dispatch => {
    dispatch({
      type: GET_USER_MANAGEMENT,
      payload: {
        data: [],
        pagination: {
          current_page: 1,
          last_page: 1,
          per_page: 10,
          total: 1
        }
      }
    })

    const token = localStorage.getItem('jwt_token')
    setAuthToken(token)
    return API.get(`/admin/usermanagement?${new URLSearchParams(params)}`)
      .then(res => {
        dispatch({
          type: GET_USER_MANAGEMENT,
          payload: res.data.data || []
        })
      })
      .catch(() => {
        dispatch({
          type: GET_USER_MANAGEMENT,
          payload: []
        })
      })
  }
}

export const getDataUserManagementList = () => {
  return dispatch => {
    const token = localStorage.getItem('jwt_token')
    setAuthToken(token)
    API.get(`/admin/usermanagement/list`)
      .then(res => {
        dispatch({
          type: GET_USER_MANAGEMENT_LIST,
          payload: res.data || {}
        })
      })
      .catch(() => {
        dispatch({
          type: GET_USER_MANAGEMENT_LIST,
          payload: {}
        })
      })
  }
}

export const getDetailUserManagement = (users_code = '') => {
  return dispatch => {
    const token = localStorage.getItem('jwt_token')
    setAuthToken(token)
    API.get(`/admin/usermanagement/` + users_code)
      .then(res => {
        dispatch({
          type: GET_DETAIL_USER_MANAGEMENT,
          payload: res.data || {}
        })
      })
      .catch(() => {
        dispatch({
          type: GET_DETAIL_USER_MANAGEMENT,
          payload: {}
        })
      })
  }
}

export const addUserManagement = body => {
  const token = localStorage.getItem('jwt_token')
  setAuthToken(token)
  return API.post('/admin/usermanagement/add', body)
}

export const updateUserManagement = body => {
  const token = localStorage.getItem('jwt_token')
  setAuthToken(token)
  return API.post('/admin/usermanagement/update', body)
}

export const deleteUserManagement = body => {
  const token = localStorage.getItem('jwt_token')
  setAuthToken(token)
  return API.post('/admin/usermanagement/delete', body)
}
