import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { Box } from "@mui/material";
import Swal from "sweetalert2";
import { addProvince } from "app/redux/actions/ProvinceActions";
import HeadTitle from "../../components/HeadTitle";

const AddProvinsi = () => {
  const history = useHistory();
  const [state, setState] = useState({
    pulau: "",
    name: "",
  });
  const [errorField, setErrorField] = useState({});
  const dataPulau = [
    {
      pulau_code: "sumatera",
      pulau_nama: "Sumatera"
    },
    {
      pulau_code: "jawa",
      pulau_nama: "Jawa"
    },
    {
      pulau_code: "nusatenggara",
      pulau_nama: "Nusa Tenggara"
    },
    {
      pulau_code: "kalimantan",
      pulau_nama: "Kalimantan"
    },
    {
      pulau_code: "sulawesi",
      pulau_nama: "Sulawesi"
    },
    {
      pulau_code: "maluku",
      pulau_nama: "Maluku"
    },
    {
      pulau_code: "papua",
      pulau_nama: "Papua"
    },
  ]

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.name === "pulau" ?
        e.target.value.toLowerCase() :
        e.target.value.toUpperCase(),
    }));
  };

  const handleSubmit = () => {
    addProvince(state)
      .then((res) => {
        console.log(res);
        Swal.fire({
          title: '<div class="custom-swal-title">Data berhasil disimpan</div>',
          buttonsStyling: false,
          icon: "success",
          customClass: {
            confirmButton: "custom-success-button",
          },
        });
        history.push("/master_data/data_lainnya");
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: '<div class="custom-swal-title">Data gagal disimpan!</div>',
          buttonsStyling: false,
          icon: "error",
          customClass: {
            confirmButton: "custom-error-button",
          },
        });
        setErrorField(err.response.data.data);
      });
  };

  return (
    <>
      <Box className="p-40">
      <HeadTitle
          title="Tambah Nama Provinsi"
          onClick={handleSubmit}
          backPath="/master_data/data_lainnya"
          btnText="Simpan Data"
        />

        <Card className="w-100 overflow-auto p-24 mb-3">
          <Grid container className="p-2 " spacing={2}>
            <Grid item lg={6} md={6} xs={12} sm={12}>
              <InputLabel htmlFor="laporan_status">
                <h5 className="font-size-14">Pilih Pulau</h5>
              </InputLabel>
              <Select
                SelectDisplayProps={{
                  style: {
                    paddingTop: 10,
                    paddingBottom: 10,
                    borderRadius: 5,
                    backgroundColor: "#FBFBFB",
                  },
                }}
                label="pulau"
                size="small"
                labelid="pulau"
                value={state.pulau}
                onChange={handleChange}
                variant="outlined"
                className="w-100"
                name="pulau"
                displayEmpty
              >
                <MenuItem key={"x"} value={""} disabled>
                  Pilih Pulau
                </MenuItem>
                {dataPulau.map((item) => (
                  <MenuItem key={item.pulau_code} value={item.pulau_code}>
                    {item.pulau_nama}
                  </MenuItem>
                ))}
              </Select>
              <small className="text-danger">{errorField?.pulau}</small>
            </Grid>
            <Grid item lg={6} md={6} xs={12} sm={12}>
              <InputLabel htmlFor="name">
                <h5 className="font-size-14">Nama Provinsi</h5>
              </InputLabel>
              <TextField
                required={true}
                size="small"
                labelid="name"
                name="name"
                className={`w-100`}
                value={state.name}
                InputProps={{
                  style: {
                    borderRadius: 5,
                  },
                }}
                placeholder="Nama Provinsi"
                variant="outlined"
                type="text"
                error={errorField?.name !== ""}
                helperText={errorField?.name}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Card>
      </Box>
    </>
  );
};

export default AddProvinsi;
