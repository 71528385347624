import {
  GET_USER_MANAGEMENT,
  GET_USER_MANAGEMENT_LIST,
  GET_DETAIL_USER_MANAGEMENT,
} from "../constant.js";

const initialState = {
  dataUser: [],
  dataUserList: [],
  dataUserPagination: {},
  detailUserManagement: {},
};

const UserManagementReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_USER_MANAGEMENT: {
      return {
        ...state,
        dataUser: action?.payload.data || [],
        dataUserPagination: action?.payload.pagination || {}
      };
    }
    case GET_USER_MANAGEMENT_LIST: {
      return {
        ...state,
        dataUserList: action?.payload.data || [],
      };
    }
    case GET_DETAIL_USER_MANAGEMENT: {
      return {
        ...state,
        detailUserManagement: action?.payload.data || {}
      };
    }
    default: {
      return state;
    }
  }
};

export default UserManagementReducer;
