import React, { useState, useEffect } from "react";
import { Card, CardContent, CardMedia, Grid, Typography, Paper, Box } from "@material-ui/core";
import { useLocation, useHistory, Link } from "react-router-dom";
import { formatCurrency } from "helpers/formatCurrency";
import { ContentCopy } from "@mui/icons-material";
import { connect, useDispatch, useSelector } from "react-redux";
import TableAdmins from "./TableAdmins";

// import NavTabs from "app/components/fragment/NavTabs";
const AdminManagement = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const getData = () => {
    };

    useEffect(() => {
        // getData();
    }, []);


    return (
        <div className="p-40">
            <div className="mb-sm-30">
                <h2 className="fw-700 fw-bold">Administrator Management</h2>
            </div>

            <Card className="w-100 overflow-auto p-24">
               <TableAdmins />
            </Card>

        </div>
    );
};

export default AdminManagement;
