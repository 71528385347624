/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Grid,
  TableContainer,
  Icon,
  InputAdornment,
  TextField,
  Hidden,
  Box,
} from "@material-ui/core";
import GeneralButton from "app/components/GeneralButton";
import { Link } from "react-router-dom";
import { AddCircleOutline } from "@material-ui/icons";
import MenuActions from "app/components/button/MenuActions";
import { useQueryParams } from "helpers/useQueryParams.hook";
import {
  showConfirmation,
  showError,
  showSuccess,
} from "app/components/sweetalertService";
import FilterUsers from "./components/FilterUsers";
import { useDispatch, useSelector } from "react-redux";
import { deletePembayaran, getDataPembayaran } from "app/redux/actions/PembayaranActions";
import { formatBankNumber } from "helpers/formatBankNumber";
import { formatCurrency } from "helpers/formatCurrency";

const TablePembayaran = () => {
  const dispatch = useDispatch();

  const { queryParams, setQueryParams } = useQueryParams(
    {
      page: 1,
      limit: 10,
      search: "",
      tanggal: "",
      waktu: "",
      status: "",
      relawan_min: "",
      relawan_max: "",
    },
    {
      page: 1,
      limit: 10,
      search: "",
      tanggal: "",
      waktu: "",
      status: "",
      relawan_min: "",
      relawan_max: "",
    }
  );

  const [state, setState] = useState({
    loading: false,
    page: queryParams.page > 0 ? queryParams.page - 1 : 0,
    rowsPerPageOptions: [5, 10, 50, 100],
    paramsChange: false,
  });

  function handleSearch({
    page,
    limit,
    search,
    tanggal,
    waktu,
    status,
    relawan_min,
    relawan_max
  }) {
    setQueryParams({
      ...(page && { page }),
      ...(limit && { limit }),
      ...(search && { search }),
      ...(tanggal && { tanggal }),
      ...(waktu && { waktu }),
      ...(status && { status }),
      ...(relawan_min && { relawan_min }),
      ...(relawan_max && { relawan_max }),
    });
  }

  const { dataPembayaran, dataPembayaranPagination, totalPembayaran } = useSelector(
    (state) => state.pembayaran
  );

  const getData = () => {
    setState((prevState) => ({ ...prevState, loading: true }));

    const { ...filteredParams } = queryParams;
    dispatch(getDataPembayaran(filteredParams))
      .then(() => {
        setState((prevState) => ({ ...prevState, loading: false }));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setState((prevState) => ({ ...prevState, loading: false }));
      });
    console.log(filteredParams)
    dispatch(getDataPembayaran(filteredParams));
  };

  const getTableRowNumber = (index) => {
    return state.page * queryParams.limit + index + 1;
  };

  const updatePagePagination = () => {
    setState({ ...state, page: 0, paramsChange: !state.paramsChange });
  };

  const handleSearchChange = (e) => {
    const { name, value } = e.target;

    const updatedSearch = {
      ...queryParams,
      page: 1,
      [name]: value,
    };

    updatePagePagination();
    handleSearch(updatedSearch);
  };


  const submitSearch = (event) => {
    if (event.keyCode === 13 || event.which === 13) {
      setState({ ...state, paramsChange: !state.paramsChange });
    }
  };

  const setRowsPerPage = (event) => {
    const newLimit = event.target.value;
    handleSearch({ ...queryParams, page: 1, limit: newLimit });
    updatePagePagination();
  };

  const handleChangePage = (event, newPage) => {
    handleSearch({
      ...queryParams,
      page: newPage + 1,
      limit: queryParams.limit,
    });
    setState({ ...state, page: newPage, paramsChange: !state.paramsChange });
  };

  const handleDelete = async (code) => {
    const confirmed = await showConfirmation("Hapus", "Apakah kamu yakin ?");

    if (confirmed) {
      try {
        deletePembayaran({ bank_code: code }).then((res) => {
          showSuccess("Data berhasil dihapus!");
          getData();
        });
        showSuccess("Data berhasil dihapus!");
      } catch (error) {
        showError("Data gagal dihapus !");
      }
    } else {
      showError("Data gagal dihapus !");
    }
  };

  // ==== START MODAL FILTER ====

  const [open, setOpen] = useState(false);

  const handleClickFilterModal = () => {
    setOpen(true);
  };

  const handleCloseFilterModal = () => {
    setOpen(false);
  };

  const handleSubmitFilter = () => {
    getData()
    updatePagePagination();
    setOpen(!open);
  };

  // ==== END MODAL FILTER ====

  useEffect(() => {
    getData();
  }, [dispatch, state.paramsChange]);

  return (
    <div>
      <Grid container>
        <Grid
          container
          spacing={3}
          className="mb-2 d-flex justify-content-between align-items-center"
        >
          <Hidden smUp>
            <Grid
              item
              xs={12}
              sm={3}
              md={6}
              lg={6}
              className="flex flex-start"
              style={{ gap: "10px" }}
            >
              <Link to="/master_data/setting/pembayaran/tambah">
                <GeneralButton
                  variant="contained"
                  color="primary"
                  text="Tambah User"
                  icon={<AddCircleOutline fontSize="small" />}
                />
              </Link>
            </Grid>
          </Hidden>

          <Grid item xs={12} sm={9} md={6} lg={6}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6} sm={6} md={8} lg={8}>
                <TextField
                  size="small"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  placeholder="Cari Bank Pembayaran"
                  onChange={handleSearchChange}
                  onKeyDown={submitSearch}
                  value={queryParams.search}
                  name="search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon color="disabled">search</Icon>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              {/* <Grid item xs={3} sm={3} md={3} lg={3}>
                <GeneralButton
                  variant="outlined"
                  color="default"
                  text="Filter"
                  otherClass="text-secondary"
                  border="1px solid #E6E9ED"
                  icon={<Filter />}
                  onClick={handleClickFilterModal}
                />
              </Grid> */}
            </Grid>
          </Grid>

          <Hidden xsDown>
            <Grid
              item
              xs={3}
              sm={3}
              md={6}
              lg={6}
              className="flex flex-end"
              style={{ gap: "10px" }}
            >
              <Link to="/master_data/setting/pembayaran/tambah">
                <GeneralButton
                  variant="contained"
                  color="primary"
                  text="Tambah Bank"
                  icon={<AddCircleOutline fontSize="small" />}
                />
              </Link>
            </Grid>
          </Hidden>
        </Grid>

        <Grid item xs={12} md={12}>
          <TableContainer>
            <Table className="table-res">
              <TableHead className="t-header">
                <TableRow>
                  <TableCell align="center" className="table-cell-65px">
                    No
                  </TableCell>
                  {/* <TableCell align="center" className="table-cell-65px">
                    Foto
                  </TableCell> */}
                  <TableCell align="center" className="table-cell-120px">
                    Nama Bank
                  </TableCell>
                  <TableCell align="center" className="table-cell-120px">
                    Pemilik
                  </TableCell>
                  <TableCell align="center" className="table-cell-120px">
                    Nomor Rekening
                  </TableCell>
                  <TableCell align="center" className="table-cell-120px">
                    Jumlah Pembayaran
                  </TableCell>
                  <TableCell align="center" className="table-cell-120px">
                    Aksi
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.loading ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      Loading ...
                    </TableCell>
                  </TableRow>
                ) : dataPembayaran.length > 0 ? (
                  dataPembayaran.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell align="center" className="table-cell-65px">
                        {getTableRowNumber(index)}
                      </TableCell>
                      {/* <TableCell align="center" className="table-cell-65px">
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <img src={item.bank_image} alt={item.bank_name} />
                        </div>
                      </TableCell> */}
                      <TableCell align="center" className="table-cell-120px">
                        {item.bank_name || '-'}
                      </TableCell>
                      <TableCell align="center" className="table-cell-120px">
                        {item.bank_user_fullname || '-'}
                      </TableCell>
                      <TableCell align="center" className="table-cell-120px">
                        {formatBankNumber(`${item.bank_rekening_number}` || '-')}
                      </TableCell>
                      <TableCell align="center" className="table-cell-120px">
                        {formatCurrency(item.bank_total_amount || 0)}
                      </TableCell>
                      <TableCell align="center" className="table-cell-120px">
                        <MenuActions
                          editPath={`/master_data/setting/pembayaran/edit/${item.bank_code}`}
                          detailPath={`/master_data/setting/pembayaran/detail/${item.bank_code}`}
                          deleteActions={() => handleDelete(item.bank_code)}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      Data kosong
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Box display="flex" justifyContent="space-between">
            <Box display="flex" flexDirection="row" p={1} alignItems="center">
              <Box p={1}>
                <div className="flex">
                  <p>Rows per page : </p>
                  <select
                    labelid="simple-select"
                    value={queryParams.limit}
                    name="rowsPerPage"
                    onChange={setRowsPerPage}
                    variant="standard"
                    className="border-none"
                  >
                    {state.rowsPerPageOptions.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </Box>
            </Box>
            <Box>
              <TablePagination
                className="px-16"
                rowsPerPageOptions={[]}
                component="div"
                count={dataPembayaranPagination.total || 0}
                rowsPerPage={queryParams.limit}
                labelRowsPerPage={""}
                page={dataPembayaranPagination.page - 1 || 0}
                backIconButtonProps={{
                  "aria-label": "Previous page",
                }}
                nextIconButtonProps={{
                  "aria-label": "Next page",
                }}
                backIconButtonText="Previous page"
                nextIconButtonText="Next page"
                onChangePage={handleChangePage}
                onChangeRowsPerPage={setRowsPerPage}
              />
            </Box>
          </Box>
        </Grid>

        <FilterUsers
          open={open}
          handleClickOpen={handleClickFilterModal}
          handleClose={handleCloseFilterModal}
          handleSubmit={handleSubmitFilter}
          state={state}
          setState={setState}
          handleSearchChange={handleSearchChange}
          value={queryParams}
          handleSearch={handleSearch}
        />
      </Grid>
    </div>
  );
};

export default TablePembayaran;
