import { API, setAuthToken } from "config/API.js";
import {
    GET_PEMBAYARAN,
    GET_DETAIL_PEMBAYARAN,
    GET_TOTAL_PEMBAYARAN
} from "../constant.js";

export const getDataPembayaran = (params = "") => {
    return (dispatch) => {
        dispatch({
            type: GET_PEMBAYARAN,
            payload: {
              data: [],
              pagination: {
                current_page: 1,
                last_page: 1,
                per_page: 10,
                total: 1
              }
            }
        });

        const token = localStorage.getItem("jwt_token");
        setAuthToken(token);
        return API.get(`/admin/setting/bank_payment?${new URLSearchParams(params)}`)
            .then((res) => {
                dispatch({
                    type: GET_PEMBAYARAN,
                    payload: res.data.data || [],
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_PEMBAYARAN,
                    payload: [],
                });
            });
    };
};

export const getDetailPembayaran = (code) => {
    return (dispatch) => {
        const token = localStorage.getItem("jwt_token");
        setAuthToken(token);
        API.get(`/admin/setting/bank_payment/detail/${code}`)
            .then((res) => {
                dispatch({
                    type: GET_DETAIL_PEMBAYARAN,
                    payload: res.data || [],
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_DETAIL_PEMBAYARAN,
                    payload: [],
                });
            });
    };
};

export const getTotalPembayaran = (code) => {
    return (dispatch) => {
        const token = localStorage.getItem("jwt_token");
        setAuthToken(token);
        API.get(`/admin/setting/bank_payment/total/${code}`)
            .then((res) => {
                dispatch({
                    type: GET_TOTAL_PEMBAYARAN,
                    payload: res.data.data || [],
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_TOTAL_PEMBAYARAN,
                    payload: [],
                });
            });
    };
};

export const addPembayaran = body => {
    const token = localStorage.getItem('jwt_token')
    setAuthToken(token)
    return API.post('/admin/setting/bank_payment/tambah', body)
  }

  export const updatePembayaran = body => {
    const token = localStorage.getItem('jwt_token')
    setAuthToken(token)
    return API.post('/admin/setting/bank_payment/update', body)
  }

  export const deletePembayaran = body => {
    const token = localStorage.getItem('jwt_token')
    setAuthToken(token)
    return API.post('/admin/setting/bank_payment/delete', body)
  }
