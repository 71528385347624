import React from "react";
import {
  Grid,
  Box,
  Hidden,
  Button,
  TextField,
  InputAdornment,
  Icon,
} from "@material-ui/core";
import { Globe } from "@phosphor-icons/react";
import GreenButton from "app/components/GreenButton";

export const Header = ({
  tab,
  onResetMap,
  onChange,
  onSearchFeatureName,
  onShowArea,
}) => {
  return (
    <Box className="flex flex-wrap gap-2 p-16">
      <Hidden xsDown>
        <Grid container className="flex justify-content-between">
          <Grid item md={6} lg={6}>
            <Button
              className="p-10 flex-shrink-0"
              style={{
                backgroundColor: "#00ACEE",
                color: "#FFFFFF",
              }}
              variant="contained"
              onClick={onResetMap}
            >
              {/* <span></span> */}
              <Globe style={{ fontSize: 24, marginRight: 5 }} />
              Sebaran Suara Politik 2019
            </Button>
          </Grid>
          <Grid item md={6} lg={6}>
            <Grid container spacing={2} alignItems="center">
              <Grid item md={8} lg={8}>
                <TextField
                  size="small"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  placeholder="Cari"
                  onChange={onChange}
                  value={onSearchFeatureName}
                  // onKeyDown={submitSearch}
                  autoComplete="off"
                  name="search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon color="disabled">search</Icon>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item md={4} lg={4}>
                <GreenButton
                  text="Cari"
                  onClick={onShowArea}
                  variant="contained"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>

      <Hidden smUp>
        <Grid container>
          <Grid item xs={12} className="mb-2">
            <Box className="flex flex-wrap">
              <Button
                className="p-10 flex-grow-1"
                style={{
                  backgroundColor: "#00ACEE",
                  color: "#FFFFFF",
                }}
                variant="contained"
                onClick={onResetMap}
              >
                <Globe style={{ fontSize: 24, marginRight: 5 }} />
                Sebaran Suara Politik 2019
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={8} sm={8}>
                <TextField
                  size="small"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  placeholder="Cari"
                  onChange={onChange}
                  value={onSearchFeatureName}
                  // onKeyDown={submitSearch}
                  autoComplete="off"
                  name="search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon color="disabled">search</Icon>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={4} sm={4}>
                <GreenButton
                  text="Cari"
                  onClick={onShowArea}
                  variant="contained"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
    </Box>
  );
};
