import {
    GET_ALL_DISTRICT,
    GET_DISTRICT,
    GET_DISTRICT_NAME,
} from "../constant.js";

const initialState = {
    dataDistrict: [],
    dataDistrictName: {},
    dataAllDistrict: [],
};

const DistrictReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_DISTRICT: {
            return {
                ...state,
                dataDistrict: action.payload,
            };
        }
        case GET_DISTRICT_NAME: {
            return {
                ...state,
                dataDistrictName: action.payload,
            };
        }
        case GET_ALL_DISTRICT: {
            return {
                ...state,
                dataAllDistrict: action.payload,
            }
        }
        default: {
            return state;
        }
    }
};

export default DistrictReducer;
