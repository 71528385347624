import { Button } from "@material-ui/core";
import React from "react";

export default function GreenButton(props) {
  const buttonStyle = {
    borderRadius: "5px",
    color: props.variant === "outlined" ? "#71BE42" : "#FFF",
    textTransform: "capitalize",
    height: "47px",
    width: "100%", // Set the width to 100% for full width
    padding: "10px 16px",
    cursor: "pointer",
    ...(props.variant === "outlined"
      ? {
        border: "1px solid #71BE42",
        backgroundColor: "#fff", // Make the background transparent for outlined buttons
      }
      : {
        backgroundColor: "#71BE42",
      }),
  };

  return (
    <Button variant={props.variant} style={buttonStyle} onClick={props.onClick}>
      {props.text}
    </Button>
  );
}
