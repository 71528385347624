import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllVillages } from 'app/redux/actions/VillagesActions';
import MenuActions from "../../components/MenuActions";
import CustomLayout from "../../components/CustomLayout";

const TableKelurahan = () => {
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();

  const { dataAllVillages } = useSelector(
    (state) => state.villages
  )

  const getData = () => {
    dispatch(getAllVillages());
  };

  useEffect(() => {
    getData();
  }, [dispatch]);

  const subDistrictColumns = [
    { header: "No", field: "id" },
    { header: "Nama Provinsi", field: "province" },
    { header: "Nama Kota/Kabupaten", field: "regency" },
    { header: "Nama Kecamatan", field: "district" },
    { header: "Nama Kelurahan", field: "name" },
    {
      header: "Aksi",
      render: (rowData) => (
        <MenuActions
          editPath="/master_data/data_lainnya/data_kelurahan/edit"
          data={rowData}
          item={rowData}
          state={rowData}
        />
      ),
      field: 'aksi'
    },
  ];

  const searchedDataAllVillages = dataAllVillages?.data?.data?.data.filter(({ name, district, regency, province }) =>
    name.toLowerCase().includes(search.toLowerCase()) ||
    district.toLowerCase().includes(search.toLowerCase()) ||
    regency.toLowerCase().includes(search.toLowerCase()) ||
    province.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <>
      <CustomLayout
        subtitle="Kelurahan"
        columns={subDistrictColumns}
        data={searchedDataAllVillages}
        button="Tambah Kelurahan"
        addPath="/master_data/data_lainnya/data_kelurahan/tambah"
        search={search}
        setSearch={setSearch}
      />
    </>
  );
};

export default TableKelurahan;
