/* eslint-disable react-hooks/exhaustive-deps */
import { Card } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MenuActions from "../components/MenuActions";
import CustomLayout from "../components/CustomLayout";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { getTPS, deleteTPS } from "app/redux/actions/TPSActions";
const MasterQuickCount = () => {
  const dispatch = useDispatch();
  const { dataTPS } = useSelector((state) => state.tps);

  const deleteDataTps = (code) => {
    const payload = {
      kategori_nomor_tps_code: code,
    };
    deleteTPS(payload)
      .then((res) => {
        console.log(res);
        Swal.fire({
          title: '<div class="custom-swal-title">Data berhasil dihapus!</div>',
          buttonsStyling: false,
          icon: "success",
          customClass: {
            confirmButton: "custom-success-button",
          },
        });
        getData();
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: '<div class="custom-swal-title">Data gagal dihapus!</div>',
          buttonsStyling: false,
          icon: "error",
          customClass: {
            confirmButton: "custom-error-button",
          },
        });
      });
  };

  const quickCountColumns = [
    { header: "No", field: "no" },
    {
      header: "Nama Tempat Pemungutan Suara",
      field: "kategori_nomor_tps_nama",
    },
    {
      header: "Aksi",
      render: (rowData) => (
        <MenuActions
          editPath={`/master_data/data_lainnya/hitung_cepat/edit/${rowData.kategori_nomor_tps_code}`}
          data={rowData}
          item={rowData}
          state={rowData}
          // onDelete={(item) => deleteDataTps(item.kategori_nomor_tps_code)}
          deleteActions={() => deleteDataTps(rowData.kategori_nomor_tps_code)}
        />
      ),
      field: "aksi",
    },
  ];

  const getData = () => {
    dispatch(getTPS());
  };

  const [searchQuickCount, setSearchQuickCount] = useState("");

  const dataTpsWithNo = dataTPS
    .filter((item) =>
      item.kategori_nomor_tps_nama
        .toLowerCase()
        .includes(searchQuickCount.toLowerCase())
    )
    .map((item, index) => ({
      ...item,
      no: index + 1,
    }));

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Card className="w-100 overflow-auto p-24 mb-3" elevation={6}>
        <CustomLayout
          title="Data Hitung Cepat"
          columns={quickCountColumns}
          data={dataTpsWithNo}
          button="Tambah TPS"
          addPath="/master_data/data_lainnya/hitung_cepat/tambah"
          search={searchQuickCount}
          setSearch={setSearchQuickCount}
        />
      </Card>
    </>
  );
};

export default MasterQuickCount;
