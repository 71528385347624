import { API, setAuthToken } from "config/API.js";
import { GET_DPT } from "../constant.js";

export const getDpt = (params = "") => {
  return dispatch => {
    dispatch({
      type: GET_DPT,
      payload: {
        data: [],
        pagination: {
          current_page: 1,
          last_page: 1,
          per_page: 10,
          total: 1
        }
      }
    })

    const token = localStorage.getItem('jwt_token')
    setAuthToken(token)
    return API.get(`/admin/dpt?${new URLSearchParams(params)}`)
      .then(res => {
        dispatch({
          type: GET_DPT,
          payload: res.data.data || []
        })
      })
      .catch(() => {
        dispatch({
          type: GET_DPT,
          payload: []
        })
      })
  }
};

export const importDpt = (body) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.post("/admin/dpt/import", body);
}

export const addDpt = (body) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.post("/admin/dpt/tambah", body);
}

export const updateDpt = (body) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.post("/admin/dpt/update", body);
}
