/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Dialog, Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import GreenButton from "app/components/GreenButton";
import InputFileImg from "app/components/Input/InputFileImg";
import { getDataDapil } from "app/redux/actions/PengaturanActions";
import GeneralButton from "app/components/GeneralButton";

const ModalImport = ({ open, handleClose, handleSubmit }) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    file: "",
    file_preview: "",
  });
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const getData = () => {
    dispatch(getDataDapil());
  };

  useEffect(() => {
    getData();
    setLoadingSubmit(false);
  }, []);

  const handleChangeFile = (file, path, state) => {
    setState((prev) => ({
      ...prev,
      [state]: file,
      [`${state}_preview`]: path,
    }));
  };

  const handleUnggah = async () => {
    await setLoadingSubmit(true);
    const params = {
      ...state,
    };
    await handleSubmit(params);
    await setLoadingSubmit(false);
  };

  return (
    <Dialog open={open} maxWidth={"md"} onClose={handleClose}>
      <div className="p-4">
        <Grid className="mb-16" container spacing={2}>
          <Grid item lg={12} md={12} xs={12} sm={12}>
            <h2 className="fs-24 mt-20 fw-600">
              Import Daftar Pemilih Tetap (DPT)
            </h2>
          </Grid>

          <Grid item lg={12} md={12} xs={12} sm={12}>
            <InputFileImg
              noPadding={true}
              name="file"
              uploadFoto={(file, path) => handleChangeFile(file, path, "file")}
              acceptFile="excel"
            />
          </Grid>

          <Grid container spacing={2} className="mt-2">
            <Grid item lg={6} md={6} xs={6} sm={6} className="mt-4">
              <GreenButton
                variant="outlined"
                color="secondary"
                text="Batal"
                otherClass="w-full text-green"
                onClick={handleClose}
                border="#4cb050"
              />
            </Grid>
            <Grid item lg={6} md={6} xs={6} sm={6} className="mt-4">
              <GeneralButton
                variant="contained"
                color="secondary"
                text={loadingSubmit ? "Mengunggah..." : "Unggah"}
                otherClass="w-full"
                onClick={() => handleUnggah()}
                disabled={loadingSubmit || state.file === ""}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
};

export default ModalImport;
