/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card,
  Grid,
  TextField,
  InputLabel,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import GreenButton from "app/components/GreenButton";
  import { addAdminManagement } from "app/redux/actions/AdminManagementActions";
import ModalError from "app/components/modal/ModalError";
import ModalSuccess from "app/components/modal/ModalSuccess";

const AddAdmin = () => {
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [state, setState] = useState({
    name: "",
    usersname: "",
    email: "",
    phone: "",
    password: "",
    confirm_password: "",
  });
  const [errorField, setErrorField] = useState({});

  const handleChange = (e) => {
    e.persist();
    const { name, value } = e.target;

    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleSubmit = () => {
    addAdminManagement(state)
      .then((res) => {
        setSuccessModal(true)
        history.push("/adm_management");
      })
      .catch((err) => {
        setErrorField(err.response?.data?.data);
        setErrorModal(true)
      });
    setSuccessModal(true)
    setErrorModal(true)
    console.log(state);
  };

  const handleCloseModal = () => {
    setErrorModal(false);
    setSuccessModal(false);
  };

  return (
    <div className="p-40">
      <Grid
        container
        spacing={3}
        className="mb-2 d-flex justify-content-center align-items-center"
      >
        <Grid item lg={6} md={6} xs={12} sm={6}>
          <h2 className="font-size-32 fw-700 fw-bold">Tambah Admin</h2>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          xs={12}
          sm={6}
          spacing={2}
          container
          justify="flex-end"
        >
          <Grid item>
            <GreenButton
              text="Kembali"
              onClick={() => history.push("/adm_management")}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <GreenButton
              text="Simpan Data"
              onClick={handleSubmit}
              variant="contained"
            />
          </Grid>
        </Grid>
      </Grid>

      <Card style={{ padding: "20px" }}>
        <Grid container className="p-4" spacing={2}>
          <Grid item lg={12} md={12} xs={12} sm={12}>
            <h3 className="fw-600 fs-24">Detail Admin</h3>
          </Grid>

          <Grid item className="mb-2" lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="name">
              <h5 className="font-size-14">Nama Lengkap</h5>
            </InputLabel>
            <TextField
              required={true}
              size="small"
              value={state.name}
              name="name"
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5,
                },
              }}
              placeholder="Nama"
              variant="outlined"
              onChange={handleChange}
              type="text"
            />
            <small className="text-danger">{errorField?.name}</small>
          </Grid>
          <Grid item className="mb-2" lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="usersname">
              <h5 className="font-size-14">Username</h5>
            </InputLabel>
            <TextField
              required={true}
              size="small"
              value={state.usersname}
              name="usersname"
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5,
                },
              }}
              placeholder="Username"
              variant="outlined"
              onChange={handleChange}
              type="text"
            />
            <small className="text-danger">{errorField?.usersname}</small>
          </Grid>

          <Grid item className="mb-2" lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="email">
              <h5 className="font-size-14">Email</h5>
            </InputLabel>
            <TextField
              required={true}
              size="small"
              value={state.email}
              name="email"
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5,
                },
              }}
              placeholder="email@domain.com"
              variant="outlined"
              onChange={handleChange}
              type="text"
            />
            <small className="text-danger">{errorField?.email}</small>
          </Grid>
          <Grid item className="mb-2" lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="phone">
              <h5 className="font-size-14">Nomor Ponsel</h5>
            </InputLabel>
            <TextField
              required={true}
              size="small"
              value={state.phone}
              name="phone"
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5,
                },
              }}
              placeholder="08xxxxx"
              variant="outlined"
              onChange={handleChange}
              type="text"
            />
            <small className="text-danger">{errorField?.phone}</small>
          </Grid>
          <Grid item className="mb-2" lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="password">
              <h5 className="font-size-14">Password</h5>
            </InputLabel>
            <TextField
              fullWidth
              hiddenLabel
              id="password"
              variant="outlined"
              size="small"
              placeholder="Masukan password"
              type={showPassword ? "text" : "password"}
              name="password"
              value={state.password}
              onChange={handleChange}
              // onKeyDown={handleKeyDown}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOff htmlColor="#4D5B7C" />
                      ) : (
                        <Visibility htmlColor="#4D5B7C" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <small className="text-danger">{errorField?.password}</small>
          </Grid>
          <Grid item className="mb-2" lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="confirm_password">
              <h5 className="font-size-14">Ulangi Password</h5>
            </InputLabel>
            <TextField
              fullWidth
              hiddenLabel
              id="confirm_password"
              variant="outlined"
              size="small"
              placeholder="Ulangi password"
              type={showPassword ? "text" : "password"}
              name="confirm_password"
              value={state.confirm_password}
              onChange={handleChange}
              // onKeyDown={handleKeyDown}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOff htmlColor="#4D5B7C" />
                      ) : (
                        <Visibility htmlColor="#4D5B7C" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <small className="text-danger">{errorField?.confirm_password}</small>
          </Grid>
        </Grid>
      </Card>

      <ModalError
        open={errorModal}
        handleClose={handleCloseModal}
      />
      <ModalSuccess
        open={successModal}
        handleClose={handleCloseModal}
      />
    </div>
  );
};

export default AddAdmin;
