import React, { Fragment } from "react";
// import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Card } from "@mui/material";

import MasterLogistik from "./logistik/MasterLogistik";
import MasterArusKas from "./arus_kas/MasterArusKas";
import MasterPartaiSekarang from "./partai_pemilu_sekarang/MasterPartaiSekarang";
import MasterPartaiTerakhir from "./partai_pemilu_terakhir/MasterPartaiTerakhir";
import MasterKandidat from "./kandidat/MasterKandidat";
import MasterQuickCount from "./quick_count/MasterQuickCount";
import MasterDataDaerah from "./data_daerah/MasterDataDaerah";
import MasterDPT from "./daftar_pemilih_tetap/MasterDPT";

const DataLainnya = () => {
  return (
    // <>
    //   <MasterPartaiSekarang />
    //   <MasterPartaiTerakhir />
    //   <MasterLogistik />
    //   <MasterArusKas />
    //   <MasterKandidat />
    //   <MasterQuickCount />
    // </>

    <Fragment>
      <Box className="p-40">
        <h2 className="font-size-32 fw-700 fw-bold mb-sm-30">Master Data</h2>
        <Card>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12} md={12} sm={12}>
              <MasterPartaiSekarang />
              <MasterPartaiTerakhir />
              <MasterLogistik />
              <MasterArusKas />
              <MasterDPT />
              <MasterKandidat />
              <MasterQuickCount />
              <MasterDataDaerah />
            </Grid>
          </Grid>
        </Card>
      </Box>
    </Fragment>
  );
};

export default DataLainnya;
