/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Dialog, Grid, Select, MenuItem, InputLabel } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getDistrict } from "app/redux/actions/DistrictActions";
import { getVillages } from "app/redux/actions/VillagesActions";
import { getCity } from "app/redux/actions/CityActions";
import { getProvince } from "app/redux/actions/ProvinceActions";
import { getDataCalonTetapSebagaiPublic } from "app/redux/actions/PengaturanActions";
import { useEffect } from "react";
import GreenButton from "app/components/GreenButton";

const FilterUsers = ({
  open,
  handleClose,
  state,
  handleSubmit,
  handleSearchChange,
  value,
  handleSearch,
}) => {
  const dispatch = useDispatch();

  const { dataCalonTetap } = useSelector(
    (state) => state.pengaturan
  );

  const getData = () => {
    dispatch(getDataCalonTetapSebagaiPublic());
    dispatch(getProvince());
  };

  useEffect(() => {
    getData();
  }, [dataCalonTetap]);

  const handleChangeProvince = (e) => {
    const updatedSearch = {
      ...value,
      provinsi: e.target.value,
      kota: "",
      kecamatan: "",
      desa: "",
    };

    handleSearch(updatedSearch);

    dispatch(getCity({ province_code: e.target.value }));
  };

  const handleChangeKotaKab = (e) => {
    const updatedSearch = {
      ...value,
      kota_kab: e.target.value,
      kecamatan: "",
      desa: "",
    };

    handleSearch(updatedSearch);

    dispatch(getDistrict({ city_code: e.target.value }));
  };

  const handleChangeDistrict = (e) => {
    const updatedSearch = {
      ...value,
      kecamatan: e.target.value,
      desa: "",
    };

    handleSearch(updatedSearch);

    dispatch(getVillages({ districts_code: e.target.value }));
  };

  useEffect(() => {
    // console.log(state);
  }, [state]);

  const { dataProvince } = useSelector((state) => state.province);
  const { dataCity } = useSelector((state) => state.city);
  const { dataDistrict } = useSelector((state) => state.district);
  const { dataVillages } = useSelector((state) => state.villages);

  const dataAdminTingkat = [
    { id: 1, name: "DPR" },
    { id: 2, name: "DPD" },
    { id: 3, name: "DPRD" }
  ]
  return (
    <Dialog open={open} maxWidth={"xs"} onClose={handleClose}>
      <div className="p-4">
        <h2 className="fs-24 mb-20 fw-600">Silahkan Pilih Filter</h2>
        <Grid className="mb-16" container spacing={2}>
          <Grid item lg={12} md={12} xs={12} sm={12}>
            <h2 className="fs-16 fw-500">Filter Admin Caleg</h2>
          </Grid>
          <Grid item lg={12} md={12} xs={12} sm={12}>
            <InputLabel htmlFor="provinsi">
              <h5 className="fs-12 text-muted fw-400">Admin Caleg</h5>
            </InputLabel>
            <Select
              SelectDisplayProps={{
                style: {
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 5,
                },
              }}
              size="small"
              labelid="provinsi"
              value={value.tingkat}
              onChange={handleChangeProvince}
              variant="outlined"
              className="w-100"
              name="provinsi"
              displayEmpty
            >
              <MenuItem key={"x"} value={""} disabled>
                Pilih admin caleg
              </MenuItem>
              {dataCalonTetap.map((tingkat) => (
                <MenuItem key={tingkat.tingkat_calon_tetap_code} value={tingkat.tingkat_calon_tetap_code}>
                  {tingkat.tingkat_calon_tetap_nama}
                </MenuItem>
              ))}
            </Select>
          </Grid>

        </Grid>

        <Grid className="mb-16" container spacing={2}>
          <Grid item lg={6} md={6} xs={6} sm={6} className="mt-4">
            <GreenButton
              variant="outlined"
              color="secondary"
              text="Batal"
              otherClass="w-full text-green"
              onClick={handleClose}
            />
          </Grid>
          <Grid item lg={6} md={6} xs={6} sm={6} className="mt-4">
            <GreenButton
              variant="contained"
              color="secondary"
              text="Terapkan"
              otherClass="w-full"
              onClick={handleSubmit}
            />
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
};

export default FilterUsers;
