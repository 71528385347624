import React from "react";
import {
    Grid,
    InputLabel,
    TextField,
    Select,
    MenuItem,
} from "@material-ui/core";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "date-fns";
import GreenButton from "app/components/GreenButton";
import { WarningCircle } from "@phosphor-icons/react";
const style = {
    width: 550,
    bgcolor: "background.paper",
    borderRadius: "3px",
    boxShadow: 24,
    p: 4,
};

const sub_title_style = {
    color: "#1C1C1C",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "150%",
    marginTop: "15px",
};

const ModalSuccess = ({ open, handleClose, message }) => {
    return (
        <Modal
            className="flex justify-content-center align-items-center m-2"
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} className="rounded">
                <Grid container spacing={2}
                    justifyContent="center" className="flex flex-column mt-2" alignItems="center">
                    <Grid item lg={12} md={12} xs={12} sm={12}>
                        <img
                            src="/assets/images/logos/Success.png"
                            alt="Error"
                            style={{ margin: "0 auto" }}
                        />
                    </Grid>
                    <h5 style={sub_title_style}>{message || 'Berhasil Menambahkan Admin'}</h5>
                    <div className="p-3 mt-3 rounded mb-2" style={{ backgroundColor: "#F6FFF1" }}>
                        <small style={{ color: "#71BE42", fontSize: "12px" }}><WarningCircle /> Email pemberitahuan telah dikirimkan ke email sistem</small>
                    </div>
                </Grid>

                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "20px",
                        marginTop: "20px",
                    }}
                >
                    <GreenButton
                        text="Tutup"
                        onClick={handleClose}
                        variant="contained"
                    />
                </div>
            </Box>
        </Modal>
    );
}

export default ModalSuccess;