import { API, setAuthToken } from "config/API.js";
import { GET_ALL_VILLAGES, GET_VILLAGES, GET_VILLAGES_NAME } from "../constant.js";

export const getAllVillages = (params = "") => {
    return async (dispatch) => {
      try {
        const token = localStorage.getItem("jwt_token");
        setAuthToken(token);
        const res = await API.post(`/villages?${new URLSearchParams(params)}`);
        dispatch({
          type: GET_ALL_VILLAGES,
          payload: res.data || [],
        });
      } catch (error) {
        dispatch({
          type: GET_ALL_VILLAGES,
          payload: [],
        });
        throw error;
      }
    };
};

export const getVillages = (body) => {
    return (dispatch) => {
        const token = localStorage.getItem("jwt_token");
        setAuthToken(token);
        API.post(`/villages/list`, body)
            .then((res) => {
                dispatch({
                    type: GET_VILLAGES,
                    payload: res.data.data || [],
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_VILLAGES,
                    payload: [],
                });
            });
    };
};

export const getVillagesName = (body) => {
    return (dispatch) => {
        const token = localStorage.getItem("jwt_token");
        setAuthToken(token);
        API.post(`/villages-name`, body)
            .then((res) => {
                dispatch({
                    type: GET_VILLAGES_NAME,
                    payload: res.data.data || [],
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_VILLAGES_NAME,
                    payload: [],
                });
            });
    };
};

export const addVillages = (body) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.post("/admin/villages/tambah", body);
}

export const updateVillages = (body) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.post("/admin/villages/update", body);
}
