/* eslint-disable react-hooks/exhaustive-deps */
import { Card } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MenuActions from "../components/MenuActions";
import CustomLayout from "../components/CustomLayout";
import {
  getDataCategoryArusKas,
  deleteCategoryArusKas,
} from "app/redux/actions/CategoryArusKasActions";
import Swal from "sweetalert2";

const MasterArusKas = () => {
  const dispatch = useDispatch();
  const { dataKategoriArusKas } = useSelector((state) => state.categoryArusKas);

  const deleteDataCategoryArusKas = (code) => {
    const payload = {
      kategori_arus_kas_code: code,
    };
    deleteCategoryArusKas(payload)
      .then((res) => {
        console.log(res);
        Swal.fire({
          title: '<div class="custom-swal-title">Data berhasil dihapus!</div>',
          icon: "success",
          buttonsStyling: false,
          icon: "success",
          customClass: {
            confirmButton: "custom-success-button",
          },
        });
        getData();
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: '<div class="custom-swal-title">Data gagal dihapus!</div>',
          icon: "error",
          buttonsStyling: false,
          icon: "error",
          customClass: {
            confirmButton: "custom-error-button",
          },
        });
      });
  };
  const arusKasColumns = [
    {
      header: "No",
      field: "no",
    },
    { header: "Nama Kategori Arus Kas", field: "kategori_arus_kas_nama" },
    {
      header: "Aksi",
      render: (rowData) => (
        <MenuActions
          editPath={`/master_data/data_lainnya/arus_kas/edit/${rowData.kategori_arus_kas_code}`}
          data={rowData}
          // item={rowData}
          // state={rowData}
          // onDelete={(item) =>
          //   deleteDataCategoryArusKas(item.kategori_arus_kas_code)
          // }
          deleteActions={() => deleteDataCategoryArusKas(rowData.kategori_arus_kas_code)}
          item={rowData}
          state={rowData}
        />
      ),
      field: "aksi",
    },
  ];

  const [search, setSearch] = useState("");

  const getData = () => {
    dispatch(getDataCategoryArusKas());
  };

  const dataKategoriArusKasWithNo = dataKategoriArusKas
    .filter((item) =>
      item.kategori_arus_kas_nama.toLowerCase().includes(search.toLowerCase())
    )
    .map((item, index) => ({
      ...item,
      no: index + 1,
    }));

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Card className="w-100 overflow-auto p-24 mb-3" elevation={6}>
        <CustomLayout
          title="Data Arus Kas"
          columns={arusKasColumns}
          data={dataKategoriArusKasWithNo}
          button="Tambah Kategori"
          addPath="/master_data/data_lainnya/arus_kas/tambah"
          search={search}
          setSearch={setSearch}
        />
      </Card>
    </>
  );
};

export default MasterArusKas;
