/* eslint-disable react-hooks/exhaustive-deps */
import { Card } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDataPartaiPublic } from "app/redux/actions/PengaturanActions";
import TablePartaiSekarang from "./TablePartaiSekarang";

const MasterPartaiSekarang = () => {
  const dispatch = useDispatch();
  const { partaiSekarang } = useSelector((state) => state.pengaturan);
  const getData = () => {
    dispatch(getDataPartaiPublic());
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Card className="w-100 overflow-auto p-24 mb-3" elevation={6}>
        <div className="mb-sm-30">
          <h2 className="fw-700 fw-bold mt-2 h1-title">Partai Peserta Pemilu Sekarang</h2>
        </div>
        <TablePartaiSekarang dataPartai={partaiSekarang} />
      </Card>
    </>
  );
};

export default MasterPartaiSekarang;
