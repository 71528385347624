// import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";
import DashboardAdmin from "./DashboardAdmin";

const dashboardRoutes = [
  {
    path: "/dashboard",
    component: DashboardAdmin,
    auth: authRoles.superadm,
    // exact: true,
  },
];

export default dashboardRoutes;
