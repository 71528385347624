import {
    GET_ALL_CITY,
    GET_CITY,
    GET_CITY_NAME
} from "../constant.js";

const initialState = {
    dataCity: [],
    dataCityName: {},
    dataAllCity: [],
    loading: true,
};

const CityReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_CITY: {
            return {
                ...state,
                dataCity: action.payload,
                loading: false,
            };
        }
        case GET_CITY_NAME: {
            return {
                ...state,
                dataCityName: action.payload,
                loading: false,
            };
        }
        case GET_ALL_CITY: {
            return {
                ...state,
                dataAllCity: action.payload,
                loading: false,
            };
        }
        default: {
            return state;
        }
    }
};

export default CityReducer;
