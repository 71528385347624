/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  InputAdornment,
  Icon,
  TableContainer,
  Hidden,
  Box,
  TablePagination,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import shortid from "shortid";
import { useDispatch, useSelector } from "react-redux";
import {
  getCalegPerPartai,
  getDetailRangkumanPartai,
  getGrafikRangkumanPartai,
  getKabupaten,
  getSuaraCalegPerPartai,
} from "app/redux/actions/PemiluTerakhirActions";
import DoughnutChart from "./chart/DoughnutChart";
import { useParams } from "react-router-dom";
import { ArrowCircleLeft } from "@phosphor-icons/react";
import GeneralButton from "app/components/GeneralButton";
import { useHistory, Link } from "react-router-dom";
import { isMobile } from "utils";
import CircularProgress from '@mui/material/CircularProgress';

const RangkumanPartai = () => {
  const { id_dapil, id_partai } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState({
    loading: true,
    kota_kab: "",
    caleg: "",
    caleg_nama: "",
    search: "",
    page: 0,
    rowsPerPage: 10,
    rowsPerPageOption: [5, 10, 50, 100],
  });
  const [loadingRangkumanCaleg, setLoadingRangkumanCaleg] = useState(true);
  const getData = () => {
    dispatch(getKabupaten({ dapil: id_dapil }));
    dispatch(getDetailRangkumanPartai({ code: id_partai }));
    // dispatch(getGrafikRangkumanPartai({ dapil: id_dapil }));
    dispatch(getCalegPerPartai(id_partai));
  };

  useEffect(() => {
    getData();
  }, []);

  const getTableRowNumber = (index) => {
    return state.page * state.rowsPerPage + index + 1;
  };

  const setRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);

    setState({
      ...state,
      rowsPerPage: newRowsPerPage,
      page: 0,
    });

    getData();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const setPage = (page) => {
    setState({
      ...state,
      page,
    });
  };

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));

    dispatch(
      getSuaraCalegPerPartai({
        dapil: id_dapil,
        code: id_partai,
        nama_caleg: e.target.value,
      })
    );
    setLoadingRangkumanCaleg(true);
  };

  const handleChangeKabupaten = async (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
      caleg_nama: e.target.text,
      loading: true,
    }));

    await dispatch(
      getGrafikRangkumanPartai({
        dapil: id_dapil,
        kota_kab: e.target.value,
        partai: rangkumanPartaiDetail.partai,
      })
    );
    // console.log("grafik", rangkumanPartaiGrafik)
  };

  const {
    kabupaten,
    rangkumanPartaiDetail,
    rangkumanPartaiGrafik,
    calegPerPartai,
    suaraCalegPerPartai,
  } = useSelector((state) => state.pemilu_terakhir);

  const filteredData = suaraCalegPerPartai?.data?.filter((item) =>
    item?.tps?.toLowerCase().includes(state.search.toLowerCase())
  );

  // console.log("state", filteredData);

  const toCamelCase = (str) => {
    return str.split(' ').map(word => word[0].toUpperCase() + word.substring(1)).join(' ');
  }

  useEffect(() => {
    if (rangkumanPartaiGrafik?.length !== 0) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
    if (suaraCalegPerPartai?.length !== 0) {
      setLoadingRangkumanCaleg(false);
    }
  }, [rangkumanPartaiGrafik, suaraCalegPerPartai])

  return (
    <div className="p-40">
      <div className="mb-sm-30 d-flex flex-space-between">
        {/* <h2 className="font-size-32 fw-700 fw-bold">
          Data Suara {rangkumanPartaiDetail?.partai}
        </h2> */}
        <Grid container spacing={3}>
          <Grid item lg={8} md={8} xs={12} sm={6}>
            <div className="flex gap-2 align-items-center">
              <Link
                to={`/master_data/pemilu_terakhir/rekap_suara/${id_partai}`}
                className="back-button"
              >
                <ArrowCircleLeft size={30} />
              </Link>
              <h1 className="font-size-28 fw-700 fw-bold text-uppercase">
                Data Suara {rangkumanPartaiDetail?.partai}
              </h1>
            </div>
          </Grid>
          <Grid item lg={4} md={4} xs={12} sm={6} container justify="flex-end">
            <Hidden xsDown>
              <GeneralButton
                variant="contained"
                color="secondary"
                text="Kembali"
                otherClass="text-white"
                onClick={() =>
                  history.push("/master_data/pemilu_terakhir/rekap_suara/" + id_partai)
                }
              />
            </Hidden>
          </Grid>
        </Grid>
      </div>
      <Card
        className="w-100 overflow-auto p-38 pb-0 radius-10 mb-5"
        elevation={6}
      >
        <Grid
          container
          spacing={3}
          className="mb-4 px-2"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item lg={9} md={9} sm={9} xs={12} className="ps-2">
            <h2 className="fs-24 fw-700 fw-600">Rangkuman Partai</h2>
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={9} className="ps-2">
            <InputLabel htmlFor="provinsi">
              <h5 className="font-size-14">Kota/Kabupaten</h5>
            </InputLabel>
            <Select
              SelectDisplayProps={{
                style: {
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 5,
                },
              }}
              size="small"
              labelid="kota_kab"
              value={state.kota_kab}
              onChange={handleChangeKabupaten}
              variant="outlined"
              className="w-100 d-flex flex-middle p-0"
              name="kota_kab"
              displayEmpty
            >
              <MenuItem value="">Pilih Kota</MenuItem>
              {kabupaten.map((item) => (
                <MenuItem value={item.kota_kab_code}>{item.kota_kab}</MenuItem>
              ))}
            </Select>
          </Grid>
          {state.kota_kab !== "" ? (
            <Grid item lg={6} md={6} xs={12} sm={6} className="ps-0">
              <Card
                className="w-100 overflow-auto p-24"
                elevation={6}
                style={{ boxShadow: "0px 2px 25px 0px rgba(0, 0, 0, 0.05)" }}
              >
                <div>
                  <p className="text-grey">Statistic</p>
                  <h2 className="fs-20 fw-600">
                    {toCamelCase(state.kota_kab)}
                  </h2>
                </div>
                <hr className="my-4" />
                {state.loading ? (
                  <CircularProgress
                    style={{
                      position: 'relative',
                      top: '50%',
                      left: '45%',
                    }}
                  />
                ) :
                  <DoughnutChart
                    height="250px"
                    color={["#2EC5FF", "#BBFF92"]}
                    data={rangkumanPartaiGrafik}
                  />
                }
                {/* <DoughnutChart
                  height="250px"
                  color={["#2EC5FF", "#BBFF92"]}
                  data={rangkumanPartaiGrafik}
                /> */}
              </Card>
            </Grid>
          ) : (
            <Grid item lg={12} md={12} className="ps-0 mx-5 my-5 text-center">
              <h2 className="fs-24 text-grey my-5">
                Silahkan pilih filter kota/kabupaten untuk melihat data
              </h2>
            </Grid>
          )}
        </Grid>
      </Card>
      <Card className="w-100 overflow-auto p-38 pb-0 radius-10" elevation={6}>
        <Grid
          container
          spacing={3}
          className="mb-4 px-2"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item lg={9} md={9} sm={9} xs={12} className="ps-2">
            <h2 className="fs-24 fw-700 fw-600">Rangkuman Caleg</h2>
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={9} className="ps-2">
            <InputLabel htmlFor="provinsi">
              <h5 className="font-size-14">Pilih Caleg</h5>
            </InputLabel>
            <Select
              SelectDisplayProps={{
                style: {
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 5,
                },
              }}
              size="small"
              labelid="caleg"
              value={state.caleg}
              onChange={handleChange}
              variant="outlined"
              className="w-100 d-flex flex-middle px-2"
              name="caleg"
              displayEmpty
            >
              <MenuItem value="">Pilih Caleg</MenuItem>
              {calegPerPartai.map((item) => (
                <MenuItem value={item.nama_caleg} className="capitalize">
                  {item.nama_caleg}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          {state.caleg !== "" ? (
            <>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid lg={6} md={6} sm={6} xs={12} className="ps-2">
                  <h5 className="fs-20 fw-600">
                    {suaraCalegPerPartai?.nama_caleg || "-"}
                  </h5>
                </Grid>
                <Grid lg={6} md={6} sm={6} xs={12}>
                  <Hidden xsDown>
                    <Grid container justify={"flex-end"}>
                      <Grid className="pe-2">
                        <h5 className="fs-20 fw-600">
                          Total Suara: {suaraCalegPerPartai?.total_suara || 0}
                        </h5>
                      </Grid>
                    </Grid>
                  </Hidden>
                  <Hidden smUp>
                    <Grid container className="ps-2">
                      <h5 className="fs-20 fw-600">
                        Total Suara: {suaraCalegPerPartai?.total_suara || 0}
                      </h5>
                    </Grid>
                  </Hidden>
                </Grid>
              </Grid>
              <Grid item lg={4} md={4} xs={9} className="ps-2">
                <TextField
                  size="small"
                  variant="outlined"
                  color="primary"
                  className="items-center background-white w-full"
                  placeholder={"Cari"}
                  onChange={(e) =>
                    setState({
                      ...state,
                      search: e.target.value,
                      // page: 0,
                    })
                  }
                  value={state.search}
                  //   onKeyDown={submitSearch}
                  name="search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon color="disabled">search</Icon>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item lg={8} md={8} className="ps-0"></Grid>
              <Grid item xs={12} md={12}>
                <TableContainer>
                  <Table className="table-res" style={{ minWidth: 700 }}>
                    <TableHead className="t-header">
                      <TableRow>
                        <TableCell align="center">Kota</TableCell>
                        <TableCell align="center">Kecamatan</TableCell>
                        <TableCell align="center">Kelurahan</TableCell>
                        <TableCell align="center">TPS</TableCell>
                        <TableCell align="center">Suara</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="t-body">
                      {loadingRangkumanCaleg ? (
                        <TableRow>
                          <TableCell colSpan={5} align="center">
                            Loading...
                          </TableCell>
                        </TableRow>
                      ) : filteredData?.length > 0 ? (
                        filteredData
                          ?.slice(
                            state.page * state.rowsPerPage,
                            state.page * state.rowsPerPage + state.rowsPerPage
                          )
                          .map((item, index) => (
                            <TableRow key={item.kota_kab}>
                              <TableCell
                                className="px-0 capitalize"
                                align="center"
                              >
                                {item.kota_kab}
                              </TableCell>
                              <TableCell
                                className="px-0 capitalize"
                                align="center"
                              >
                                {item.kecamatan}
                              </TableCell>
                              <TableCell
                                className="px-0 capitalize"
                                align="center"
                              >
                                {item.kelurahan}
                              </TableCell>
                              <TableCell
                                className="px-0 capitalize"
                                align="center"
                              >
                                {item.tps}
                              </TableCell>
                              <TableCell className="px-0" align="center">
                                {item.jumlah_suara}
                              </TableCell>
                            </TableRow>
                          ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={5} align="center">
                            Data kosong
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  <div style={{ minWidth: 700 }}>
                    <Box display="flex" justifyContent="space-between">
                      <Box
                        display="flex"
                        flexDirection="row"
                        p={1}
                        alignItems="center"
                      >
                        <Box p={1}>
                          <div className="flex">
                            <p>Rows per page : </p>
                            <select
                              labelid="simple-select"
                              value={state.rowsPerPage}
                              name="rowsPerPage"
                              onChange={setRowsPerPage}
                              variant="standard"
                              className="border-none"
                            >
                              {state.rowsPerPageOption.map((item) => (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Box>
                      </Box>
                      <Box>
                        <TablePagination
                          className="px-16"
                          rowsPerPageOptions={false}
                          component="div"
                          count={filteredData?.length || -1}
                          rowsPerPage={state.rowsPerPage}
                          labelRowsPerPage={""}
                          page={state.page}
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={setRowsPerPage}
                        />
                      </Box>
                    </Box>
                  </div>
                </TableContainer>
              </Grid>
            </>
          ) : (
            <Grid item lg={12} md={12} className="ps-0 mx-5 my-5">
              <h2 className="fs-24 text-grey my-5 text-center">
                Silahkan pilih filter caleg untuk melihat data
              </h2>
            </Grid>
          )}
        </Grid>
      </Card>
    </div>
  );
};

export default RangkumanPartai;
