import { combineReducers } from "redux";
import LoginReducer from "./LoginReducer";
import UserReducer from "./UserReducer";
import LayoutReducer from "./LayoutReducer";
// import ScrumBoardReducer from "./ScrumBoardReducer";
// import NotificationReducer from "./NotificationReducer";
// import EcommerceReducer from "./EcommerceReducer";
// import MantenimientoReducer from "./MantenimientoReducer";
// import LSSReducer from "./LSSReducer";
// import UsersManageReducer from "./UsersManageReducers";
// import { persistStore } from "redux-persist";
// import BrandReducers from "./BrandReducers";
// import ArticleReducer from "./ArticleReducer";
// import CategoryReducers from "./CategoryReducer";
// import ReviewReducer from "./ReviewReducer";
// import ProductReducer from "./ProductReducer";
// import ProductDetail from "./ProductDetail";
// import QuestionReducer from "./QuestionReducer";
// import ReportReducer from "./ReportReducer";
import WargaBinaanReducer from "./WargaBinaanReducer";
import ArusKasReducer from "./ArusKasReducer";
import LogistikReducer from "./LogistikReducers";
import SurveyReducer from "./SurveyReducer";
import PengaturanReducer from "./PengaturanReducer";
import PemiluTerakhirReducer from "./PemiluTerakhirReducer";
import RelawanReducer from "./RelawanReducers";
import TeamManagementReducer from "./TeamManagementReducer";
import ProvinceReducer from "./ProvinceReducer";
import CityReducer from "./CityReducer";
import GlobalReducer from "./GlobalReducers";
import DistrictReducer from "./DistrictReducer";
import VillagesReducer from "./VillagesReducer";
import StatisticReducer from "./StatisticReducer";
import WilayahReducer from "./WilayahReducer";
import TPSReducer from "./TPSReducer";
import CategoryArusKasReducer from "./CategoryArusKasReducer";
import CategoryLogisticReducer from "./CategoryLogisticReducer";
import LogistikSatuanUnitReducer from "./LogistikSatuanUnitreducer";
import DashboardReducer from "./DashboardReducer";
import NavigationReducer from "./NavigationReducer";
import SebaranSuaraReducer from "./SebaranSuaraReducer";
import HitungCepatReducer from "./HitungCepatReducer";
import LaporanReducer from "./LaporanReducer";
import PembayaranReducer from "./PembayaranReducer";
import DptReducer from "./DptReducer";
import AdminDashboardReducer from "./AdminDashboardReducer";
import UserManagementReducer from './UserManagementReducer';
import AdminManagementReducer from "./AdminManagementReducer";

const appReducer = combineReducers({
  // scrumboard: ScrumBoardReducer,
  // notification: NotificationReducer,
  // mantenimientos: MantenimientoReducer,
  // dataUsers: UsersManageReducer,
  // dataReview: ReviewReducer,
  // dataProduct: ProductReducer,
  // ecommerce: EcommerceReducer,
  // dataCategory: CategoryReducers,
  // dataBrand: BrandReducers,
  // dataArticle: ArticleReducer,
  // detailProduct: ProductDetail,
  // lss: LSSReducer,
  // dataQuestion: QuestionReducer,
  // dataReport: ReportReducer,
  login: LoginReducer,
  user: UserReducer,
  layout: LayoutReducer,
  navigations: NavigationReducer,
  wargaBinaan: WargaBinaanReducer,
  arusKas: ArusKasReducer,
  dataLogistik: LogistikReducer,
  pemilu_terakhir: PemiluTerakhirReducer,
  dataSurvey: SurveyReducer,
  pengaturan: PengaturanReducer,
  relawan: RelawanReducer,
  teamManagement: TeamManagementReducer,
  province: ProvinceReducer,
  city: CityReducer,
  global: GlobalReducer,
  district: DistrictReducer,
  villages: VillagesReducer,
  statistic: StatisticReducer,
  wilayah: WilayahReducer,
  tps: TPSReducer,
  categoryArusKas: CategoryArusKasReducer,
  categoryLogistic: CategoryLogisticReducer,
  logistikSatuanUnit: LogistikSatuanUnitReducer,
  dashboard: DashboardReducer,
  sebaranSuara: SebaranSuaraReducer,
  hitungCepat: HitungCepatReducer,
  laporan: LaporanReducer,
  pembayaran: PembayaranReducer,
  dpt: DptReducer,
  adminDashboard: AdminDashboardReducer,
  userManagement: UserManagementReducer,
  adminManagement: AdminManagementReducer,
});

const RootReducer = (state, action) => {
  if (action.type === "USER_LOGGED_OUT") {
    // for all keys defined in your persistConfig(s)
    // persistStore.removeItem('persist:root')
    // storage.removeItem('persist:otherKey')
    state = undefined;
  }

  return appReducer(state, action);
};

export default RootReducer;
