/* eslint-disable react-hooks/exhaustive-deps */
import {
    Card,
    Grid,
    TextField,
    Select,
    MenuItem,
    InputLabel,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import GreenButton from "app/components/GreenButton";
import InputFileImg from "app/components/Input/InputFileImg";
import { getDataCalonTetapSebagaiPublic } from "app/redux/actions/PengaturanActions";
import { getDetailUserManagement } from "app/redux/actions/UserManagementActions";

const DetailUser = (props) => {
    const users_code = props.match.params.users_code;
    const dispatch = useDispatch();
    const history = useHistory();
    const [state, setState] = useState({
        users_code: users_code,
        users_category: "",
        foto_profile: "",
        foto_profile_preview: "",
        name: "",
        usersname: "",
        email: "",
        phone: "",
    });
    const [errorField, setErrorField] = useState({});
    const { dataCalonTetap } = useSelector(
        (state) => state.pengaturan
    );
    const { detailUserManagement } = useSelector(
        (state) => state.userManagement
    );

    const getData = () => {
        dispatch(getDataCalonTetapSebagaiPublic());
        dispatch(getDetailUserManagement(users_code));
    };

    useEffect(() => {
        getData()
        setState((prevState) => ({
            ...prevState,
            users_category: detailUserManagement.users_category,
            foto_profile: detailUserManagement.foto_profile,
            foto_profile_preview: detailUserManagement.foto_profile_preview,
            name: detailUserManagement.name,
            usersname: detailUserManagement.usersname,
            email: detailUserManagement.email,
            phone: detailUserManagement.phone,
        }));
    }, [detailUserManagement.usersname]);

    return (
        <div className="p-40">
            <Grid
                container
                spacing={3}
                className="mb-2 d-flex justify-content-center align-items-center"
            >
                <Grid item lg={6} md={6} xs={12} sm={6}>
                    <h2 className="font-size-32 fw-700 fw-bold">Detail User</h2>
                </Grid>
                <Grid
                    item
                    lg={6}
                    md={6}
                    xs={12}
                    sm={6}
                    spacing={2}
                    container
                    justify="flex-end"
                >
                    <Grid item>
                        <GreenButton
                            text="Kembali"
                            onClick={() => history.push("/user_management")}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Card style={{ padding: "20px" }}>
                <Grid container className="p-4" spacing={2}>
                    <Grid item lg={12} md={12} xs={12} sm={12}>
                        <h3 className="fw-600 fs-24">Detail User</h3>
                    </Grid>
                    <Grid item className="mb-2" lg={6} md={6} xs={12} sm={6}>
                        <InputLabel htmlFor="users_category">
                            <h5 className="font-size-14">Pilih Kategori Admin</h5>
                        </InputLabel>
                        <Select
                            SelectDisplayProps={{
                                style: {
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    borderRadius: 5,
                                    backgroundColor: "#FBFBFB",
                                },
                            }}
                            label="users_category"
                            size="small"
                            labelid="users_category"
                            value={state.users_category}
                            variant="outlined"
                            className="w-100"
                            name="users_category"
                            displayEmpty
                            disabled
                        >
                            <MenuItem key={"x"} value={""} disabled>
                                Pilih Kategori
                            </MenuItem>
                            {dataCalonTetap?.map((item) => (
                                <MenuItem key={item.tingkat_calon_tetap_code} value={item.tingkat_calon_tetap_code}>
                                    {item.tingkat_calon_tetap_nama}
                                </MenuItem>
                            ))}
                            <MenuItem value="Pilbup (Pemilihan Bupati)">Pilbup (Pemilihan Bupati)</MenuItem>
                            <MenuItem value="Pilgub (Pemilihan Gubernur)">Pilgub (Pemilihan Gubernur)</MenuItem>
                            <MenuItem value="Pilpres (Pemilihan Presiden)">Pilpres (Pemilihan Presiden)</MenuItem>
                        </Select>
                        <small className="text-danger">{errorField?.users_category}</small>
                    </Grid>
                    <Grid item className="mb-2" lg={6} md={6} xs={12} sm={12}></Grid>
                    <Grid item className="mb-2" lg={6} md={6} xs={12} sm={12}>
                        <InputLabel htmlFor="foto_profile">
                            <h5 className="font-size-14">Foto User</h5>
                            {state.foto_profile && (
                                <InputFileImg
                                  noPadding={true}
                                  fileName={state.foto_profile}
                                  preview={state.foto_profile}
                                  disabled={true}
                                />
                            )}
                            <small className="text-danger">{errorField?.foto_profile}</small>
                        </InputLabel>
                    </Grid>

                    <Grid item className="mb-2" lg={6} md={6} xs={12} sm={12}></Grid>

                    <Grid item className="mb-2" lg={6} md={6} xs={12} sm={12}>
                        <InputLabel htmlFor="name">
                            <h5 className="font-size-14">Nama Lengkap Admin</h5>
                        </InputLabel>
                        <TextField
                            required={true}
                            size="small"
                            value={state.name}
                            name="name"
                            className={`w-100`}
                            InputProps={{
                                style: {
                                    borderRadius: 5,
                                },
                            }}
                            placeholder="Nama"
                            variant="outlined"
                            type="text"
                            disabled
                        />
                        <small className="text-danger">{errorField?.name}</small>
                    </Grid>
                    <Grid item className="mb-2" lg={6} md={6} xs={12} sm={12}>
                        <InputLabel htmlFor="usersname">
                            <h5 className="font-size-14">Username</h5>
                        </InputLabel>
                        <TextField
                            required={true}
                            size="small"
                            value={state.usersname}
                            name="usersname"
                            className={`w-100`}
                            InputProps={{
                                style: {
                                    borderRadius: 5,
                                },
                            }}
                            placeholder="Username"
                            variant="outlined"
                            type="text"
                            disabled
                        />
                        <small className="text-danger">{errorField?.usersname}</small>
                    </Grid>

                    <Grid item className="mb-2" lg={6} md={6} xs={12} sm={12}>
                        <InputLabel htmlFor="email">
                            <h5 className="font-size-14">Email</h5>
                        </InputLabel>
                        <TextField
                            required={true}
                            size="small"
                            value={state.email}
                            name="email"
                            className={`w-100`}
                            InputProps={{
                                style: {
                                    borderRadius: 5,
                                },
                            }}
                            placeholder="email@domain.com"
                            variant="outlined"
                            type="text"
                            disabled
                        />
                        <small className="text-danger">{errorField?.email}</small>
                    </Grid>
                    <Grid item className="mb-2" lg={6} md={6} xs={12} sm={12}>
                        <InputLabel htmlFor="phone">
                            <h5 className="font-size-14">Nomor Ponsel</h5>
                        </InputLabel>
                        <TextField
                            required={true}
                            size="small"
                            value={state.phone}
                            name="phone"
                            className={`w-100`}
                            InputProps={{
                                style: {
                                    borderRadius: 5,
                                },
                            }}
                            placeholder="08xxxxx"
                            variant="outlined"
                            type="text"
                            disabled
                        />
                        <small className="text-danger">{errorField?.phone}</small>
                    </Grid>
                </Grid>
            </Card>
        </div>
    );
};

export default DetailUser;
