import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { getAllDistrict } from 'app/redux/actions/DistrictActions';
import MenuActions from "../../components/MenuActions";
import CustomLayout from "../../components/CustomLayout";

const TableKecamatan = () => {
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();

  const { dataAllDistrict } = useSelector(
    (state) => state.district
  )

  const getData = () => {
    dispatch(getAllDistrict());
  };

  useEffect(() => {
    getData();
  }, [dispatch]);

  const districtColumns = [
    { header: "No", field: "id" },
    { header: "Nama Provinsi", field: "province" },
    { header: "Nama Kota/Kabupaten", field: "regency" },
    { header: "Nama Kecamatan", field: "name" },
    {
      header: "Aksi",
      render: (rowData) => (
        <MenuActions
          editPath="/master_data/data_lainnya/data_kecamatan/edit"
          data={rowData}
          item={rowData}
          state={rowData}
        />
      ),
      field: 'aksi'
    },
  ];

  const searchedDataAllDistrict = dataAllDistrict?.data?.data?.data.filter(({ name, regency, province }) =>
    name.toLowerCase().includes(search.toLowerCase()) ||
    regency.toLowerCase().includes(search.toLowerCase()) ||
    province.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <>
      <CustomLayout
        subtitle="Kecamatan"
        columns={districtColumns}
        data={searchedDataAllDistrict}
        button="Tambah Kecamatan"
        addPath="/master_data/data_lainnya/data_kecamatan/tambah"
        search={search}
        setSearch={setSearch}
      />
    </>
  );
};

export default TableKecamatan;
