/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Card,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  useMediaQuery,
} from '@material-ui/core';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import FlagIcon from "@mui/icons-material/Flag";
import { MapPin, Users, UserSquare } from '@phosphor-icons/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  GeoJSON,
  MapContainer,
  TileLayer,
  Tooltip,
  useMap,
} from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import axios from "axios";
import Swal from "sweetalert2";
import indonesia_cities from '../../sebaran_suara/indonesia_geojson/indonesia-cities.json';
import indonesia_provinces from '../../sebaran_suara/indonesia_geojson/indonesia-provinces.json';
import jumlah_suara_provinsi from '../../sebaran_suara/indonesia_data/jumlah_suara_provinsi.json';
import provinsi_indonesia from '../../sebaran_suara/indonesia_geojson/2019-provinsi-indonesia.json';
import kota_indonesia from '../../sebaran_suara/indonesia_geojson/2019-kota-indonesia.json';
import kecamatan_indonesia from '../../sebaran_suara/indonesia_geojson/2019-kecamatan-indonesia.json';
import { Header } from "../../sebaran_suara/components/Header";
import { StatisticPartai } from "../../sebaran_suara/components/StatisticPartai";
import GeneralButton from "app/components/GeneralButton";
import Loader from "app/components/Loader";
import { getAdminDashboardSebaranCaleg } from "app/redux/actions/AdminDashboardActions";
import { getCity, getCityName } from "app/redux/actions/CityActions";
import { getDistrict, getDistrictName } from "app/redux/actions/DistrictActions";
import { getProvince, getProvinceName } from "app/redux/actions/ProvinceActions";
import { getVillages, getVillagesName } from "app/redux/actions/VillagesActions";
import { getGrafikRangkumanSuaraPartai } from "app/redux/actions/PemiluTerakhirActions";
import {
  getDataDapil,
  getDataPartai,
} from "app/redux/actions/PengaturanActions";
import { getSebaranSuara } from "app/redux/actions/SebaranSuaraActions";

const color = [

  "#71BE42",
  "#00ACEE",
  "#E34242",
  "#8F8B66",
  "#9cd27b",
  "#A03472",
  "#734222",
  "#e96868",
  "#eef522",
  "#F4A900",
  "#2EC5FF",
  "#CB3234",
  "#B44C43",
  "#FF2301",
  "#B7EBFF",
  "#C7B446",
];

const SebaranPengguna = () => {
  const dispatch = useDispatch()
  const { dataSebaranSuara, dataSebaranSuaraKota } = useSelector(
    state => state.sebaranSuara
  )
  const { dataCity, dataCityName } = useSelector(
    state => state.city
  )
  const { dataDistrict, dataDistrictName } = useSelector(
    state => state.district
  )
  const { dataProvince, dataProvinceName } = useSelector(
    state => state.province
  )
  const { dataVillages, dataVillagesName } = useSelector(
    state => state.villages
  )
  const { adminDashboardSebaranCaleg } = useSelector(
    state => state.adminDashboard
  )
  // const { dataSebaranSuara } = useSelector((state) => state.sebaranSuara);
  // const [dataSebaranSuaraKota, setDataSebaranSuaraKota] = useState([]);

  const [state, setState] = useState({
    dapil: "",
    namaProvinsi: "",
    namaKota: "",
    namaKecamatan: "",
    namaKelurahan: "",
    namaPartai: "",
    jumlahSuara: "",
    audiens: "",
    namaCaleg: "",
    wilayah: "provinsi",
    loading: true,
  });
  const [ dataSuaraProvinsi, setDataSuaraProvinsi ] = useState([]);
  const [ dataSuaraKotaKab, setDataSuaraKotaKab ] = useState([]);
  const [ dataSuaraKecamatan, setDataSuaraKecamatan ] = useState([]);
  const [ dataSuaraKelurahan, setDataSuaraKelurahan ] = useState([]);
  const [param, setParam] = useState("?wilayah=provinsi");

  const getData = () => {
    // dispatch(getDataPartai());
    // dispatch(getDataDapil());
    // dispatch(getGrafikRangkumanSuaraPartai({ dapil: state.dapil }));
    dispatch(getSebaranSuara(param));
    // // Get mock data kota
    if (param !== undefined) {
      // axios
      //   .get("/api/v1/user/sebaran_suara" + params)
      //   .then((response) => {
      //     console.log("data sebaran suara", response.data);
      //     // setDataSebaranSuaraKota(response.data);
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //   });
      console.log("param", param);

      dispatch(getAdminDashboardSebaranCaleg(param));
    }
    // end
  };

  // useEffect(() => {
  //   updateDapilFromNamaProvinsi();
  //   setState((prevState) => ({
  //     ...prevState,
  //   }));
  //   getData()
  //   getHighestValue()
  // }, [state.namaProvinsi]);

  useEffect(() => {
    let params = "?wilayah=" + state.wilayah; // "provinsi", "kota_kab", "kecamatan", "kelurahan"
    setParam("?wilayah=provinsi")
    // getData();
    dispatch(getAdminDashboardSebaranCaleg("?wilayah=provinsi"));
    dispatch(getProvince());
    // getHighestValue()
    // setState((prevState) => ({
    //   ...prevState,
    //   loading: true,
    // }));
  }, []);

  useEffect(() => {
    const wilayah = adminDashboardSebaranCaleg[0]?.wilayah;
    if (wilayah === "provinsi") {
      setDataSuaraProvinsi(adminDashboardSebaranCaleg);
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }

    if (wilayah === "kota") {
      setDataSuaraKotaKab(adminDashboardSebaranCaleg);
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }

    if (wilayah === "kecamatan") {
      setDataSuaraKecamatan(adminDashboardSebaranCaleg);
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }

    if (wilayah === "kelurahan") {
      setDataSuaraKelurahan(adminDashboardSebaranCaleg);
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
  }, [adminDashboardSebaranCaleg[0]?.wilayah]);

  // useEffect(() => {
  //   getHighestValue();
  //   setState(prevState => ({
  //     ...prevState,
  //     loading: false
  //   }))
  // }, [rangkumanSuaraPartaiGrafik]);

  //   const updateDapilFromNamaProvinsi = () => {
  //     const matchingDapil = dataDapil.find(
  //       dapil =>
  //         dapil.province_name.toLowerCase().replace(/ /g, '') ===
  //         state.namaProvinsi.toLowerCase().replace(/ /g, '')
  //     )
  //     if (matchingDapil) {
  //       setState(prevState => ({
  //         ...prevState,
  //         dapil: matchingDapil.dapil_code
  //       }))
  //     } else {
  //       setState(prevState => ({
  //         ...prevState,
  //         dapil: ''
  //       }))
  //     }
  //   }

  //   const getHighestValue = () => {
  //     if (rangkumanSuaraPartaiGrafik.length > 0) {
  //       const highestValueObject = rangkumanSuaraPartaiGrafik.reduce(
  //         (max, current) => (current.value > max.value ? current : max)
  //       )

  //       setState(prevState => ({
  //         ...prevState,
  //         namaPartai: highestValueObject.name,
  //         jumlahSuara: highestValueObject.value,
  //         loading: false
  //       }))
  //     } else {
  //       setState(prevState => ({
  //         ...prevState,
  //         namaPartai: '-',
  //         jumlahSuara: '-',
  //         loading: false
  //       }))
  //     }
  //   }

  const getWarnaPartai = partai => {
    const warna = {
      PDIP: "#00ACEE",
      DEMOKRAT: "#E34242",
      GERINDRA: "#A03472",
      // PKB: "#038339",
      GOLKAR: "#734222",
      NASDEM: "#eef522",
      PAN: "#71BE42",
      BERKARYA: "#8F8B66",
      GARUDA: "#9cd27b",
      HANURA: "#e96868",
      PBB: "#F4A900",
      PERINDO: "#2EC5FF",
      PKB: "#CB3234",
      PKPI: "#B44C43",
      PPP: "#FF2301",
      PSI: "#B7EBFF",
      PKS: "#C7B446",
    };
    return warna[partai] || "";
  };

  const suara_provinsi = jumlah_suara_provinsi.data

  // const style = (feature) => {
  //   // console.log(feature);
  //   let nama_partai = dataSebaranSuara.filter(
  //     (item) => item.name === feature.properties.NAME_1
  //   );
  //   return {
  //     fillColor: getWarnaPartai(nama_partai[0].partai),
  //     weight: 1,
  //     opacity: 1,
  //     color: "white",
  //     dashArray: "3",
  //     fillOpacity: 0.7,
  //   };
  // };

  // const provGeojson = prov_geojson;
  // console.log(provGeojson);

  const data = indonesia_provinces.features
  // console.log(data);
  const setColor = ({ properties }) => {
    return { weight: 1 };
  };

  const highlightFeature = (e, feature) => {
    const provinces = feature?.properties?.NAME_1
    const cities = feature?.properties?.NAME_2
    switch (type) {
      case 'provinsi':
        if (provinces !== undefined || cities !== undefined) {
          // let sebaranSuara = dataSebaranSuara.filter(
          //   (item) => item.name === provinces.toUpperCase()
          // );
          // get mock data
          // let params = `?wilayah=provinsi`;
          getData();
          const data = adminDashboardSebaranCaleg?.find(sebaranCaleg => sebaranCaleg.provinsi?.toLowerCase() === provinces.toLowerCase());
          setState((prevState) => ({
            ...prevState,
            namaProvinsi: data?.provinsi,
            namaPartai: data?.partai,
            jumlahSuara: data?.jumlah_suara,
            namaCaleg: data?.nama_caleg,
            wilayah: 'provinsi',
            loading: false,
          }))
        } else {
          setState(prevState => ({
            ...prevState,
            namaProvinsi: '',
            namaPartai: '',
            jumlahSuara: '',
            namaCaleg: '',
            wilayah: '',
            loading: false,
          }))
        }

        break
      case 'kota':
        const coordinate = feature?.coordinates
        const coordinateKota = kota_geojson.filter(
          (item) => item.geometry.coordinates === coordinate
        );

        if (coordinateKota.length > 0) {
          // console.log('coordinates', coordinateKota[0])
          // let sebaranSuara = dataSebaranSuaraKota.filter(
            //   (item) =>
            //     item.name === coordinateKota[0]?.properties?.NAME_2?.toUpperCase()
            // );
            // console.log('sebaran suara', sebaranSuara[0]?.partai)
            const type = coordinateKota[0]?.properties['TYPE_2']?.toLowerCase();
            const cityName = coordinateKota[0]?.properties['NAME_2']?.toLowerCase()?.replace(/^kota\s*/i, "");
          // let params = `?wilayah=kota&provinsi=${coordinateKota[0]?.properties?.NAME_1}`;
          setParam(`?wilayah=kota&provinsi=${coordinateKota[0]?.properties?.NAME_1}`);
          getData();
          const data = adminDashboardSebaranCaleg?.find(sebaranCaleg => sebaranCaleg.kota_kab?.toLowerCase() === (type === "kotamadya" ? `kota ${cityName}`: cityName));
          setState(prevState => ({
            ...prevState,
            namaProvinsi: data?.provinsi,
            namaKota: data?.kota_kab,
            namaPartai: data?.partai,
            jumlahSuara: data?.jumlah_suara,
            namaCaleg: data?.nama_caleg,
            wilayah: 'kota',
            loading: false,
          }))
        } else {
          setState(prevState => ({
            ...prevState,
            namaProvinsi: '',
            namaKota: '',
            namaPartai: '',
            jumlahSuara: '',
            namaCaleg: '',
            wilayah: '',
            loading: false,
          }))
        }

        break
      default:
        break
    }

    var layer = e.target
    layer.setStyle({
      weight: 3,
      dashArray: '',
      fillOpacity: 1
    })
    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront()
    }
    // const layer = e.target
    // if (layer) {
    //   layer.setStyle({
    //     weight: 3,
    //     dashArray: '',
    //     fillOpacity: 1
    //   })
    //   if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
    //     layer.bringToFront()
    //   }
    // }
  }

  const resetHighlight = e => {
    // console.log(e);
    e.target.setStyle({
      fillOpacity: 0.7,
      weight: 1,
      dashArray: '3'
    })
  }

  const center = [-4.27, 116.65]
  const zoom = 5

  const [zoomLevel, setZoomLevel] = useState(8)
  const [defaultCenter, setDefaultCenter] = useState(center)
  const [defaultZoom, setDefaultZoom] = useState(zoom)

  const mapRef = useRef()

  const handleResetMap = () => {
    setDefaultCenter(center)
    setDefaultZoom(zoom)
    setSearchFeatureName('')
    setSelectPartai('')
    setSearchGeojson(provinsi_indonesia)
    setType('provinsi')

    const map = mapRef.current
    if (map) {
      map.flyTo(defaultCenter, defaultZoom, {
        animate: true,
        duration: 1
      })
    }
  }
  // BARU 12 OKTOBER 2023

  const [type, setType] = useState('provinsi') // kecamatan, kota, provinsi
  const [provinsi_geojson, setProvinsiGeojson] = useState(provinsi_indonesia)
  const [kota_geojson, setKotaGeojson] = useState(kota_indonesia.features)
  const [kecamatan_geojson, setKecamatanGeojson] = useState(
    kecamatan_indonesia.features
  )
  const [kelurahan_geojson, setKelurahanGeojson] = useState(
    kecamatan_indonesia.features
  )

  // Replace Geojson State ketika ada perubahan
  const [searchGeojson, setSearchGeojson] = useState(provinsi_indonesia)
  const [searchKotaGeojson, setSearchKotaGeojson] = useState(
    kota_indonesia.features
  )
  const [searchKecamatanGeojson, setSearchKecamatanGeojson] = useState("");
  const [searchKelurahanGeojson, setSearchKelurahanGeojson] = useState("");

  const [searchFeatureName, setSearchFeatureName] = useState('')

  const handleChange = e => {
    e.preventDefault()

    const input = e.target.value

    if (input.trim() === '') {
      setSearchGeojson(provinsi_indonesia)
      setType('provinsi')
    }

    setSearchFeatureName(input)
    setSearchGeojson([])
  }

  const handleShowArea = () => {
    const firstWord = searchFeatureName.toLowerCase()
    if (type === 'provinsi') {
      const provinsi = provinsi_geojson.filter(
        item =>
          item.properties.NAME_1.toLowerCase().startsWith(firstWord) ||
          item.properties.NAME_1.toLowerCase().includes(firstWord)
      )

      if (provinsi.length === 0) {
        console.log('error')
      }

      if (provinsi[0] === undefined) {
        console.log('tidak ada')
        Swal.fire({
          title:
            '<div class="custom-swal-title">Provinsi tidak ditemukan.</div>',
          buttonsStyling: false,
          icon: 'error',
          customClass: {
            confirmButton: 'custom-success-button'
          }
        })
      } else if (provinsi[0] !== undefined && searchFeatureName !== '') {
        const coordinates = provinsi[0].geometry.coordinates
        const center = calculateCenter(coordinates[0])
        const map = mapRef.current

        if (map) {
          map.flyTo(center, 6, {
            animate: true,
            duration: 1
          })
        }
      }
      setSearchGeojson(provinsi)
      // console.log("coor", coordinates);
      // console.log("latlng=========", center);
    }
  }

  const calculateCenter = coordinates => {
    // Assuming coordinates are in the format [[[longitude, latitude], [longitude, latitude], ...]]
    const flattenedCoordinates = coordinates.flat(2)
    const latitudes = flattenedCoordinates.filter(
      (coord, index) => index % 2 === 1
    )
    const longitudes = flattenedCoordinates.filter(
      (coord, index) => index % 2 === 0
    )
    const latitude = (Math.min(...latitudes) + Math.max(...latitudes)) / 2
    const longitude = (Math.min(...longitudes) + Math.max(...longitudes)) / 2
    return [latitude, longitude]
  }

  const onEachFeature = (feature, layer) => {
    layer.on({
      // mouseover: e => highlightFeature(e, feature),
      // mouseout: e => resetHighlight(e, feature),

      click: async e => {
        const map = e.target._map;
        const coordinate = e.latlng;

        setZoomLevel(prevZoomLevel => prevZoomLevel <= defaultZoom ? 8 : prevZoomLevel + 2);
        map.flyTo(coordinate, zoomLevel);

        if (type === "provinsi") {
          const clickedArea = feature.properties.NAME_1.toLowerCase();
          const city_feature = kota_indonesia.features.filter(
            (item) => {
              let provinsi = item.properties.NAME_1.toLowerCase();

              if (provinsi === "bangka-belitung") {
                provinsi = `kepulauan ${provinsi.replace("-", " ")}`;
              }
              if (provinsi === "jakarta raya") {
                provinsi = `dki ${provinsi.replace(/\s*raya$/i, "")}`;
              }
              if (provinsi === "yogyakarta") {
                provinsi = `di ${provinsi}`;
              }
              if (provinsi === "irian jaya barat") {
                provinsi = provinsi.replace('irian jaya', 'papua');
              }

              return provinsi === clickedArea;
            }
          );

          // get mock data
          // let params = `?wilayah=kota&provinsi=${clickedArea}`;
          setParam(`?wilayah=kota&provinsi=${clickedArea}`)
          // getData();
          dispatch(getAdminDashboardSebaranCaleg(`?wilayah=kota&provinsi=${clickedArea}`))

          // setDataSuaraKotaKab(adminDashboardSebaranCaleg);
          setState((prevState) => ({
            ...prevState,
            loading: false,
          }));

          // const data = adminDashboardSebaranCaleg?.find(sebaranCaleg => sebaranCaleg.kota_kab?.toLowerCase() === (type === 'kotamadya' ? `kota ${cityName}` : cityName));
          // console.log('data', data);
          // setState((prevState) => ({
          //   ...prevState,
          //   namaProvinsi: data?.provinsi,
          //   namaKota: data?.kota_kab,
          //   namaPartai: data?.partai,
          //   jumlahSuara: data?.jumlah_suara,
          //   namaCaleg: data?.nama_caleg,
          //   wilayah: 'kota',
          //   loading: false,
          // }))


          if (city_feature.length > 0) {
            setKotaGeojson(city_feature)
            setSearchKotaGeojson(city_feature)
            setType("kota")
          }
        }

        if (type === "kota") {
          const clickedArea = feature.properties.NAME_2.toLowerCase().replace(/^kota\s*/i, "").replace("-", "");
          const type = feature.properties.TYPE_2.toLowerCase();
          const clickedAreaProv = feature.properties.NAME_1.toLowerCase();

          const kecamatan = await import(`../../sebaran_suara/indonesia_geojson/kecamatan-indonesia/${clickedAreaProv?.replace(/[\s-]/g, "_")}.json`);
          const kecamatan_feature = kecamatan.features.filter(
            (item) => {
              return item.properties.regency.toLowerCase().replace("-", "") === (type === "kotamadya" ? `kota ${clickedArea}` : clickedArea);
            }
          )

          setParam(`?wilayah=kecamatan&provinsi=${clickedAreaProv}&kota_kab=${(type === "kotamadya" ? `kota ${clickedArea}` : clickedArea)}`)
          // getData();
          dispatch(getAdminDashboardSebaranCaleg(`?wilayah=kecamatan&provinsi=${clickedAreaProv}&kota_kab=${(type === "kotamadya" ? `kota ${clickedArea}` : clickedArea)}`))

          // setDataSuaraKecamatan(adminDashboardSebaranCaleg);
          setState((prevState) => ({
            ...prevState,
            loading: false,
          }));

          if (kecamatan_feature.length > 0) {
            setKecamatanGeojson(kecamatan_feature)
            setSearchKecamatanGeojson(kecamatan_feature)
            setType("kecamatan")
          }
        }

        if (type === "kecamatan") {
          const clickedArea = feature.properties.district.toLowerCase();
          let clickedAreaProv = feature.properties.province.toLowerCase();

          if (clickedAreaProv === "daerah istimewa yogyakarta") {
            clickedAreaProv = "di yogyakarta";
          }
          if (clickedAreaProv === "jakarta raya") {
            clickedAreaProv = "dki jakarta";
          }
          if (clickedAreaProv === "kepulauan bangka belitung") {
            clickedAreaProv = "bangka belitung";
          }
          if (clickedAreaProv === "papua barat") {
            clickedAreaProv = "irian jaya barat";
          }

          const clickedAreaReg = feature.properties.regency.toLowerCase().replace("-", "");

          const kelurahan = await import(`../../sebaran_suara/indonesia_geojson/kecamatan-indonesia/${clickedAreaProv?.replace(/\s+/g, "_")}.json`);
          const kelurahan_feature = kelurahan.features.filter(
            (item) => item.properties.district.toLowerCase() === clickedArea
          );

          setParam(`?wilayah=kelurahan&provinsi=${clickedAreaProv}&kota_kab=${clickedAreaReg}&kecamatan=${clickedArea}`)
          // getData();
          dispatch(getAdminDashboardSebaranCaleg(`?wilayah=kelurahan&provinsi=${clickedAreaProv}&kota_kab=${clickedAreaReg}&kecamatan=${clickedArea}`))

          // setDataSuaraKelurahan(adminDashboardSebaranCaleg);
          setState((prevState) => ({
            ...prevState,
            loading: false,
          }));

          if (kelurahan_feature.length > 0) {
            setKelurahanGeojson(kelurahan_feature)
            setSearchKelurahanGeojson(kelurahan_feature)
            setType("kelurahan")
          }
        }
        // console.log(`Clicked on feature: , Zoom Level: ${map.getZoom()}`);
      }
    })

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const map = useMap()

    map.on('zoomend', () => {
      const zoomLevel = map.getZoom()
      const currentStyle = layer.options.style

      if (zoomLevel <= defaultZoom) {
        setType('provinsi')
        layer.setStyle({ ...currentStyle })
      }

      setZoomLevel(zoomLevel)
    })
  }
  // ==== END: LEAFLET MAPS ==== //

  const styling = (feature, prop_name) => {
    let partai
    if (prop_name === "provinsi") {
      partai = dataSuaraProvinsi?.find(sebaranCaleg => feature.properties.NAME_1.toLowerCase() === sebaranCaleg.provinsi?.toLowerCase())?.partai;
    } else if (prop_name === "kota_kab") {
      const type = feature.properties.TYPE_2.toLowerCase();
      const cityName = feature.properties.NAME_2.toLowerCase().replace(/^kota\s*/i, "");
      partai = dataSuaraKotaKab?.find(sebaranCaleg => ((type === "kotamadya") ? `kota ${cityName}` : cityName) === sebaranCaleg.kota_kab?.toLowerCase())?.partai;
    } else if (prop_name === "kecamatan") {
      partai = dataSuaraKecamatan?.find(sebaranCaleg => feature.properties.district.toLowerCase() === sebaranCaleg.kecamatan?.toLowerCase())?.partai;
    } else if (prop_name === "kelurahan") {
      partai = dataSuaraKelurahan?.find(sebaranCaleg => feature.properties.village.toLowerCase() === sebaranCaleg.kelurahan?.toLowerCase())?.partai;
    }

    return {
      fillColor: getWarnaPartai(partai?.toUpperCase()),
      weight: 1,
      opacity: 1,
      color: 'white',
      dashArray: '3',
      fillOpacity: 0.7
    }
  }

  const [selectProvinsi, setSelectProvinsi] = useState("");
  const [selectKotaKab, setSelectKotaKab] = useState("");
  const [selectKecamatan, setSelectKecamatan] = useState("");
  const [selectKelurahan, setSelectKelurahan] = useState("");
  const [textProvinsi, setTextProvinsi] = useState("");
  const [textKotaKab, setTextKotaKab] = useState("");
  const [textKecamatan, setTextKecamatan] = useState("");
  const [textKelurahan, setTextKelurahan] = useState("");
  const [filterLoading, setFilterLoading] = useState(false);

  const handleSelectProvinsi = (event) => {
    event.persist();
    setSelectKotaKab("");
    setSelectKecamatan("");
    setSelectKelurahan("");
    setTextKotaKab("");
    setTextKecamatan("");
    setTextKelurahan("");
    const value = event.target.value;
    setSelectProvinsi(value);

    setFilterLoading(true);
    dispatch(getProvinceName({ province_code: value }));
    dispatch(getCity({ province_code: value }));
  }
  const handleSelectKotaKab = (event) => {
    event.persist();
    setSelectKecamatan("");
    setSelectKelurahan("");
    setTextKecamatan("");
    setTextKelurahan("");
    const value = event.target.value;
    setSelectKotaKab(value);

    setFilterLoading(true);
    dispatch(getCityName({ city_code: value }));
    dispatch(getDistrict({ city_code: value }));
  }
  const handleSelectKecamatan = (event) => {
    event.persist();
    setSelectKelurahan("");
    setTextKelurahan("");
    const value = event.target.value;
    setSelectKecamatan(value);

    setFilterLoading(true);
    dispatch(getDistrictName({ districts_code: value }));
    dispatch(getVillages({ districts_code: value }));
  }
  const handleSelectKelurahan = (event) => {
    event.persist();
    const value = event.target.value;
    setSelectKelurahan(value);

    setFilterLoading(true);
    dispatch(getVillagesName({ villages_code: value }));
  }

  useEffect(() => {
    setTextProvinsi(dataProvinceName?.name);
    setFilterLoading(false);
  }, [dataProvinceName?.name]);
  useEffect(() => {
    setTextKotaKab(dataCityName?.name);
    setFilterLoading(false);
  }, [dataCityName?.name]);
  useEffect(() => {
    setTextKecamatan(dataDistrictName?.name);
    setFilterLoading(false);
  }, [dataDistrictName?.name]);
  useEffect(() => {
    setTextKelurahan(dataVillagesName?.name);
    setFilterLoading(false);
  }, [dataVillagesName?.name]);

  // const partaiWithColor = partaiTerakhir?.map((item, index) => ({
  //   ...item,
  //   partai_warna: color[index]
  // }))

  const [selectPartai, setSelectPartai] = useState('')
  const [findPartai, setFindPartai] = useState(false)

  const handleSelectPartai = (event, newInput) => {
    event.preventDefault();
    const partai = newInput;
    // console.log(newInput);
    setSelectPartai(partai);

    // Find Partai Provinsi
    const areaPartaiProvinsi = dataSebaranSuara.filter(
      (item) => item.partai === partai
    );
    const provinsiPartai = provinsi_geojson.filter((obj1) =>
      areaPartaiProvinsi.some(
        (obj2) => obj2.name === obj1.properties.NAME_1?.toUpperCase()
      )
    );

    if (type === 'provinsi') {
      setSearchGeojson([])

      setTimeout(() => {
        setSearchGeojson(provinsiPartai)
        setFindPartai(true)

        if (provinsiPartai.length === 0) {
          setSearchGeojson(provinsi_geojson)
        }
      }, 100);
      console.log("areaPartaiProvinsi========", areaPartaiProvinsi);
      console.log("provinsiPartai========", provinsiPartai);
    }

    // Find Partai Kota
    const areaPartaiKota = dataSebaranSuaraKota.filter(
      (item) => item.partai === partai
    );
    const kotaPartai = kota_geojson.filter((obj1) =>
      areaPartaiKota.some(
        (obj2) => obj2.name === obj1.properties.NAME_2?.toUpperCase()
      )
    );

    if (type === 'kota') {
      setKotaGeojson([])

      setTimeout(() => {
        setKotaGeojson(kotaPartai)
        setFindPartai(true)

        if (kotaPartai.length === 0) {
          setKotaGeojson(searchKotaGeojson)
        }
      }, 100);
      // console.log("areaPartaiKota========", areaPartaiKota);
      // console.log("kotaPartai========", kotaPartai);
    }
  };

  const handleFilterWilayah = async () => {
    setFilterLoading(true);

    const map = mapRef.current;
    map.flyTo(defaultCenter, defaultZoom, {
      animate: true,
      duration: 1,
    });

    if (!textKelurahan) {
      if (!textKecamatan) {
        if (!textKotaKab) {
          if (!textProvinsi) {
            return;
          }

          const city_feature = kota_indonesia.features.filter(
            (item) => {
              let provinsi = item.properties.NAME_1.toLowerCase();

              if (provinsi === "bangka-belitung") {
                provinsi = `kepulauan ${provinsi.replace("-", " ")}`;
              }
              if (provinsi === "jakarta raya") {
                provinsi = `dki ${provinsi.replace(/\s*raya$/i, "")}`;
              }
              if (provinsi === "yogyakarta") {
                provinsi = `di ${provinsi}`;
              }
              if (provinsi === 'irian jaya barat') {
                provinsi = provinsi.replace('irian jaya', 'papua');
              }

              return provinsi === textProvinsi.toLowerCase();
            }
          );

          // dispatch(getAdminDashboardSebaranCaleg(`?wilayah=kota&provinsi=${textProvinsi.toLowerCase()}`))

          axios
          .get(`${process.env.REACT_APP_API_URL}/api/v1/admin/dashboard/sebaran_caleg?wilayah=kota&provinsi=${textProvinsi.toLowerCase()}`)
          .then((res) => {
              setDataSuaraKotaKab(res?.data?.data);
              setState((prevState) => ({
                ...prevState,
                loading: false,
              }));

              if (city_feature.length > 0) {
                setKotaGeojson(city_feature);
                setSearchKotaGeojson(city_feature);
                setType("kota");

                const coordinates = city_feature[0]?.geometry.coordinates;
                let center = defaultCenter;
                if (coordinates.length > 0) {
                  center = calculateCenter(coordinates[0]);
                }
                if (map) {
                  map.flyTo(center, 8, {
                    animate: true,
                    duration: 1
                  });
                }
              }
              setFilterLoading(false);
            })
            .catch((error) => {
              console.error(error);
            });
          return;
        }

        let provinsi = textProvinsi.toLowerCase();
        const kota = textKotaKab.toLowerCase().replace(/^kabupaten\s*/i, "").replace("-", "");

        if (provinsi === "kepulauan bangka belitung") {
          provinsi = "bangka belitung";
        }
        if (provinsi === "dki jakarta") {
          provinsi = "jakarta raya";
        }
        if (provinsi === "papua barat") {
          provinsi = "irian jaya barat";
        }

        const kecamatan = await import(`../../sebaran_suara/indonesia_geojson/kecamatan-indonesia/${provinsi?.replace(/[\s-]/g, "_")}.json`);
        const kecamatan_feature = kecamatan.features.filter(
          (item) => {
            return item.properties.regency.toLowerCase().replace("-", "") === kota;
          }
        );

        // dispatch(getAdminDashboardSebaranCaleg(`?wilayah=kecamatan&provinsi=${provinsi}&kota_kab=${kota}`))

        axios
        .get(`${process.env.REACT_APP_API_URL}/api/v1/admin/dashboard/sebaran_caleg?wilayah=kecamatan&provinsi=${provinsi}&kota_kab=${kota}`)
        .then((res) => {
            setDataSuaraKecamatan(res?.data?.data);
            setState((prevState) => ({
              ...prevState,
              loading: false,
            }));

            if (kecamatan_feature.length > 0) {
              setKecamatanGeojson(kecamatan_feature);
              setSearchKecamatanGeojson(kecamatan_feature);
              setType("kecamatan");
              const coordinates = kecamatan_feature[0]?.geometry.coordinates[0];
              let center = defaultCenter;
              if (coordinates.length > 0) {
                center = calculateCenter(coordinates[0]);
              }
              if (map) {
                map.flyTo(center, 10, {
                  animate: true,
                  duration: 1
                });
              }
            }
            setFilterLoading(false);
          })
          .catch((error) => {
            console.error(error);
          });
        return;
      }

      let provinsi = textProvinsi.toLowerCase();
      const kota = textKotaKab.toLowerCase().replace(/^kabupaten\s*/i, "").replace("-", "");
      const kecamatan = textKecamatan.toLowerCase();

      if (provinsi === "kepulauan bangka belitung") {
        provinsi = "bangka belitung";
      }
      if (provinsi === "dki jakarta") {
        provinsi = "jakarta raya";
      }
      if (provinsi === "papua barat") {
        provinsi = "irian jaya barat";
      }

      const kelurahan = await import(`../../sebaran_suara/indonesia_geojson/kecamatan-indonesia/${provinsi?.replace(/[\s-]/g, "_")}.json`);
      const kelurahan_feature = kelurahan.features.filter(
        (item) => {
          return item.properties.district.toLowerCase() === kecamatan;
        }
      );

      // dispatch(getAdminDashboardSebaranCaleg(`?wilayah=kelurahan&provinsi=${provinsi}&kota_kab=${kota}&kecamatan=${kecamatan}`));

      axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/admin/dashboard/sebaran_caleg?wilayah=kelurahan&provinsi=${provinsi}&kota_kab=${kota}&kecamatan=${kecamatan}`)
      .then((res) => {
          setDataSuaraKelurahan(res?.data?.data);
          setState((prevState) => ({
            ...prevState,
            loading: false,
          }));

          if (kelurahan_feature.length > 0) {
            setKelurahanGeojson(kelurahan_feature);
            setSearchKelurahanGeojson(kelurahan_feature);
            setType("kelurahan");
            const coordinates = kelurahan_feature[0]?.geometry.coordinates[0];
            let center = defaultCenter;
            if (coordinates.length > 0) {
              center = calculateCenter(coordinates[0]);
            }
            if (map) {
              map.flyTo(center, 12, {
                animate: true,
                duration: 1
              });
            }
          }
          setFilterLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });
      return;
    }
    setFilterLoading(false);
    return;
  }

  const isMediumScreen = useMediaQuery("(min-width: 960px)");

  return (
    <>
      <div className='mb-sm-30'>
        <h2 className='font-size-32 fw-700 fw-bold'>
          Peta Persebaran Pengguna atau Caleg
        </h2>
      </div>

      <div>
      <Grid container spacing={2} justify="space-around">
        <Grid container item xs={12} md={9} lg={9} sm={12} spacing={isMediumScreen ? 2 : 0}>
          <Grid item xs={12} md={4} lg={4} sm={12} style={{ marginBottom: isMediumScreen ? 0 : 8 }}>
            <Select
              SelectDisplayProps={{
                style: {
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 5,
                },
              }}
              size="small"
              labelid="province_id"
              value={selectProvinsi}
              onChange={handleSelectProvinsi}
              variant="outlined"
              className="w-100"
              name="province_id"
              displayEmpty
            >
              <MenuItem key={"x"} value={""} disabled>
                Pilih Provinsi
              </MenuItem>
              {dataProvince?.map((item) => (
                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} md={4} lg={4} sm={12} style={{ marginBottom: isMediumScreen ? 0 : 8 }}>
            <Select
              SelectDisplayProps={{
                style: {
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 5,
                },
              }}
              size="small"
              labelid="regency_id"
              value={selectKotaKab}
              onChange={handleSelectKotaKab}
              variant="outlined"
              className="w-100"
              name="regency_id"
              displayEmpty
              disabled={!textProvinsi}
            >
              <MenuItem key={"x"} value={""} disabled>
                Pilih Kota Kabupaten
              </MenuItem>
              {dataCity?.map((item) => (
                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} md={4} lg={4} sm={12}>
            <Select
              SelectDisplayProps={{
                style: {
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 5,
                },
              }}
              size="small"
              labelid="distircts_id"
              value={selectKecamatan}
              onChange={handleSelectKecamatan}
              variant="outlined"
              className="w-100"
              name="distircts_id"
              displayEmpty
              disabled={!textKotaKab}
            >
              <MenuItem key={"x"} value={""} disabled>
                Pilih Kecamatan
              </MenuItem>
              {dataDistrict?.map((item) => (
                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
              ))}
            </Select>
          </Grid>
          {/* <Grid item xs={12} md={4} lg={4} sm={12}>
            <Select
              SelectDisplayProps={{
                style: {
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 5,
                },
              }}
              size="small"
              labelid="villages_id"
              value={selectKelurahan}
              onChange={handleSelectKelurahan}
              variant="outlined"
              className="w-100"
              name="villages_id"
              displayEmpty
            >
              <MenuItem key={"x"} value={""} disabled>
                Pilih Kelurahan
              </MenuItem>
              {dataVillages?.map((item) => (
                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
              ))}
            </Select>
          </Grid> */}
        </Grid>
        <Grid container item xs={12} md={3} lg={3} sm={12} justify={isMediumScreen ? "flex-end" : "flex-start"} alignItems="center">
          <Grid item>
            <GeneralButton
              text={filterLoading ? "Loading..." : "Lihat Data"}
              // otherClasses="w-full"
              onClick={handleFilterWilayah}
              disabled={filterLoading || !textProvinsi?.length}
            />
          </Grid>
        </Grid>
      </Grid>
        {/* <Card className='w-100 mb-2'>
          <Header
            onChange={handleChange}
            onResetMap={handleResetMap}
            onSearchFeatureName={searchFeatureName}
            onShowArea={handleShowArea}
          />
        </Card> */}

        <Card className='leaflet-maps mt-2' style={{ position: 'relative' }}>
          <MapContainer
            center={defaultCenter}
            zoom={defaultZoom}
            scrollWheelZoom={true}
            ref={mapRef}
          >
            <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
            {type === 'provinsi' &&
              searchGeojson.length > 0 &&
              searchGeojson.map((item, index) => (
                <GeoJSON
                  key={index}
                  data={item.geometry}
                  style={styling(item, 'provinsi')}
                  onEachFeature={onEachFeature}
                >
                  <Tooltip className='leaflet-tooltip' sticky>
                    <div
                      className='flex gap-2'
                      style={{ alignItems: 'center' }}
                    >
                      <MapPin size={32} />
                      <p className='w-full'>
                        {item.geometry.properties.NAME_1}
                      </p>
                    </div>
                    <div
                      className='flex gap-2'
                      style={{ alignItems: 'center' }}
                    >
                      <FlagIcon
                        style={{
                          fontSize: '32px',
                          color: getWarnaPartai(dataSuaraProvinsi?.find(sebaranCaleg => sebaranCaleg.provinsi?.toLowerCase() === item.geometry.properties.NAME_1.toLowerCase())?.partai)
                        }}
                      />
                      {state.loading ? (
                        <Loader />
                      ) : (
                        <p className='border-bottom w-full'>
                          {dataSuaraProvinsi?.find(sebaranCaleg => sebaranCaleg.provinsi?.toLowerCase() === item.geometry.properties.NAME_1.toLowerCase())?.partai}
                        </p>
                      )}
                    </div>
                    <div
                      className='flex gap-2'
                      style={{ alignItems: 'center' }}
                    >
                      {/* <UserSquare size={30} color='#1C1C1C' /> */}
                      <AccountBoxOutlinedIcon style={{ fontSize: 24 }} />
                      {state.loading ? (
                        <Loader />
                      ) : (
                        <p className='border-bottom w-full'>
                          {dataSuaraProvinsi?.find(sebaranCaleg => sebaranCaleg.provinsi?.toLowerCase() === item.geometry.properties.NAME_1.toLowerCase())?.nama_caleg}
                        </p>
                      )}
                    </div>
                    <div
                      className='flex gap-2'
                      style={{ alignItems: 'center' }}
                    >
                      <Users size={30} color='#1C1C1C' />
                      {state.loading ? (
                        <Loader />
                      ) : (
                        <p className='border-bottom w-full'>
                          {dataSuaraProvinsi?.find(sebaranCaleg => sebaranCaleg.provinsi?.toLowerCase() === item.geometry.properties.NAME_1.toLowerCase())?.jumlah_suara || 0} Suara
                        </p>
                      )}
                    </div>
                  </Tooltip>
                </GeoJSON>
              ))}
            {type === 'kota' &&
              kota_geojson.map((item, index) => (
                <GeoJSON
                  key={index}
                  data={item}
                  style={styling(item, 'kota_kab')}
                  onEachFeature={onEachFeature}
                >
                  <Tooltip className='leaflet-tooltip' sticky>
                    <div
                      className='flex gap-2'
                      style={{ alignItems: 'center' }}
                    >
                      <MapPin size={32} />
                      <p className='w-full'>{item.properties.NAME_2}</p>
                    </div>
                    <div
                      className='flex gap-2'
                      style={{ alignItems: 'center' }}
                    >
                      <FlagIcon
                        style={{
                          fontSize: '32px',
                          color: getWarnaPartai(dataSuaraKotaKab?.find(sebaranCaleg => sebaranCaleg.kota_kab?.toLowerCase() === `${item.properties['TYPE_2'].toLowerCase() === "kotamadya" ? "kota " : ""}${item.properties.NAME_2.toLowerCase().replace(/^kota\s*/i, "")}`)?.partai)
                        }}
                      />
                      {state.loading ? (
                        <Loader />
                      ) : (
                        <p className='border-bottom w-full'>
                          {dataSuaraKotaKab?.find(sebaranCaleg => sebaranCaleg.kota_kab?.toLowerCase() === `${item.properties['TYPE_2'].toLowerCase() === "kotamadya" ? "kota " : ""}${item.properties.NAME_2.toLowerCase().replace(/^kota\s*/i, "")}`)?.partai}
                        </p>
                      )}
                    </div>
                    <div
                      className='flex gap-2'
                      style={{ alignItems: 'center' }}
                    >
                      {/* <UserSquare size={30} color='#1C1C1C' /> */}
                      <AccountBoxOutlinedIcon style={{ fontSize: 24 }} />
                      {state.loading ? (
                        <Loader />
                      ) : (
                        <p className='border-bottom w-full'>
                          {dataSuaraKotaKab?.find(sebaranCaleg => sebaranCaleg.kota_kab?.toLowerCase() === `${item.properties['TYPE_2'].toLowerCase() === "kotamadya" ? "kota " : ""}${item.properties.NAME_2.toLowerCase().replace(/^kota\s*/i, "")}`)?.nama_caleg}
                        </p>
                      )}
                    </div>
                    <div
                      className='flex gap-2'
                      style={{ alignItems: 'center' }}
                    >
                      <Users size={32} />
                      {state.loading ? (
                        <Loader />
                      ) : (
                        <p className='border-bottom w-full'>
                          {dataSuaraKotaKab?.find(sebaranCaleg => sebaranCaleg.kota_kab?.toLowerCase() === `${item.properties['TYPE_2'].toLowerCase() === "kotamadya" ? "kota " : ""}${item.properties.NAME_2.toLowerCase().replace(/^kota\s*/i, "")}`)?.jumlah_suara || 0} Suara
                        </p>
                      )}
                    </div>
                  </Tooltip>
                </GeoJSON>
              ))}
            {type === 'kecamatan' &&
              kecamatan_geojson.map((item, index) => (
                <GeoJSON
                  key={index}
                  data={item}
                  style={styling(item, 'kecamatan')}
                  onEachFeature={onEachFeature}
                >
                  <Tooltip className='leaflet-tooltip' sticky>
                    <div
                      className='flex gap-2'
                      style={{ alignItems: 'center' }}
                    >
                      <MapPin size={32} />
                      <p className='w-full'>{item.properties.district}</p>
                    </div>
                    <div
                      className='flex gap-2'
                      style={{ alignItems: 'center' }}
                    >
                      <FlagIcon
                        style={{
                          fontSize: '32px',
                          color: getWarnaPartai(dataSuaraKecamatan?.find(sebaranCaleg => item.properties.district.toLowerCase() === sebaranCaleg.kecamatan?.toLowerCase())?.partai)
                        }}
                      />
                      {state.loading ? (
                        <Loader />
                      ) : (
                        <p className='border-bottom w-full'>
                          {dataSuaraKecamatan?.find(sebaranCaleg => item.properties.district.toLowerCase() === sebaranCaleg.kecamatan?.toLowerCase())?.partai}
                        </p>
                      )}
                    </div>
                    <div
                      className='flex gap-2'
                      style={{ alignItems: 'center' }}
                    >
                      {/* <UserSquare size={30} color='#1C1C1C' /> */}
                      <AccountBoxOutlinedIcon style={{ fontSize: 24 }} />
                      {state.loading ? (
                        <Loader />
                      ) : (
                        <p className='border-bottom w-full'>
                          {dataSuaraKecamatan?.find(sebaranCaleg => item.properties.district.toLowerCase() === sebaranCaleg.kecamatan?.toLowerCase())?.nama_caleg}
                        </p>
                      )}
                    </div>
                    <div
                      className='flex gap-2'
                      style={{ alignItems: 'center' }}
                    >
                      <Users size={32} />
                      {state.loading ? (
                        <Loader />
                      ) : (
                        <p className='border-bottom w-full'>
                          {dataSuaraKecamatan?.find(sebaranCaleg => item.properties.district.toLowerCase() === sebaranCaleg.kecamatan?.toLowerCase())?.jumlah_suara || 0} Suara
                        </p>
                      )}
                    </div>
                  </Tooltip>
                </GeoJSON>
              ))}
            {type === 'kelurahan' &&
              kelurahan_geojson.map((item, index) => (
                <GeoJSON
                  key={index}
                  data={item.geometry}
                  style={styling(item, 'kelurahan')}
                  onEachFeature={onEachFeature}
                >
                  <Tooltip className='leaflet-tooltip' sticky>
                    <div
                      className='flex gap-2'
                      style={{ alignItems: 'center' }}
                    >
                      <MapPin size={32} />
                      <p className='w-full'>{item.properties.village}</p>
                    </div>
                    <div
                      className='flex gap-2'
                      style={{ alignItems: 'center' }}
                    >
                      <FlagIcon
                        style={{
                          fontSize: '32px',
                          color: getWarnaPartai(dataSuaraKelurahan?.find(sebaranCaleg => item.properties.village.toLowerCase() === sebaranCaleg.kelurahan?.toLowerCase())?.partai)
                        }}
                      />
                      {state.loading ? (
                        <Loader />
                      ) : (
                        <p className='border-bottom w-full'>
                          {dataSuaraKelurahan?.find(sebaranCaleg => item.properties.village.toLowerCase() === sebaranCaleg.kelurahan?.toLowerCase())?.partai}
                        </p>
                      )}
                    </div>
                    <div
                      className='flex gap-2'
                      style={{ alignItems: 'center' }}
                    >
                      {/* <UserSquare size={30} color='#1C1C1C' /> */}
                      <AccountBoxOutlinedIcon style={{ fontSize: 24 }} />
                      {state.loading ? (
                        <Loader />
                      ) : (
                        <p className='border-bottom w-full'>
                          {dataSuaraKelurahan?.find(sebaranCaleg => item.properties.village.toLowerCase() === sebaranCaleg.kelurahan?.toLowerCase())?.nama_caleg}
                        </p>
                      )}
                    </div>
                    <div
                      className='flex gap-2'
                      style={{ alignItems: 'center' }}
                    >
                      <Users size={32} />
                      {state.loading ? (
                        <Loader />
                      ) : (
                        <p className='border-bottom w-full'>
                          {dataSuaraKelurahan?.find(sebaranCaleg => item.properties.village.toLowerCase() === sebaranCaleg.kelurahan?.toLowerCase())?.jumlah_suara || 0} Suara
                        </p>
                      )}
                    </div>
                  </Tooltip>
                </GeoJSON>
              ))}
          </MapContainer>
        </Card>

        {/* LEAFLET LEGENDS */}
        {/* <Card className='w-100 mb-2'>
          <Box className='flex flex-wrap gap-2 p-16'>
            <Grid container>
              <Grid item xs={12} className="mb-3 border-bottom">
                <h4 className="fw-600">Statistik Desa Menang</h4>
              </Grid>
              <StatisticPartai
                dataPartai={partaiWithColor}
                onClick={handleSelectPartai}
              />
            </Grid>
          </Box>
        </Card> */}
      </div>
    </>
  )
}

export default SebaranPengguna
