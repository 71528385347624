/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import InputFileImg from "app/components/Input/InputFileImg";
import GreenButton from "app/components/GreenButton";
import { getDataCalonTetapSebagaiPublic } from "app/redux/actions/PengaturanActions";
import { addUserManagement } from "app/redux/actions/UserManagementActions";
import ModalError from "app/components/modal/ModalError";
import ModalSuccess from "app/components/modal/ModalSuccess";

const AddUser = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [state, setState] = useState({
    users_category: "",
    foto_profile: "",
    foto_profile_preview: "",
    name: "",
    usersname: "",
    email: "",
    phone: "",
    password: "",
    confirm_password: "",
  });
  const [errorField, setErrorField] = useState({});


  const { dataCalonTetap } = useSelector(
    (state) => state.pengaturan
  );

  const getData = () => {
    dispatch(getDataCalonTetapSebagaiPublic());
  };

  useEffect(() => {
    getData()
  }, [dispatch]);

  const handleChange = (e) => {
    e.persist();
    const { name, value } = e.target;

    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleSubmit = () => {
    addUserManagement(state)
      .then((res) => {
        setSuccessModal(true)
        history.push("/user_management");
      })
      .catch((err) => {
        setErrorField(err.response.data.data);
        setErrorModal(true)
      });
    setSuccessModal(true)
    setErrorModal(true)
    console.log(state);
  };

  const handleChangeFoto = (file, path, state) => {
    setState((prev) => ({
      ...prev,
      [state]: file,
      [`${state}_preview`]: path,
    }));
  };

  const handleCloseModal = () => {
    setErrorModal(false);
    setSuccessModal(false);
  };

  return (
    <div className="p-40">
      <Grid
        container
        spacing={3}
        className="mb-2 d-flex justify-content-center align-items-center"
      >
        <Grid item lg={6} md={6} xs={12} sm={6}>
          <h2 className="font-size-32 fw-700 fw-bold">Tambah User</h2>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          xs={12}
          sm={6}
          spacing={2}
          container
          justify="flex-end"
        >
          <Grid item>
            <GreenButton
              text="Kembali"
              onClick={() => history.push("/user_management")}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <GreenButton
              text="Simpan Data"
              onClick={handleSubmit}
              variant="contained"
            />
          </Grid>
        </Grid>
      </Grid>

      <Card style={{ padding: "20px" }}>
        <Grid container className="p-4" spacing={2}>
          <Grid item lg={12} md={12} xs={12} sm={12}>
            <h3 className="fw-600 fs-24">Detail User</h3>
          </Grid>
          <Grid item className="mb-2" lg={6} md={6} xs={12} sm={6}>
            <InputLabel htmlFor="users_category">
              <h5 className="font-size-14">Pilih Kategori Admin</h5>
            </InputLabel>
            <Select
              SelectDisplayProps={{
                style: {
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 5,
                  backgroundColor: "#FBFBFB",
                },
              }}
              label="users_category"
              size="small"
              labelid="users_category"
              value={state.users_category}
              onChange={handleChange}
              variant="outlined"
              className="w-100"
              name="users_category"
              displayEmpty
            >
              <MenuItem key={"x"} value={""} disabled>
                Pilih Kategori
              </MenuItem>
              {dataCalonTetap?.map((item) => (
                <MenuItem key={item.tingkat_calon_tetap_code} value={item.tingkat_calon_tetap_code}>
                  {item.tingkat_calon_tetap_nama}
                </MenuItem>
              ))}
              <MenuItem value="Pilbup (Pemilihan Bupati)">Pilbup (Pemilihan Bupati)</MenuItem>
              <MenuItem value="Pilgub (Pemilihan Gubernur)">Pilgub (Pemilihan Gubernur)</MenuItem>
              <MenuItem value="Pilpres (Pemilihan Presiden)">Pilpres (Pemilihan Presiden)</MenuItem>
            </Select>
            <small className="text-danger">{errorField?.users_category}</small>
          </Grid>
          <Grid item className="mb-2" lg={6} md={6} xs={12} sm={12}></Grid>
          <Grid item className="mb-2" lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="foto_profile">
              <h5 className="font-size-14">Foto User</h5>
              <InputFileImg
                noPadding={true}
                name="foto_profile"
                uploadFoto={(file, path) =>
                  handleChangeFoto(file, path, "foto_profile")
                }
                preview={state.foto_profile_preview}
                acceptFile="image"
              />
              <small className="text-danger">{errorField?.foto_profile}</small>
            </InputLabel>
          </Grid>

          <Grid item className="mb-2" lg={6} md={6} xs={12} sm={12}></Grid>

          <Grid item className="mb-2" lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="name">
              <h5 className="font-size-14">Nama Lengkap Admin</h5>
            </InputLabel>
            <TextField
              required={true}
              size="small"
              value={state.name}
              name="name"
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5,
                },
              }}
              placeholder="Nama"
              variant="outlined"
              onChange={handleChange}
              type="text"
            />
            <small className="text-danger">{errorField?.name}</small>
          </Grid>
          <Grid item className="mb-2" lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="usersname">
              <h5 className="font-size-14">Username</h5>
            </InputLabel>
            <TextField
              required={true}
              size="small"
              value={state.usersname}
              name="usersname"
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5,
                },
              }}
              placeholder="Username"
              variant="outlined"
              onChange={handleChange}
              type="text"
            />
            <small className="text-danger">{errorField?.usersname}</small>
          </Grid>

          <Grid item className="mb-2" lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="email">
              <h5 className="font-size-14">Email</h5>
            </InputLabel>
            <TextField
              required={true}
              size="small"
              value={state.email}
              name="email"
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5,
                },
              }}
              placeholder="email@domain.com"
              variant="outlined"
              onChange={handleChange}
              type="text"
            />
            <small className="text-danger">{errorField?.email}</small>
          </Grid>
          <Grid item className="mb-2" lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="phone">
              <h5 className="font-size-14">Nomor Ponsel</h5>
            </InputLabel>
            <TextField
              required={true}
              size="small"
              value={state.phone}
              name="phone"
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5,
                },
              }}
              placeholder="08xxxxx"
              variant="outlined"
              onChange={handleChange}
              type="text"
            />
            <small className="text-danger">{errorField?.phone}</small>
          </Grid>
          <Grid item className="mb-2" lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="password">
              <h5 className="font-size-14">Password</h5>
            </InputLabel>
            <TextField
              fullWidth
              hiddenLabel
              id="password"
              variant="outlined"
              size="small"
              placeholder="Masukan password"
              type={showPassword ? "text" : "password"}
              name="password"
              value={state.password}
              onChange={handleChange}
              // onKeyDown={handleKeyDown}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOff htmlColor="#4D5B7C" />
                      ) : (
                        <Visibility htmlColor="#4D5B7C" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <small className="text-danger">{errorField?.password}</small>
          </Grid>
          <Grid item className="mb-2" lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="confirm_password">
              <h5 className="font-size-14">Ulangi Password</h5>
            </InputLabel>
            <TextField
              fullWidth
              hiddenLabel
              id="confirm_password"
              variant="outlined"
              size="small"
              placeholder="Ulangi password"
              type={showPassword ? "text" : "password"}
              name="confirm_password"
              value={state.confirm_password}
              onChange={handleChange}
              // onKeyDown={handleKeyDown}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOff htmlColor="#4D5B7C" />
                      ) : (
                        <Visibility htmlColor="#4D5B7C" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <small className="text-danger">{errorField?.confirm_password}</small>
          </Grid>
        </Grid>
      </Card>

      <ModalError
        open={errorModal}
        handleClose={handleCloseModal}
      />
      <ModalSuccess
        open={successModal}
        handleClose={handleCloseModal}
      />
    </div>
  );
};

export default AddUser;
