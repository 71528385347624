import { API, setAuthToken } from "config/API.js";
import {
    GET_ADMIN_DASHBOARD_CALEG,
    GET_ADMIN_DASHBOARD_CALEG_TERBANYAK,
    GET_ADMIN_DASHBOARD_CALON_PEMIMPIN,
    GET_ADMIN_DASHBOARD_SEBARAN_CALEG,
    GET_ADMIN_DASHBOARD_USERS,
} from "../constant.js";

export const getAdminDashboardUsers = (params = "") => {
    return (dispatch) => {
        const token = localStorage.getItem("jwt_token");
        setAuthToken(token);
        API.get(`/admin/dashboard/statistics/users` + params)
            .then((res) => {
                dispatch({
                    type: GET_ADMIN_DASHBOARD_USERS,
                    payload: res.data.data || [],
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_ADMIN_DASHBOARD_USERS,
                    payload: [],
                });
            });
    };
};

export const getAdminDashboardCaleg = (params = "") => {
    return (dispatch) => {
        const token = localStorage.getItem("jwt_token");
        setAuthToken(token);
        API.get(`/admin/dashboard/statistics/caleg` + params)
            .then((res) => {
                dispatch({
                    type: GET_ADMIN_DASHBOARD_CALEG,
                    payload: res.data.data || [],
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_ADMIN_DASHBOARD_CALEG,
                    payload: [],
                });
            });
    };
};

export const getAdminDashboardCalegTerbanyak = (params = "") => {
    return (dispatch) => {
        const token = localStorage.getItem("jwt_token");
        setAuthToken(token);
        API.get(`/admin/dashboard/statistics/caleg_terbanyak` + params)
            .then((res) => {
                dispatch({
                    type: GET_ADMIN_DASHBOARD_CALEG_TERBANYAK,
                    payload: res.data.data || [],
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_ADMIN_DASHBOARD_CALEG_TERBANYAK,
                    payload: [],
                });
            });
    };
};

export const getAdminDashboardCalonPemimpin = (params = "") => {
    return (dispatch) => {
        const token = localStorage.getItem("jwt_token");
        setAuthToken(token);
        API.get(`/admin/dashboard/statistics/calon_pemimpin` + params)
            .then((res) => {
                dispatch({
                    type: GET_ADMIN_DASHBOARD_CALON_PEMIMPIN,
                    payload: res.data.data || [],
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_ADMIN_DASHBOARD_CALON_PEMIMPIN,
                    payload: [],
                });
            });
    };
};

export const getAdminDashboardSebaranCaleg = (params = "") => {
    return (dispatch) => {
        const token = localStorage.getItem("jwt_token");
        setAuthToken(token);
        API.get(`/admin/dashboard/sebaran_caleg` + params)
            .then((res) => {
                dispatch({
                    type: GET_ADMIN_DASHBOARD_SEBARAN_CALEG,
                    payload: res.data.data || [],
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_ADMIN_DASHBOARD_SEBARAN_CALEG,
                    payload: [],
                });
            });
    };
};
