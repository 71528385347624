/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card,
  Grid,
  Icon,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TableContainer,
  TablePagination,
  TextField,
  Hidden,
  Box,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GeneralButton from "app/components/GeneralButton";
import {
  getDataPemiluTerakhir,
  getKabupaten,
  getKecamatan,
  getKelurahan,
  importPemiluTerakhir,
} from "app/redux/actions/PemiluTerakhirActions";
import { Import } from "app/components/icon";
import TableProvince from "./table/TableProvince";
import TableKotaKab from "./table/TableKotaKab";
import TableKecamatan from "./table/TableKecamatan";
import TableKelurahan from "./table/TableKelurahan";
import TableTPS from "./table/TableTPS";
import ModalImport from "./components/ModalImport";
import Swal from "sweetalert2";
import { getDataDapil } from "app/redux/actions/PengaturanActions";
import { useQueryParams } from "helpers/useQueryParams.hook";
import { getDataLogistik } from "app/redux/actions/LogistikActions";

const MasterDataCaleg = () => {
  const dispatch = useDispatch();

  const { queryParams, setQueryParams } = useQueryParams(
    {
      search: "",
      page: 1,
      limit: 10,
      provinsi: "",
      dapil: "",
      kota_kab: "",
      kecamatan: "",
      kelurahan: "",
    },
    {
      search: "",
      page: 1,
      limit: 10,
      provinsi: "",
      dapil: "",
      kota_kab: "",
      kecamatan: "",
      kelurahan: "",
    }
  );

  function handleSearch({
    search,
    page,
    limit,
    provinsi,
    dapil,
    kota_kab,
    kecamatan,
    kelurahan,
  }) {
    setQueryParams({
      ...(search && { search }),
      ...(page && { page }),
      ...(limit && { limit }),
      ...(provinsi && { provinsi }),
      ...(dapil && { dapil }),
      ...(kota_kab && { kota_kab }),
      ...(kecamatan && { kecamatan }),
      ...(kelurahan && { kelurahan }),
    });
  }

  const [state, setState] = useState({
    loading: false,
    page: queryParams.page > 0 ? queryParams.page - 1 : 0,
    rowsPerPageOption: [5, 10, 50, 100],
  });
  const [openImport, setOpenImport] = useState(false);

  const handleSearchChange = (e) => {
    const { name, value } = e.target;

    const updatedSearch = {
      ...queryParams,
      page: 1,
      [name]: value,
    };
    handleSearch(updatedSearch);
  };

  const getData = () => {
    dispatch(getDataDapil());
    dispatch(getDataLogistik());

    dispatch(
      getKabupaten({ provinsi: queryParams.provinsi, dapil: queryParams.dapil })
    );
    dispatch(getKecamatan({ kota_kab: queryParams.kota_kab }));
    dispatch(getKelurahan({ kecamatan: queryParams.kecamatan }));

    setState((prevState) => ({ ...prevState, loading: true }));

    dispatch(getDataPemiluTerakhir(queryParams))
      .then(() => {
        setState((prevState) => ({ ...prevState, loading: false }));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setState((prevState) => ({ ...prevState, loading: false }));
      });
  };

  const updatePagePagination = () => {
    setState({ ...state, page: 0, paramsChange: !state.paramsChange });
  };

  const setRowsPerPage = (e) => {
    const newLimit = e.target.value;
    handleSearch({ ...queryParams, page: 1, limit: newLimit });
    updatePagePagination();
  };

  const handleChangePage = (event, newPage) => {
    handleSearch({
      ...queryParams,
      page: newPage + 1,
    });
    setState({ ...state, page: newPage, paramsChange: !state.paramsChange });
  };

  const handleChangeProvinsi = async (e) => {
    const { name, value } = e.target;
    const prov = value.split("|");

    const updatedSearch = {
      ...queryParams,
      page: 1,
      [name]: prov[0],
      dapil: prov[1],
      kota_kab: "",
      kecamatan: "",
      kelurahan: "",
    };
    handleSearch(updatedSearch);

    await dispatch(getKabupaten({ provinsi: prov[0], dapil: prov[1] }));
    updatePagePagination();
  };

  const handleChangeKabupaten = async (e) => {
    const { name, value } = e.target;

    const updatedSearch = {
      ...queryParams,
      page: 1,
      [name]: value,
    };
    handleSearch(updatedSearch);

    await dispatch(getKecamatan({ kota_kab: value }));
    updatePagePagination();
  };

  const submitSearch = (e) => {
    if (e.keyCode === 13) {
      handleSearch({ ...queryParams, page: 1 });
      updatePagePagination();
    }
  };

  const handleChangeKecamatan = async (e) => {
    const { name, value } = e.target;

    const updatedSearch = {
      ...queryParams,
      page: 1,
      [name]: value,
    };
    handleSearch(updatedSearch);
    await dispatch(getKelurahan({ kecamatan: value }));
    updatePagePagination();
  };

  const handleChangeKelurahan = async (e) => {
    const { name, value } = e.target;

    const updatedSearch = {
      ...queryParams,
      page: 1,
      [name]: value,
    };
    handleSearch(updatedSearch);
    updatePagePagination();
  };

  useEffect(() => {
    getData();
  }, [state.paramsChange]);

  const tableCondition = () => {
    return (
      queryParams.provinsi === "" &&
      queryParams.kota_kab === "" &&
      queryParams.kecamatan === "" &&
      queryParams.kelurahan === ""
    );
  };

  const renderTable = (data, searchValue) => {
    if (
      queryParams.provinsi === "" &&
      queryParams.kota_kab === "" &&
      queryParams.kecamatan === "" &&
      queryParams.kelurahan === ""
    ) {
      return (
        <TableProvince
          data={data}
          search={searchValue}
          queryParams={queryParams}
          state={state}
        />
      );
    } else if (
      queryParams.provinsi !== "" &&
      queryParams.kota_kab === "" &&
      queryParams.kecamatan === "" &&
      queryParams.kelurahan === ""
    ) {
      return (
        <TableKotaKab
          data={data}
          search={searchValue}
          queryParams={queryParams}
          state={state}
        />
      );
    } else if (
      queryParams.provinsi !== "" &&
      queryParams.kota_kab !== "" &&
      queryParams.kecamatan === "" &&
      queryParams.kelurahan === ""
    ) {
      return (
        <TableKecamatan
          data={data}
          search={searchValue}
          queryParams={queryParams}
          state={state}
        />
      );
    } else if (
      queryParams.provinsi !== "" &&
      queryParams.kota_kab !== "" &&
      queryParams.kecamatan !== "" &&
      queryParams.kelurahan === ""
    ) {
      return (
        <TableKelurahan
          data={data}
          search={searchValue}
          queryParams={queryParams}
          state={state}
        />
      );
    } else if (
      queryParams.provinsi !== "" &&
      queryParams.kota_kab !== "" &&
      queryParams.kecamatan !== "" &&
      queryParams.kelurahan !== ""
    ) {
      return (
        <TableTPS
          data={data}
          search={searchValue}
          queryParams={queryParams}
          state={state}
        />
      );
    }
  };

  const {
    dataPemiluTerakhir,
    dataPemiluTerakhirPagination,
    kabupaten,
    kecamatan,
    kelurahan,
  } = useSelector((state) => state.pemilu_terakhir);

  const { dataDapil } = useSelector((state) => state.pengaturan);

  const handleClose = () => {
    setOpenImport(!openImport);
  };

  const resetParams = () => {
    setQueryParams({
      ...queryParams,
      page: 1,
      limit: 10,
      provinsi: "",
      kota_kab: "",
      kecamatan: "",
      kelurahan: "",
      dapilValue: "",
    });
  };
  const handleSubmitImport = (data) => {
    importPemiluTerakhir(data)
      .then((res) => {
        Swal.fire("Berhasil", "Data berhasil diimport", "success");
        handleClose();
        resetParams();
        getData();
      })
      .catch((err) => {
        Swal.fire("Gagal", "Data gagal diimport", "error");
        handleClose();
      });
  };

  return (
    <>
      <Card className="overflow-auto p-24 mb-3" style={{ width: "100%" }} elevation={6}>
        <div className="mb-sm-30">
          <h2 className="font-size-32 fw-700 fw-bold mt-2 h1-title">Peserta Pemilu 2019</h2>
        </div>
        <Grid
          container
          spacing={3}
          className="mb-4 d-flex justify-content-between"
        >
          <Grid item lg={9} md={9} sm={9} >
            <h2 className="h2-subtitle">Daftar Dapil yang Tersedia</h2>
          </Grid>

          <Grid
            item
            lg={3}
            md={3}
            sm={3}
            className="d-flex flex-wrap flex-end p-0 m-0"
          >
            <GeneralButton
              variant="contained"
              color="primary"
              text="Import Suara Partai"
              icon={<Import />}
              info="Unggah file Excel atau CSV"
              onClick={handleClose}
            />
          </Grid>
        </Grid>

        <Hidden mdUp>
          <Grid
            container
            spacing={3}
            className="mb-4 px-2 d-flex align-items-center justify-content-between"
          >
            <Grid item sm={6} xs={6} className="px-1">
              <InputLabel htmlFor="provinsi">
                <h5 className="fs-12 text-muted fw-400">Dapil</h5>
              </InputLabel>
              <Select
                SelectDisplayProps={{
                  style: {
                    paddingTop: 10,
                    paddingBottom: 10,
                    borderRadius: 5,
                  },
                }}
                size="small"
                labelid="provinsi"
                value={queryParams.provinsi + "|" + queryParams.dapil}
                onChange={handleChangeProvinsi}
                variant="outlined"
                className="w-100 d-flex flex-middle p-0"
                name="provinsi"
                displayEmpty
              >
                <MenuItem value="|">Pilih Dapil</MenuItem>
                {dataDapil.map((item) => (
                  <MenuItem
                    key={item.dapil_code}
                    value={item.province_code + "|" + item.dapil_code}
                  >
                    {item.dapil_nama}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item sm={6} xs={6} className="px-1">
              <InputLabel htmlFor="kota_kab">
                <h5 className="fs-12 text-muted fw-400">Kota/Kabupaten</h5>
              </InputLabel>
              <Select
                SelectDisplayProps={{
                  style: {
                    paddingTop: 10,
                    paddingBottom: 10,
                    borderRadius: 5,
                  },
                }}
                size="small"
                labelid="kota_kab"
                value={queryParams.kota_kab}
                onChange={handleChangeKabupaten}
                variant="outlined"
                className="w-100 d-flex flex-middle p-0"
                name="kota_kab"
                displayEmpty
              >
                <MenuItem value="">Pilih Kota</MenuItem>
                {kabupaten.map((item) => (
                  <MenuItem key={item.kota_kab_code} value={item.kota_kab_code}>
                    {item.kota_kab}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item sm={6} xs={6} className="px-1">
              <InputLabel htmlFor="kecamatan">
                <h5 className="fs-12 text-muted fw-400">Kecamatan</h5>
              </InputLabel>
              <Select
                SelectDisplayProps={{
                  style: {
                    paddingTop: 10,
                    paddingBottom: 10,
                    borderRadius: 5,
                  },
                }}
                size="small"
                labelid="kecamatan"
                value={queryParams.kecamatan}
                onChange={handleChangeKecamatan}
                variant="outlined"
                className="w-100 d-flex flex-middle p-0"
                name="kecamatan"
                displayEmpty
              >
                <MenuItem value="">Pilih Kecamatan</MenuItem>
                {kecamatan.map((item) => (
                  <MenuItem
                    key={item.kecamatan_code}
                    value={item.kecamatan_code}
                  >
                    {item.kecamatan}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item sm={6} xs={6} className="px-1">
              <InputLabel htmlFor="kelurahan">
                <h5 className="fs-12 text-muted fw-400">Kelurahan</h5>
              </InputLabel>
              <Select
                SelectDisplayProps={{
                  style: {
                    paddingTop: 10,
                    paddingBottom: 10,
                    borderRadius: 5,
                  },
                }}
                size="small"
                labelid="kelurahan"
                value={queryParams.kelurahan}
                onChange={handleChangeKelurahan}
                variant="outlined"
                className="w-100 d-flex flex-middle p-0"
                name="kelurahan"
                displayEmpty
              >
                <MenuItem value="">Pilih Kelurahan</MenuItem>
                {kelurahan.map((item) => (
                  <MenuItem
                    key={item.kelurahan_code}
                    value={item.kelurahan_code}
                  >
                    {item.kelurahan}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </Hidden>

        <Grid
          container
          spacing={3}
          className="mb-4 d-flex align-items-end justify-content-between"
        >
          <Grid item lg={4} md={4} sm={6} xs={12} className="px-2">
            <TextField
              size="small"
              variant="outlined"
              color="primary"
              className="items-center background-white w-full"
              placeholder={"Cari"}
              onChange={handleSearchChange}
              value={queryParams.search}
              onKeyDown={submitSearch}
              name="search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon color="disabled">search</Icon>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Hidden smDown>
            <Grid item lg={2} md={2} sm={6} className="px-2">
              <InputLabel htmlFor="provinsi">
                <h5 className="fs-12 text-muted fw-400">Dapil</h5>
              </InputLabel>
              <Select
                SelectDisplayProps={{
                  style: {
                    paddingTop: 10,
                    paddingBottom: 10,
                    borderRadius: 5,
                  },
                }}
                size="small"
                labelid="provinsi"
                value={queryParams.provinsi + "|" + queryParams.dapil}
                onChange={handleChangeProvinsi}
                variant="outlined"
                className="w-100 d-flex flex-middle p-0"
                name="provinsi"
                displayEmpty
              >
                <MenuItem value="|">Pilih Dapil</MenuItem>
                {dataDapil.map((item) => (
                  <MenuItem
                    key={item.dapil_code}
                    value={item.province_code + "|" + item.dapil_code}
                  >
                    {item.dapil_nama}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item lg={2} md={2} sm={6} className="px-2">
              <InputLabel htmlFor="kota_kab">
                <h5 className="fs-12 text-muted fw-400">Kota/Kabupaten</h5>
              </InputLabel>
              <Select
                SelectDisplayProps={{
                  style: {
                    paddingTop: 10,
                    paddingBottom: 10,
                    borderRadius: 5,
                  },
                }}
                size="small"
                labelid="kota_kab"
                value={queryParams.kota_kab}
                onChange={handleChangeKabupaten}
                variant="outlined"
                className="w-100 d-flex flex-middle p-0"
                name="kota_kab"
                displayEmpty
              >
                <MenuItem value="">Pilih Kota</MenuItem>
                {kabupaten.map((item) => (
                  <MenuItem key={item.kota_kab_code} value={item.kota_kab_code}>
                    {item.kota_kab}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item lg={2} md={2} sm={6} className="px-2">
              <InputLabel htmlFor="kecamatan">
                <h5 className="fs-12 text-muted fw-400">Kecamatan</h5>
              </InputLabel>
              <Select
                SelectDisplayProps={{
                  style: {
                    paddingTop: 10,
                    paddingBottom: 10,
                    borderRadius: 5,
                  },
                }}
                size="small"
                labelid="kecamatan"
                value={queryParams.kecamatan}
                onChange={handleChangeKecamatan}
                variant="outlined"
                className="w-100 d-flex flex-middle p-0"
                name="kecamatan"
                displayEmpty
              >
                <MenuItem value="">Pilih Kecamatan</MenuItem>
                {kecamatan.map((item) => (
                  <MenuItem
                    key={item.kecamatan_code}
                    value={item.kecamatan_code}
                  >
                    {item.kecamatan}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item lg={2} md={2} sm={6} className="px-2">
              <InputLabel htmlFor="kelurahan">
                <h5 className="fs-12 text-muted fw-400">Kelurahan</h5>
              </InputLabel>
              <Select
                SelectDisplayProps={{
                  style: {
                    paddingTop: 10,
                    paddingBottom: 10,
                    borderRadius: 5,
                  },
                }}
                size="small"
                labelid="kelurahan"
                value={queryParams.kelurahan}
                onChange={handleChangeKelurahan}
                variant="outlined"
                className="w-100 d-flex flex-middle p-0"
                name="kelurahan"
                displayEmpty
              >
                <MenuItem value="">Pilih Kelurahan</MenuItem>
                {kelurahan.map((item) => (
                  <MenuItem
                    key={item.kelurahan_code}
                    value={item.kelurahan_code}
                  >
                    {item.kelurahan}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Hidden>
        </Grid>

        <TableContainer>
          {renderTable(dataPemiluTerakhir, queryParams.search)}
        </TableContainer>

        <div style={{ minWidth: tableCondition() ? 900 : 700 }}>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" flexDirection="row" p={1} alignItems="center">
              <Box p={1}>
                <div className="flex">
                  <p>Rows per page : </p>
                  <select
                    labelid="simple-select"
                    value={queryParams.limit}
                    name="rowsPerPage"
                    onChange={setRowsPerPage}
                    variant="standard"
                    className="border-none"
                  >
                    {state.rowsPerPageOption.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </Box>
            </Box>
            <Box>
              <TablePagination
                className="px-16"
                rowsPerPageOptions={[]}
                component="div"
                count={dataPemiluTerakhirPagination?.total || 0}
                rowsPerPage={queryParams.limit}
                labelRowsPerPage={""}
                page={dataPemiluTerakhirPagination?.current_page - 1 || 0}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={setRowsPerPage}
              />
            </Box>
          </Box>
        </div>
      </Card>

      <ModalImport
        open={openImport}
        handleClose={handleClose}
        handleSubmit={handleSubmitImport}
      />
    </>
  );
};

export default MasterDataCaleg;
