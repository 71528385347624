import {
  Clock,
  Users,
  Gear,
  Package,
  ClipboardText,
  PresentationChart,
  MapTrifold,
  Gauge,
  ArrowsLeftRight,
  CurrencyCircleDollar,
  Wallet,
  UsersThree,
  ChartLineUp,
  FolderSimpleLock,
  UserCircle
} from "@phosphor-icons/react";
import React from "react";
import { authRoles } from "./auth/authRoles";
import UserOctagon from "./components/icon/UserOctagon";
import FolderConnection from "./components/icon/FolderConnection";

export const navigations = [
  {
    name: "Dashboard",
    path: "/dashboard",
    auth: authRoles.superadm,
    icon: <ChartLineUp style={{ marginBottom: "15px" }} />,
  },
  {
    name: "User Management",
    path: "/user_management",
    auth: authRoles.superadm,
    icon: <UsersThree style={{ marginBottom: "15px" }} />,
  },
  {
    name: "Adm Management",
    path: "/adm_management",
    auth: authRoles.superadm,
    icon: <UserCircle style={{ marginBottom: "15px" }} />,
  },
  {
    name: "Master Data",
    path: "/master_data",
    auth: authRoles.superadm,
    icon: <FolderSimpleLock style={{ marginBottom: "15px" }} />,
    children: [
      {
        name: "Peserta Pemilu 2019",
        path: "/master_data/peserta_pemilu_terakhir",
        auth: authRoles.superadm,
        exact: true,
      },
      {
        name: "Data Lainnya",
        path: "/master_data/data_lainnya",
        auth: authRoles.superadm,
        exact: true,
      },
      {
        name: "Setting",
        path: "/master_data/setting",
        auth: authRoles.superadm,
        exact: true,
      },
    ]
  },
];
