import { API, setAuthToken } from "config/API.js";
import { GET_ALL_PROVINCE, GET_PROVINCE, GET_PROVINCE_NAME } from "../constant.js";

export const getAllProvince = (params = "") => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("jwt_token");
      setAuthToken(token);
      const res = await API.get(`/provinsi?${new URLSearchParams(params)}`);
      dispatch({
        type: GET_ALL_PROVINCE,
        payload: res.data || [],
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_PROVINCE,
        payload: [],
      });
      throw error;
    }
  };
};

export const getProvince = (params = "") => {
  return (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.get(`/provinsi/list` + params)
      .then((res) => {
        dispatch({
          type: GET_PROVINCE,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_PROVINCE,
          payload: [],
        });
      });
  };
};

export const getProvinceName = (body) => {
  return (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.post('/provinsi-name', body)
      .then((res) => {
        dispatch({
          type: GET_PROVINCE_NAME,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_PROVINCE_NAME,
          payload: [],
        });
      });
  };
}

export const addProvince = (body) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.post("/admin/provinsi/tambah", body);
}

export const updateProvince = (body) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.post("/admin/provinsi/update", body);
}
