import React from "react";
import { Redirect } from "react-router-dom";

import sessionRoutes from "./views/sessions/SessionRoutes";
import ProfileRoutes from "./views/profile/ProfileRoutes";
import dashboardRoutes from "./views/dashboard/DashboardRoutes";
import AdminManagementRoutes from "./views/adm_management/AdminManagementRoutes";
import UserManagementRoutes from "./views/user_management/UserManagementRoutes.js";
import MasterDataRoutes from "./views/master_data/MasterDataRoutes";
// import RelawanRoutes from "./views/relawan/RelawanRoutes";
// import WargaBinaanRoutes from "./views/warga_binaan/WargaBinaanRoutes";
// import ArusKasRoutes from "./views/arus_kas/ArusKasRoutes";
// import StatistikRoutes from "./views/statistik/StatistikRoutes";
// import LogistikRoutes from "./views/logistik/LogistikRoutes";
// import SurveyRoutes from "./views/survey/SurveyRoutes";
// import LaporanRoutes from "./views/laporan/LaporanRoutes";
// import PengaturanRoutes from "./views/pengaturan/PengaturanRoutes";
// import PemiluTerakhirRoutes from "./views/pemilu_terakhir/PemiluTerakhirRoutes";
// import HitungCepatRoutes from "./views/hitung_cepat/HitungCepatRoutes";
// import SebaranSuaraRoutes from "./views/sebaran_suara/SebaranSuaraRoutes";
// import PembayaranRoutes from "./views/pembayaran/PembayaranRoutes";

const redirectRoute = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
];

const errorRoute = [
  {
    component: () => <Redirect to="/session/404" />,
  },
];

const routes = [
  ...sessionRoutes,
  ...ProfileRoutes,
  ...dashboardRoutes,
  ...AdminManagementRoutes,
  ...UserManagementRoutes,
  ...MasterDataRoutes,
  // ...RelawanRoutes,
  // ...WargaBinaanRoutes,
  // ...StatistikRoutes,
  // ...ArusKasRoutes,
  // ...LogistikRoutes,
  // ...SurveyRoutes,
  // ...LaporanRoutes,
  // ...PengaturanRoutes,
  // ...PemiluTerakhirRoutes,
  // ...HitungCepatRoutes,
  // ...SebaranSuaraRoutes,
  // ...PembayaranRoutes,
  ...redirectRoute,
  ...errorRoute,
];

export default routes;
