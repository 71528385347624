/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card,
  Grid,
  Icon,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Box,
  Hidden,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GeneralButton from "app/components/GeneralButton";
import { getDetailTPS } from "app/redux/actions/PemiluTerakhirActions";
import { Import } from "app/components/icon";
import { useHistory, Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { ArrowCircleLeft } from "@phosphor-icons/react";
import { useQueryParams } from "helpers/useQueryParams.hook";

const PemiluTerakhir = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const { queryParams, setQueryParams } = useQueryParams(
    {
      page: 1,
      limit: 10,
      search: "",
    },
    {
      page: 0,
      limit: 10,
      search: "",
    }
  );

  function handleSearch({ page, limit, search }) {
    setQueryParams({
      ...(page && { page }),
      ...(limit && { limit }),
      ...(search && { search }),
    });
  }

  const [state, setState] = useState({
    loading: true,
    page: queryParams.page > 0 ? queryParams.page - 1 : 0,
    rowsPerPageOption: [5, 10, 50, 100],
  });

  const [loading, setLoading] = useState(true);

  const getData = () => {
    dispatch(
      getDetailTPS(id, {
        page: queryParams.page,
        limit: queryParams.limit,
      })
    );
  };

  const setRowsPerPage = (event) => {
    const newLimit = event.target.value;
    handleSearch({ page: 1, limit: newLimit });
  };

  const handleChangePage = (event, newPage) => {
    handleSearch({ page: newPage + 1, limit: queryParams.limit });
    setState({ ...state, page: newPage });
    setLoading(true);
  };

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    handleSearch({ [name]: value });
  };

  useEffect(() => {
    getData();
  }, [queryParams]);

  const {
    detailTPS = [],
    detailTPSPagination = {},
    tps = {},
  } = useSelector((state) => state.pemilu_terakhir);

  useEffect(() => {
    if (detailTPS.length > 0) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [detailTPS]);

  return (
    <div className="p-40">
      <div className="mb-sm-30">
        <Grid container spacing={3}>
          <Grid item lg={8} md={8} xs={12} sm={6}>
            <div className="flex gap-2 align-items-center">
              <Link to={`/master_data/peserta_pemilu_terakhir?tab=caleg`} className="back-button">
                <ArrowCircleLeft size={30} />
              </Link>
              <h2 className="fw-700">Daftar Daerah Pemilihan</h2>
            </div>
          </Grid>
          <Grid item lg={4} md={4} xs={12} sm={6} container justify="flex-end">
            <Hidden xsDown>
              <GeneralButton
                variant="contained"
                color="secondary"
                text="Kembali"
                otherClass="text-white"
                onClick={() => history.push("/master_data/peserta_pemilu_terakhir?tab=caleg")}
              />
            </Hidden>
          </Grid>
        </Grid>
      </div>
      <Card className="w-100 overflow-auto p-24" elevation={6}>
        <Grid
          container
          spacing={3}
          className="mb-4 px-2"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item lg={9} md={9} className="ps-0">
            <h2 className="fs-24 fw-700">Daftar Dapil yang Tersedia</h2>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          className="mb-4 px-2"
          alignItems="flex-end"
          justifyContent="space-between"
        >
          <Grid item lg={12} md={12} className="ps-0">
            <h5 className="fs-16 text-uppercase">
              {tps.kelurahan} / {tps.tps}
            </h5>
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={6} className="p-0">
            <TextField
              size="small"
              variant="outlined"
              color="primary"
              className="items-center background-white w-full"
              placeholder={"Cari"}
              onChange={handleSearchChange}
              value={queryParams.search}
              name="search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon color="disabled">search</Icon>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <TableContainer>
          <Table className="t-container" style={{ minWidth: 600 }}>
            <TableHead className="t-header">
              <TableRow>
                <TableCell align="center">No</TableCell>
                <TableCell align="left">Partai</TableCell>
                <TableCell align="left" colSpan={2}>
                  Caleg
                </TableCell>
                <TableCell align="center">Jumlah Suara</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="t-body">
              {loading ? (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    Loading ...
                  </TableCell>
                </TableRow>
              ) : detailTPS.filter((item) =>
                item.nama_caleg
                  .toLowerCase()
                  .includes(queryParams.search.toLocaleLowerCase())
              ).length > 0 ? (
                detailTPS
                  .filter((item) =>
                    item.nama_caleg
                      .toLowerCase()
                      .includes(queryParams.search.toLocaleLowerCase())
                  )
                  .map((item, index) => (
                    <TableRow key={index}>
                      <TableCell className="px-0" align="center">
                        {state.page * queryParams.limit + (index + 1)}
                      </TableCell>
                      <TableCell className="px-0" align="left">
                        {item.partai}
                      </TableCell>
                      <TableCell className="px-0" align="left" colSpan={2}>
                        {item.nama_caleg}
                      </TableCell>
                      <TableCell className="px-0" align="center">
                        {item.jumlah_suara}
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    Data kosong
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>

          <div style={{ minWidth: 600 }}>
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" flexDirection="row" p={1} alignItems="center">
                <Box p={1}>
                  <div className="flex">
                    <p>Rows per page : </p>
                    <select
                      labelid="simple-select"
                      value={queryParams.limit}
                      name="rowsPerPage"
                      onChange={setRowsPerPage}
                      variant="standard"
                      className="border-none"
                    >
                      {state.rowsPerPageOption.map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                </Box>
              </Box>
              <Box>
                <TablePagination
                  className="px-16"
                  rowsPerPageOptions={false}
                  component="div"
                  count={detailTPSPagination.total}
                  rowsPerPage={queryParams.limit}
                  labelRowsPerPage={""}
                  page={state.page}
                  backIconButtonProps={{
                    "aria-label": "Previous page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Next page",
                  }}
                  backIconButtonText="Previous page"
                  nextIconButtonText="Next page"
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={setRowsPerPage}
                />
              </Box>
            </Box>
          </div>
        </TableContainer>
      </Card>
    </div>
  );
};

export default PemiluTerakhir;
