import React from "react";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ArrowCircleLeft } from "@phosphor-icons/react";
import GeneralButton from "app/components/GeneralButton";
import GreenButton from "app/components/GreenButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const HeadTitle = ({ title, onClick, backPath, btnText }) => {
  const history = useHistory();

  return (
    <div className="mb-sm-30 flex justify-content-between align-items-center">
      {/* <Grid container spacing={3}>
        <Grid item lg={8} md={8} xs={12} sm={8}>
          <div className="flex gap-2 align-items-center">
            <Link to={backPath} className="back-button">
              <ArrowCircleLeft size={30} />
            </Link>
            <h1 className="h1-title">{title}</h1>
          </div>
        </Grid>
        <Grid item lg={4} md={4} xs={12} sm={4} container justify="flex-end">
          <GeneralButton
            variant="contained"
            color="secondary"
            text={btnText}
            otherClass="text-white"
            onClick={onClick}
          />
        </Grid>
      </Grid> */}

      <Grid
        container
        spacing={3}
        className="mb-2 d-flex justify-content-center align-items-center"
      >
        <Grid item lg={6} md={6} xs={12} sm={6}>
          <h2 className="font-size-32 fw-700 fw-bold">{title}</h2>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          xs={12}
          sm={6}
          spacing={2}
          container
          justify="flex-end"
        >
          <Grid item>
            <GreenButton
              text="Kembali"
              onClick={() => history.push(backPath)}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <GreenButton
              text={btnText || "Simpan Data"}
              onClick={onClick}
              variant="contained"
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default HeadTitle;
