import React, { Fragment } from "react";
import { Box, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import { useLocation, useHistory } from "react-router-dom";
import NavTabs from "app/components/fragment/NavTabs";
import { Hexagon } from "@phosphor-icons/react";
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import MasterDataPartai from "./partai/MasterPartaiTerakhir";
import MasterDataCaleg from "./caleg/MasterDataCaleg";

const PesertaPemilu = () => {
    const location = useLocation();
    const history = useHistory();
    const params = new URLSearchParams(location.search);
    const initialTab = params.get("tab") || "partai";

    const tabs = [
        {
            key: "partai",
            title: "Partai",
            path: "/master_data/peserta_pemilu_terakhir?tab=partai",
            icon: <Hexagon style={{ fontSize: 24, marginRight: 5 }} />,
        },
        {
            key: "caleg",
            title: "Caleg",
            path: "/master_data/peserta_pemilu_terakhir?tab=caleg",
            icon: <AccountBoxOutlinedIcon style={{ fontSize: 24, marginRight: 5 }} />,
        },
    ];

    return (
        <Fragment>
            <Box className="p-40">
                <h2 className="font-size-32 fw-700 fw-bold mb-sm-30">Master Data</h2>

                <Card className="w-100 mb-3">
                    <NavTabs initialTab={initialTab} history={history} tabs={tabs} />
                </Card>

                <Card>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={12} md={12} sm={12}>
                            {initialTab === "partai" && <MasterDataPartai />}
                            {initialTab === "caleg" && <MasterDataCaleg />}
                        </Grid>
                    </Grid>
                </Card>
            </Box>
        </Fragment>
    );
};

export default PesertaPemilu;
