import { API, setAuthToken } from 'config/API.js'
import { GET_ADMIN_MANAGEMENT, GET_DETAIL_ADMIN_MANAGEMENT, GET_ADMIN_MANAGEMENT_LIST } from '../constant.js'

export const getDataAdminManagement = (params = '') => {
  return dispatch => {
    dispatch({
      type: GET_ADMIN_MANAGEMENT,
      payload: {
        data: [],
        pagination: {
          current_page: 1,
          last_page: 1,
          per_page: 10,
          total: 1
        }
      }
    })

    const token = localStorage.getItem('jwt_token')
    setAuthToken(token)
    return API.get(`/admin/adminmanagement?${new URLSearchParams(params)}`)
      .then(res => {
        dispatch({
          type: GET_ADMIN_MANAGEMENT,
          payload: res.data.data || []
        })
      })
      .catch(() => {
        dispatch({
          type: GET_ADMIN_MANAGEMENT,
          payload: []
        })
      })
  }
}

export const getDataAdminManagementList = () => {
  return dispatch => {
    const token = localStorage.getItem('jwt_token')
    setAuthToken(token)
    API.get(`/admin/adminmanagement/list`)
      .then(res => {
        dispatch({
          type: GET_ADMIN_MANAGEMENT_LIST,
          payload: res.data || {}
        })
      })
      .catch(() => {
        dispatch({
          type: GET_ADMIN_MANAGEMENT_LIST,
          payload: {}
        })
      })
  }
}

export const getDetailAdminManagement = (users_code = '') => {
  return dispatch => {
    const token = localStorage.getItem('jwt_token')
    setAuthToken(token)
    API.get(`/admin/adminmanagement/` + users_code)
      .then(res => {
        dispatch({
          type: GET_DETAIL_ADMIN_MANAGEMENT,
          payload: res.data || {}
        })
      })
      .catch(() => {
        dispatch({
          type: GET_DETAIL_ADMIN_MANAGEMENT,
          payload: {}
        })
      })
  }
}

export const addAdminManagement = body => {
  const token = localStorage.getItem('jwt_token')
  setAuthToken(token)
  return API.post('/admin/adminmanagement/add', body)
}

export const updateAdminManagement = body => {
  const token = localStorage.getItem('jwt_token')
  setAuthToken(token)
  return API.post('/admin/adminmanagement/update', body)
}

export const deleteAdminManagement = body => {
  const token = localStorage.getItem('jwt_token')
  setAuthToken(token)
  return API.post('/admin/adminmanagement/delete', body)
}
