import React, { useState } from "react";
import { Box } from "@mui/material";
import { Card, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { updateDpt } from "app/redux/actions/DptActions";
import HeadTitle from "../components/HeadTitle";

const EditDPT = (props) => {
  const location = useLocation();
  const itemData = location.state ? location.state.itemData : null;
  const history = useHistory();

  const [state, setState] = useState({
    daftar_pemilih_tetap_code: itemData.daftar_pemilih_tetap_code,
    daftar_pemilih_tetap_nama: itemData.daftar_pemilih_tetap_nama,
    daftar_pemilih_tetap_total: itemData.daftar_pemilih_tetap_total,
  });
  const [errorField, setErrorField] = useState({});

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = () => {
    updateDpt(state)
      .then((res) => {
        console.log(res);
        Swal.fire({
          title: '<div class="custom-swal-title">Data berhasil disimpan</div>',
          buttonsStyling: false,
          icon: "success",
          customClass: {
            confirmButton: "custom-success-button",
          },
        });
        history.push("/master_data/data_lainnya");
      })
      .catch((err) => {
        console.log(err.response);
        Swal.fire({
          title: '<div class="custom-swal-title">Data gagal disimpan!</div>',
          buttonsStyling: false,
          icon: "error",
          customClass: {
            confirmButton: "custom-error-button",
          },
        });
        setErrorField(err.response.data.data);
      });
  };

  return (
    <>
      <Box className="p-40">
        <HeadTitle
          title="Edit Data Daftar Pemilih Tetap (DPT)"
          onClick={handleSubmit}
          backPath="/master_data/data_lainnya"
          btnText="Edit Data"
        />

        <Card className="w-100 overflow-auto p-24 mb-3">
          <Grid container className="mt-2 p-2" spacing={2}>
          <Grid item lg={12} md={12} xs={12} sm={12}>
            <h3 className="fw-600 fs-24">Detail Daftar Pemilih Tetap (DPT)</h3>
          </Grid>
          <Grid item lg={6} md={6} xs={12} sm={12}>
              <InputLabel htmlFor="daftar_pemilih_tetap_nama">
                <h5 className="font-size-14">Kategori Daftar Pemilih Tetap (DPT)</h5>
              </InputLabel>
              <Select
                SelectDisplayProps={{
                  style: {
                    paddingTop: 10,
                    paddingBottom: 10,
                    borderRadius: 5,
                  },
                }}
                size="small"
                labelid="daftar_pemilih_tetap_nama"
                value={state.daftar_pemilih_tetap_nama}
                onChange={handleChange}
                variant="outlined"
                className="w-100"
                name="daftar_pemilih_tetap_nama"
                displayEmpty
                readOnly
              >
                <MenuItem key={"x"} value={""} disabled>
                  Pilih Kategori
                </MenuItem>
                <MenuItem value="DPT Dapil" selected={state.daftar_pemilih_tetap_nama === "DPT Dapil"}>
                  Daftar Pemilih Tetap (DPT) Dapil
                </MenuItem>
                <MenuItem value="DPT Pria" selected={state.daftar_pemilih_tetap_nama === "DPT Pria"}>
                  Daftar Pemilih Tetap (DPT) Pria
                </MenuItem>
                <MenuItem value="DPT Wanita" selected={state.daftar_pemilih_tetap_nama === "DPT Wanita"}>
                  Daftar Pemilih Tetap (DPT) Wanita
                </MenuItem>
              </Select>
            </Grid>
            <Grid item lg={6} md={6} xs={12} sm={12}>
              <InputLabel htmlFor="daftar_pemilih_tetap_total">
                <h5 className="font-size-14">Jumlah Daftar Pemilih Tetap (DPT)</h5>
              </InputLabel>
              <TextField
                required={true}
                size="small"
                value={state.daftar_pemilih_tetap_total}
                name="daftar_pemilih_tetap_total"
                className={`w-100`}
                InputProps={{
                  style: {
                    borderRadius: 5,
                  },
                }}
                placeholder="Jumlah Daftar Pemilih Tetap (DPT)"
                variant="outlined"
                type="number"
                error={errorField?.daftar_pemilih_tetap_total !== ""}
                helperText={errorField?.daftar_pemilih_tetap_total}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Card>
      </Box>
    </>
  );
};

export default EditDPT;
