import { API, setAuthToken } from "config/API.js";
import {
  GET_KANDIDAT_LAIN,
  GET_PENGGUNA,
  GET_PARTAI,
  GET_KANDIDAT_MAIN,
  GET_DAPIL,
  GET_CALON_TETAP_SEBAGAI,
  GET_DETAIL_KANDIDAT,
  GET_DETAIL_PENGGUNA,
  GET_DETAIL_PARTAI,
  GET_DETAIL_CALON_TETAP,
} from "../constant.js";

export const getDataPengguna = (params = "") => {
  return (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.get(`/user/setting/pengguna?${new URLSearchParams(params)}`)
      .then((res) => {
        dispatch({
          type: GET_PENGGUNA,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_PENGGUNA,
          payload: [],
        });
      });
  };
};

export const getDetailPengguna = (code) => {
  return (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.get(`/user/setting/pengguna/${code}`)
      .then((res) => {
        dispatch({
          type: GET_DETAIL_PENGGUNA,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_DETAIL_PENGGUNA,
          payload: [],
        });
      });
  };
};

export const addPengguna = (body) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.post("/user/setting/pengguna/tambah", body);
};

export const updatePengguna = (body) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.post("/user/setting/pengguna/update", body);
};

export const deletePengguna = (code) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.delete(`/user/setting/pengguna/delete/${code}`);
};

export const getDataKandidatLain = (params = "") => {
  return (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.get(`/user/setting/kandidat?${new URLSearchParams(params)}`)
      .then((res) => {
        dispatch({
          type: GET_KANDIDAT_LAIN,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_KANDIDAT_LAIN,
          payload: [],
        });
      });
  };
};

export const getKandidatMain = (params = "") => {
  return (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.get(`/user/setting/kandidat/main` + params)
      .then((res) => {
        dispatch({
          type: GET_KANDIDAT_MAIN,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_KANDIDAT_MAIN,
          payload: [],
        });
      });
  };
};

export const getDetailPartai = (params = "") => {
  return (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.get(`/user/setting/partai/` + params)
      .then((res) => {
        dispatch({
          type: GET_DETAIL_PARTAI,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_DETAIL_PARTAI,
          payload: [],
        });
      });
  };
};

export const getDataPartai = (params = "") => {
  return (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.get(`/user/setting/partai` + params)
      .then((res) => {
        dispatch({
          type: GET_PARTAI,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_PARTAI,
          payload: [],
        });
      });
  };
};

export const addPartai = (body) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.post("/user/setting/partai/tambah", body);
};

export const updatePartai = (body) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.post("/user/setting/partai/update", body);
};

export const deletePartai = (body) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.post("/user/setting/partai/delete", body);
};

export const getDataDapil = (params = "") => {
  return (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.get(`/user/setting/dapil` + params)
      .then((res) => {
        dispatch({
          type: GET_DAPIL,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_DAPIL,
          payload: [],
        });
      });
  };
};

export const deleteDapil = (body) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.post("/user/setting/dapil/delete", body);
};

export const addDapil = (body) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.post("/user/setting/dapil/tambah", body);
};

export const updateDapil = (body) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.post("/user/setting/dapil/update", body);
};

export const getDetailCalonTetap = (params = "") => {
  return (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.get(`/user/setting/calontetap/` + params)
      .then((res) => {
        console.log(res.data);
        dispatch({
          type: GET_DETAIL_CALON_TETAP,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_DETAIL_CALON_TETAP,
          payload: [],
        });
      });
  };
};

export const getDataCalonTetapSebagai = (params = "") => {
  return (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.get(`/user/setting/calontetap` + params)
      .then((res) => {
        dispatch({
          type: GET_CALON_TETAP_SEBAGAI,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_CALON_TETAP_SEBAGAI,
          payload: [],
        });
      });
  };
};

export const addCalonTetapSebagai = (body) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.post("/user/setting/calontetap/tambah", body);
};

export const updateCalonTetapSebagai = (body) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.post("/user/setting/calontetap/update", body);
};

export const deleteCalonTetapSebagai = (body) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.post("/user/setting/calontetap/delete", body);
};

export const getDetailKandidat = (code) => {
  return (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.get(`/user/setting/kandidat/${code}`)
      .then((res) => {
        dispatch({
          type: GET_DETAIL_KANDIDAT,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_DETAIL_KANDIDAT,
          payload: [],
        });
      });
  };
};

export const addKandidat = (body) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.post("/user/setting/kandidat/tambah", body);
};

export const updateKandidat = (body) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.post("/user/setting/kandidat/update", body);
};

export const deleteKandidat = (body) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.post("/user/setting/kandidat/delete", body);
};

export const getDataDapilPublic = (params = "") => {
  return (dispatch) => {
    // const token = localStorage.getItem("jwt_token");
    // setAuthToken(token);
    API.get(`public/dapil` + params)
      .then((res) => {
        dispatch({
          type: GET_DAPIL,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_DAPIL,
          payload: [],
        });
      });
  };
};

export const getDataPartaiPublic = (params = "") => {
  return (dispatch) => {
    // const token = localStorage.getItem("jwt_token");
    // setAuthToken(token);
    API.get(`/public/partai` + params)
      .then((res) => {
        dispatch({
          type: GET_PARTAI,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_PARTAI,
          payload: [],
        });
      });
  };
};

export const getDataCalonTetapSebagaiPublic = (params = "") => {
  return (dispatch) => {
    // const token = localStorage.getItem("jwt_token");
    // setAuthToken(token);
    API.get(`/public/calontetap` + params)
      .then((res) => {
        dispatch({
          type: GET_CALON_TETAP_SEBAGAI,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_CALON_TETAP_SEBAGAI,
          payload: [],
        });
      });
  };
};

export const saveContactPaymentConfirmation = (body) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.post("/admin/setting/contact_confirmation/save", body);
}

export const saveNominalSubscription = (body) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.post("/admin/setting/nominal_subscription/save", body);
}