import {
    GET_ADMIN_DASHBOARD_CALEG,
    GET_ADMIN_DASHBOARD_CALEG_TERBANYAK,
    GET_ADMIN_DASHBOARD_CALON_PEMIMPIN,
    GET_ADMIN_DASHBOARD_SEBARAN_CALEG,
    GET_ADMIN_DASHBOARD_USERS,
} from "../constant.js";

const initialState = {
    adminDashboardUsers: {},
    adminDashboardCaleg: {},
    adminDashboardCalegTerbanyak: {},
    adminDashboardCalonPemimpin: {},
    adminDashboardSebaranCaleg: [],
};

const AdminDashboardReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_ADMIN_DASHBOARD_USERS: {
            return {
                ...state,
                adminDashboardUsers: action.payload,
            };
        }
        case GET_ADMIN_DASHBOARD_CALEG: {
            return {
                ...state,
                adminDashboardCaleg: action.payload,
            };
        }
        case GET_ADMIN_DASHBOARD_CALEG_TERBANYAK: {
            return {
                ...state,
                adminDashboardCalegTerbanyak: action.payload,
            };
        }
        case GET_ADMIN_DASHBOARD_CALON_PEMIMPIN: {
            return {
                ...state,
                adminDashboardCalonPemimpin: action.payload,
            };
        }
        case GET_ADMIN_DASHBOARD_SEBARAN_CALEG: {
            return {
                ...state,
                adminDashboardSebaranCaleg: action.payload,
            };
        }
        default: {
            return state;
        }
    }
};

export default AdminDashboardReducer;
