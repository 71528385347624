import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllProvince } from 'app/redux/actions/ProvinceActions';
import MenuActions from '../../components/MenuActions'
import CustomLayout from '../../components/CustomLayout'

const TableProvinsi = () => {
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();

  const { dataAllProvince } = useSelector(
    (state) => state.province
  )

  const getData = () => {
    dispatch(getAllProvince());
  };

  useEffect(() => {
    getData();
  }, [dispatch]);

  const provinceColumns = [
    { header: 'No', field: 'id' },
    { header: 'Nama Provinsi', field: 'name' },
    {
      header: 'Aksi',
      render: rowData => (
        <MenuActions
          editPath='/master_data/data_lainnya/data_provinsi/edit'
          data={rowData}
          item={rowData}
          state={rowData}
        />
      ),
      field: 'aksi'
    }
  ]

  const searchedDataAllProvince = dataAllProvince?.data?.data?.data.filter(({ name }) => name.toLowerCase().includes(search.toLowerCase()));

  return (
    <CustomLayout
      title='Data Daerah'
      subtitle='Provinsi'
      columns={provinceColumns}
      data={searchedDataAllProvince}
      button='Tambah Provinsi'
      addPath='/master_data/data_lainnya/data_provinsi/tambah'
      search={search}
      setSearch={setSearch}
    />
  )
}

export default TableProvinsi
