import { authRoles } from "app/auth/authRoles";
import PesertaPemilu from "./peserta_pemilu/PesertaPemilu";
import DetailTPS from "./peserta_pemilu/caleg/DetailTPS";
import RangkumanPartai from "./peserta_pemilu/caleg/RangkumanPartai";
import RekapSuara from "./peserta_pemilu/caleg/RekapSuara";
import SuaraCaleg from "./peserta_pemilu/caleg/suara/SuaraCaleg";
import SuaraPartai from "./peserta_pemilu/caleg/suara/SuaraPartai";
import AddPartai from "./peserta_pemilu/partai/AddPartai";
import DetailPartai from "./peserta_pemilu/partai/DetailPartai";
import EditPartai from "./peserta_pemilu/partai/EditPartai";

import DataLainnya from "./data_lainnya/DataLainnya";
import AddPartaiSekarang from "./data_lainnya/partai_pemilu_sekarang/AddPartaiSekarang";
import EditPartaiSekarang from "./data_lainnya/partai_pemilu_sekarang/EditPartaiSekarang";
import AddPartaiTerakhir from "./data_lainnya/partai_pemilu_terakhir/AddPartaiTerakhir";
import EditPartaiTerakhir from "./data_lainnya/partai_pemilu_terakhir/EditPartaiTerakhir";

import AddLogistik from "./data_lainnya/logistik/AddLogistik";
import EditLogistik from "./data_lainnya/logistik/EditLogistik";
import AddLogistikUnit from "./data_lainnya/logistik/AddLogistikUnit";
import EditLogistikUnit from "./data_lainnya/logistik/EditLogistikUnit";

import AddArusKas from "./data_lainnya/arus_kas/AddArusKas";
import EditArusKas from "./data_lainnya/arus_kas/EditArusKas";

import AddDPT from "./data_lainnya/daftar_pemilih_tetap/AddDPT";
import EditDPT from "./data_lainnya/daftar_pemilih_tetap/EditDPT";

import AddCalonTetap from "./data_lainnya/kandidat/AddCalonTetap";
import EditCalonTetap from "./data_lainnya/kandidat/EditCalonTetap";

import AddDapil from "./data_lainnya/kandidat/AddDapil";
import EditDapil from "./data_lainnya/kandidat/EditDapil";

import AddQuickCount from "./data_lainnya/quick_count/AddQuickCount";
import EditQuickCount from "./data_lainnya/quick_count/EditQuickCount";

import AddProvinsi from "./data_lainnya/data_daerah/provinsi/AddProvinsi";
import EditProvinsi from "./data_lainnya/data_daerah/provinsi/EditProvinsi";

import AddKotaKab from "./data_lainnya/data_daerah/kota_kabupaten/AddKotaKab";
import EditKotaKab from "./data_lainnya/data_daerah/kota_kabupaten/EditKotaKab";

import AddKecamatan from "./data_lainnya/data_daerah/kecamatan/AddKecamatan";
import EditKecamatan from "./data_lainnya/data_daerah/kecamatan/EditKecamatan";

import AddKelurahan from "./data_lainnya/data_daerah/kelurahan/AddKelurahan";
import EditKelurahan from "./data_lainnya/data_daerah/kelurahan/EditKelurahan";


import Setting from './setting/Setting';
import AddBankPembayaran from './setting/AddBankPembayaran'
import DetailBankPembayaran from './setting/DetailBankPembayaran'
import EditBankPembayaran from './setting/EditBankPembayaran'

const MasterDataRoutes = [
  // Peserta Pemilu Routes
  {
    path: "/master_data/peserta_pemilu_terakhir",
    exact: true,
    component: PesertaPemilu,
    auth: authRoles.superadm,
  },
  // Partai Route
  {
    path: "/master_data/peserta_pemilu_terakhir/partai/tambah",
    exact: true,
    component: AddPartai,
    auth: authRoles.superadm,
  },
  {
    path: "/master_data/peserta_pemilu_terakhir/partai/detail/:partai_code",
    exact: true,
    component: DetailPartai,
    auth: authRoles.superadm,
  },
  {
    path: "/master_data/peserta_pemilu_terakhir/partai/edit/:partai_code",
    exact: true,
    component: EditPartai,
    auth: authRoles.superadm,
  },
  // Caleg Route
  {
    path: "/master_data/pemilu_terakhir/detail_tps/:id",
    exact: true,
    component: DetailTPS,
    auth: authRoles.superadm,
  },
  {
    path: "/master_data/pemilu_terakhir/rekap_suara/:id",
    exact: true,
    component: RekapSuara,
    auth: authRoles.superadm,
  },
  {
    path: "/master_data/pemilu_terakhir/rangkuman_partai/:id_partai/:id_dapil",
    exact: true,
    component: RangkumanPartai,
    auth: authRoles.superadm,
  },
  {
    path: "/master_data/pemilu_terakhir/suara_caleg/:id_dapil",
    exact: true,
    component: SuaraCaleg,
    auth: authRoles.superadm,
  },
  {
    path: "/master_data/pemilu_terakhir/suara_partai/:id_dapil",
    exact: true,
    component: SuaraPartai,
    auth: authRoles.superadm,
  },
  // Data Lainnya Routes
  {
    path: "/master_data/data_lainnya",
    exact: true,
    component: DataLainnya,
    auth: authRoles.superadm,
  },
  {
    path: "/master_data/data_lainnya/partai_sekarang/tambah",
    exact: true,
    component: AddPartaiSekarang,
    auth: authRoles.superadm,
  },
  {
    path: "/master_data/data_lainnya/partai_sekarang/edit/:partai_code",
    exact: true,
    component: EditPartaiSekarang,
    auth: authRoles.superadm,
  },
  {
    path: "/master_data/data_lainnya/partai_terakhir/tambah",
    exact: true,
    component: AddPartaiTerakhir,
    auth: authRoles.superadm,
  },
  {
    path: "/master_data/data_lainnya/partai_terakhir/edit/:partai_code",
    exact: true,
    component: EditPartaiTerakhir,
    auth: authRoles.superadm,
  },
  // Logistik route
  {
    path: "/master_data/data_lainnya/logistik/tambah",
    exact: true,
    component: AddLogistik,
    auth: authRoles.superadm,
  },
  {
    path: "/master_data/data_lainnya/logistik/edit/:kategori_logistik_code",
    exact: true,
    component: EditLogistik,
    auth: authRoles.superadm,
  },
  {
    path: "/master_data/data_lainnya/logistik_satuan/tambah",
    exact: true,
    component: AddLogistikUnit,
    auth: authRoles.superadm,
  },
  {
    path: "/master_data/data_lainnya/logistik_satuan/edit/:kategori_unit_code",
    exact: true,
    component: EditLogistikUnit,
    auth: authRoles.superadm,
  },
  // Arus Kas Routes
  {
    path: "/master_data/data_lainnya/arus_kas/tambah",
    exact: true,
    component: AddArusKas,
    auth: authRoles.superadm,
  },
  {
    path: "/master_data/data_lainnya/arus_kas/edit/:kategori_arus_kas_code",
    exact: true,
    component: EditArusKas,
    auth: authRoles.superadm,
  },
  // DPT Routes
  {
    path: "/master_data/data_lainnya/dpt/tambah",
    exact: true,
    component: AddDPT,
    auth: authRoles.superadm,
  },
  {
    path: "/master_data/data_lainnya/dpt/edit/:daftar_pemilih_tetap_code",
    exact: true,
    component: EditDPT,
    auth: authRoles.superadm,
  },
  // Calon Tetap Routes
  {
    path: "/master_data/data_lainnya/calon_tetap/tambah",
    exact: true,
    component: AddCalonTetap,
    auth: authRoles.superadm,
  },
  {
    path: "/master_data/data_lainnya/calon_tetap/edit/:tingkat_calon_tetap_code",
    exact: true,
    component: EditCalonTetap,
    auth: authRoles.superadm,
  },
  // Dapil Routes
  {
    path: "/master_data/data_lainnya/dapil/tambah",
    exact: true,
    component: AddDapil,
    auth: authRoles.superadm,
  },
  {
    path: "/master_data/data_lainnya/dapil/edit/:dapil_code",
    exact: true,
    component: EditDapil,
    auth: authRoles.superadm,
  },
  // Hitung Cepat Routes
  {
    path: "/master_data/data_lainnya/hitung_cepat/tambah",
    exact: true,
    component: AddQuickCount,
    auth: authRoles.superadm,
  },
  {
    path: "/master_data/data_lainnya/hitung_cepat/edit/:hitung_cepat_code",
    exact: true,
    component: EditQuickCount,
    auth: authRoles.superadm,
  },
  // Data Daerah Routes
  {
    path: "/master_data/data_lainnya/data_provinsi/tambah",
    exact: true,
    component: AddProvinsi,
    auth: authRoles.superadm
  },
  {
    path: "/master_data/data_lainnya/data_provinsi/edit",
    exact: true,
    component: EditProvinsi,
    auth: authRoles.superadm
  },
  {
    path: "/master_data/data_lainnya/data_kota_kabupaten/tambah",
    exact: true,
    component: AddKotaKab,
    auth: authRoles.superadm
  },
  {
    path: "/master_data/data_lainnya/data_kota_kabupaten/edit",
    exact: true,
    component: EditKotaKab,
    auth: authRoles.superadm
  },
  {
    path: "/master_data/data_lainnya/data_kecamatan/tambah",
    exact: true,
    component: AddKecamatan,
    auth: authRoles.superadm
  },
  {
    path: "/master_data/data_lainnya/data_kecamatan/edit",
    exact: true,
    component: EditKecamatan,
    auth: authRoles.superadm
  },
  {
    path: "/master_data/data_lainnya/data_kelurahan/tambah",
    exact: true,
    component: AddKelurahan,
    auth: authRoles.superadm
  },
  {
    path: "/master_data/data_lainnya/data_kelurahan/edit",
    exact: true,
    component: EditKelurahan,
    auth: authRoles.superadm
  },
  // Pembayaran Routes
  {
    path: '/master_data/setting',
    exact: true,
    component: Setting,
    auth: authRoles.superadm
  },
  {
    path: '/master_data/setting/pembayaran/tambah',
    exact: true,
    component: AddBankPembayaran,
    auth: authRoles.superadm
  },
  {
    path: '/master_data/setting/pembayaran/detail/:bank_code',
    exact: true,
    component: DetailBankPembayaran,
    auth: authRoles.superadm
  },
  {
    path: '/master_data/setting/pembayaran/edit/:bank_code',
    exact: true,
    component: EditBankPembayaran,
    auth: authRoles.superadm
  },
];

export default MasterDataRoutes;
