import React, { useState } from "react";
import { Box } from "@mui/material";
import { Card, Grid, InputLabel, TextField } from "@material-ui/core";
import InputFileImg from "app/components/Input/InputFileImg";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { addPartai } from "app/redux/actions/PengaturanActions";
import GreenButton from "app/components/GreenButton";

const DetailPartai = () => {
  const history = useHistory();

  const [state, setState] = useState({
    partai_images: "",
    partai_images_preview: "",
    partai_nama: "",
    partai_caleg: "",
    partai_nomor: "",
    partai_type: "terakhir",
  });
  const [errorField, setErrorField] = useState({});

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangeFoto = (file, path) => {
    setState((prev) => ({
      ...prev,
      partai_images: file,
      partai_images_preview: path,
    }));
  };

  const handleSubmit = () => {
    addPartai(state)
      .then((res) => {
        console.log(res);
        Swal.fire({
          title: '<div class="custom-swal-title">Data berhasil disimpan</div>',
          buttonsStyling: false,
          icon: "success",
          customClass: {
            confirmButton: "custom-success-button",
          },
        });
        history.push("/master_data/peserta_pemilu_terakhir?tab=partai");
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: '<div class="custom-swal-title">Data gagal disimpan!</div>',
          buttonsStyling: false,
          icon: "error",
          customClass: {
            confirmButton: "custom-error-button",
          },
        });
        setErrorField(err.response.data.data);
      });
  };

  return (
    <>
      <Box className="p-40">
        <div className="mb-sm-30 flex justify-content-between align-items-center">
          <Grid
            container
            spacing={3}
            className="mb-2 d-flex justify-content-center align-items-center"
          >
            <Grid item lg={6} md={6} xs={12} sm={6}>
              <h2 className="font-size-32 fw-700 fw-bold">Detail Partai</h2>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              xs={12}
              sm={6}
              spacing={2}
              container
              justify="flex-end"
            >
              <Grid item>
                <GreenButton
                  text="Kembali"
                  onClick={() => history.push("/master_data/peserta_pemilu_terakhir?tab=partai")}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>
        </div>

        <Card className="w-100 overflow-auto p-24 mb-3">
          <h3 className="fw-bold font-size-40">Detail Partai</h3>

          <Grid container className="mt-2 p-2" spacing={2}>
            <Grid item lg={6} md={6} xs={12} sm={12}>
              <InputLabel htmlFor="image">
                <h5 className="font-size-14">Logo Partai</h5>
                <InputFileImg
                  noPadding={true}
                  name="partai_images"
                  uploadFoto={handleChangeFoto}
                  preview={state.partai_images_preview}
                  acceptFile="image"
                />
              </InputLabel>
              <small className="text-danger">{errorField?.partai_images}</small>
            </Grid>
            <Grid item lg={6} md={6} xs={12} sm={12}></Grid>

            <Grid item lg={6} md={6} xs={12} sm={12}>
              <InputLabel htmlFor="partai_nama">
                <h5 className="font-size-14">Nama Partai</h5>
              </InputLabel>
              <TextField
                required={true}
                value={state.partai_nama}
                size="small"
                name="partai_nama"
                className={`w-100`}
                InputProps={{
                  style: {
                    borderRadius: 5,
                  },
                }}
                placeholder="Nama Partai"
                variant="outlined"
                type="text"
                error={errorField?.partai_nama !== ""}
                helperText={errorField?.partai_nama}
                onChange={handleChange}
              />
            </Grid>

            {/* <Grid item lg={6} md={6} xs={12} sm={12}>
              <InputLabel htmlFor="partai_caleg">
                <h5 className="font-size-14">Jumlah Caleg</h5>
              </InputLabel>
              <TextField
                required={true}
                size="small"
                value={state.partai_caleg}
                name="partai_caleg"
                className={`w-100`}
                InputProps={{
                  style: {
                    borderRadius: 5,
                  },
                }}
                placeholder="Jumlah Caleg"
                variant="outlined"
                type="number"
                error={errorField?.partai_caleg !== ""}
                helperText={errorField?.partai_caleg}
                onChange={handleChange}
              />
            </Grid> */}

            <Grid item lg={6} md={6} xs={12} sm={12}>
              <InputLabel htmlFor="partai_nomor">
                <h5 className="font-size-14">Nomor Partai</h5>
              </InputLabel>
              <TextField
                required={true}
                size="small"
                value={state.partai_nomor}
                name="partai_nomor"
                className={`w-100`}
                InputProps={{
                  style: {
                    borderRadius: 5,
                  },
                }}
                placeholder="Nomor Partai"
                variant="outlined"
                type="number"
                error={errorField?.partai_nomor !== ""}
                helperText={errorField?.partai_nomor}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Card>
      </Box>
    </>
  );
};

export default DetailPartai;
