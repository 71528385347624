import { API, setAuthToken } from "config/API.js";
import { GET_ALL_DISTRICT, GET_DISTRICT, GET_DISTRICT_NAME } from "../constant.js";

export const getAllDistrict = (params = "") => {
    return async (dispatch) => {
      try {
        const token = localStorage.getItem("jwt_token");
        setAuthToken(token);
        const res = await API.post(`/district?${new URLSearchParams(params)}`);
        dispatch({
          type: GET_ALL_DISTRICT,
          payload: res.data || [],
        });
      } catch (error) {
        dispatch({
          type: GET_ALL_DISTRICT,
          payload: [],
        });
        throw error;
      }
    };
};

export const getDistrict = (body) => {
    return (dispatch) => {
        const token = localStorage.getItem("jwt_token");
        setAuthToken(token);
        API.post(`/district/list`, body)
            .then((res) => {
                dispatch({
                    type: GET_DISTRICT,
                    payload: res.data.data || [],
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_DISTRICT,
                    payload: [],
                });
            });
    };
};

export const getDistrictName = (body) => {
    return (dispatch) => {
        const token = localStorage.getItem("jwt_token");
        setAuthToken(token);
        API.post(`/district-name`, body)
            .then((res) => {
                dispatch({
                    type: GET_DISTRICT_NAME,
                    payload: res.data.data || [],
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_DISTRICT_NAME,
                    payload: [],
                });
            });
    };
};

export const addDistrict = (body) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.post("/admin/district/tambah", body);
}

export const updateDistrict = (body) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.post("/admin/district/update", body);
}
