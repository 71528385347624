import {
    GET_ALL_VILLAGES,
    GET_VILLAGES,
    GET_VILLAGES_NAME
} from "../constant.js";

const initialState = {
    dataVillages: [],
    dataVillagesName: {},
    dataAllVillages: [],
};

const VillagesReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_VILLAGES: {
            return {
                ...state,
                dataVillages: action.payload,
            };
        }
        case GET_VILLAGES_NAME: {
            return {
                ...state,
                dataVillagesName: action.payload,
            };
        }
        case GET_ALL_VILLAGES: {
            return {
                ...state,
                dataAllVillages: action.payload,
            };
        }
        default: {
            return state;
        }
    }
};

export default VillagesReducer;
