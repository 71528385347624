/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { Grid, Card, Paper } from "@mui/material";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { UsersThree, Users } from "@phosphor-icons/react";
import {
  getAdminDashboardCaleg,
  getAdminDashboardCalegTerbanyak,
  getAdminDashboardCalonPemimpin,
  getAdminDashboardUsers,
} from "app/redux/actions/AdminDashboardActions";
import GeneralButton from "app/components/GeneralButton.jsx";
import CardGrafikDoughnut from "./components/CardGrafikDoughnut.jsx";
import SebaranPengguna from "./components/SebaranPengguna.jsx";

const DashboardAdmin = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(({ user }) => user);

  const {
    adminDashboardCaleg,
    adminDashboardCalegTerbanyak,
    adminDashboardCalonPemimpin,
    adminDashboardUsers
  } = useSelector((state) => state.adminDashboard);

  const getData = () => {
    try {
      dispatch(getAdminDashboardCaleg());
      dispatch(getAdminDashboardCalegTerbanyak());
      dispatch(getAdminDashboardCalonPemimpin());
      dispatch(getAdminDashboardUsers());
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const dataCalon = [];
  for (const key in adminDashboardCalonPemimpin) {
    dataCalon.push({
      name: `Calon ${key}`,
      value: adminDashboardCalonPemimpin[key]
    });
  };
  const dataCaleg = [];
  for (const key in adminDashboardCaleg) {
    dataCaleg.push({
      name: `Caleg ${key}`,
      value: adminDashboardCaleg[key]
    });
  };
  const dataUsers = [];
  for (const key in adminDashboardUsers) {
    dataUsers.push({
      name: `User ${key}`,
      value: adminDashboardUsers[key]
    });
  };
  const dataCalegTerbanyak = [];
  for (const key in adminDashboardCalegTerbanyak) {
    dataCalegTerbanyak.push({
      name: key,
      value: adminDashboardCalegTerbanyak[key]
    });
  };

  return (
    <Fragment>
      <div className="p-40">
        <h1 className="fs-2 fw-700 mb-sm-30">Dashboard PemiluNow: Siap Menang Pemilu 2024</h1>
        <h4 className="font-size-32 mb-4 text-grey" style={{color: "#616161"}}>Selamat Datang Kembali {user?.name}!</h4>

        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12} sm={12}>
            <Card className="p-3">
              <h4 className="font-size-32 fw-700 fw-bold">Data Jumlah Pengguna, Caleg, dan Calon Pemimpin</h4>
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6} sm={12}>
                <CardGrafikDoughnut
                  height="250px"
                  color={["#B7EBFF", "#BBFF92", "#8CE059", "#2EC5FF",]}
                  title="Jumlah Calon Pemimpin"
                  data={dataCalon}
                  cut={2}
                />
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12}>
                <CardGrafikDoughnut
                  height="250px"
                  color={["#B7EBFF", "#BBFF92", "#8CE059", "#2EC5FF",]}
                  title="Jumlah Caleg"
                  data={dataCaleg}
                  cut={2}
                />
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12}>
                <CardGrafikDoughnut
                  height="250px"
                  color={["#B7EBFF", "#BBFF92", "#8CE059", "#8ADEFF" , "#2EC5FF",]}
                  title="Jumlah Pengguna"
                  data={dataUsers}
                  cut={3}
                />
          </Grid>
          {/* <Grid item xs={12} md={6} lg={6} sm={12}>
                <CardGrafikDoughnut
                  height="250px"
                  color={["#B7EBFF", "#BBFF92", "#8CE059", "#8ADEFF" , "#2EC5FF",]}
                  title="Suara Caleg Terbanyak"
                  data={dataCalegTerbanyak.slice(0, 5)}
                  cut={3}
                />
                <GeneralButton
                  text="Lihat Data"
                  otherClass="w-full"
                  onClick={() => history.push({
                    pathname: "/master_data/peserta_pemilu_terakhir",
                  })}
                />
          </Grid> */}
          <Grid item xs={12} md={12} lg={12} sm={12}>
            <SebaranPengguna />
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
};

export default DashboardAdmin;