import { forwardRef, useState } from "react";
import { Button, Menu, MenuItem } from "@material-ui/core";
import { Link } from "react-router-dom";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import React from "react";
import { Eye, PencilSimpleLine, Trash } from "@phosphor-icons/react";

const MenuItemLink = forwardRef(({ to, children, onClick, style }, ref) => (
  <Link to={to}>
    <MenuItem ref={ref} onClick={onClick} style={style}>
      {children}
    </MenuItem>
  </Link>
));

const MenuItemActions = forwardRef(({ children, onClick, style }, ref) => (
  <MenuItem onClick={onClick} style={style} ref={ref}>
    {children}
  </MenuItem>
));

const menuItemsStyle = {
  width: "163px",
  height: "37px",
  padding: "8px 12px 8px 12px",
  gap: "10px",
};

export default function MenuActions({ editPath, deleteActions, detailPath }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    deleteActions();
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className="elevation-z0"
      >
        <MoreHorizIcon />
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {detailPath && (
          <MenuItemLink
            to={detailPath}
            onClick={handleClose}
            style={menuItemsStyle}
          >
            <Eye size={24} />
            <span className="pl-3">Lihat Detail</span>
          </MenuItemLink>
        )}

        {editPath && (
          <MenuItemLink
            to={editPath}
            onClick={handleClose}
            style={menuItemsStyle}
          >
            <PencilSimpleLine size={24} color="#71BE42" />
            <span className="pl-3" style={{ color: "#71BE42" }}>
              Edit Data
            </span>
          </MenuItemLink>
        )}

        {deleteActions && (
          <MenuItemActions onClick={handleDelete} style={menuItemsStyle}>
            <Trash size={24} color="#E34242" />
            <span className="pl-3" style={{ color: "#E34242" }}>
              Hapus Data
            </span>
          </MenuItemActions>
        )}
      </Menu>
    </div>
  );
}
