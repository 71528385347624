import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import React, { useEffect } from 'react'
import GeneralButton from 'app/components/GeneralButton'
import { Link } from 'react-router-dom'
import { indexOf } from 'lodash'

const TableProvince = ({ data, search, state, queryParams }) => {
  useEffect(() => {
    //
  }, [])

  const getTableRowNumber = index => {
    return state.page * queryParams.limit + index + 1
  }

  return (
    <Table style={{ minWidth: 900 }}>
      <TableHead className='t-header'>
        <TableRow>
          <TableCell align='center' className='table-cell-65px'>
            No
          </TableCell>
          <TableCell align='left' className='table-cell-170px'>
            Nama Dapil
          </TableCell>
          <TableCell align='center' className='table-cell-170px'>
            Tingkat
          </TableCell>
          <TableCell align='center' className='table-cell-170px'>
            Tahun
          </TableCell>
          <TableCell align='center' className='table-cell-170px'>
            Suara Caleg
          </TableCell>
          <TableCell align='center' className='table-cell-170px'>
            Suara Partai
          </TableCell>
          <TableCell align='center' className='table-cell-170px'>
            Aksi
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody className='t-body'>
        {state.loading ? (
          <TableRow>
            <TableCell colSpan={7} align='center'>
              Loading...
            </TableCell>
          </TableRow>
        ) : data.filter(item =>
          item?.dapil_nama?.toLowerCase().includes(search.toLowerCase())
        ).length > 0 ? (
          data
            .filter(item =>
              item?.dapil_nama?.toLowerCase().includes(search.toLowerCase())
            )
            .map((item, index) => (
              <TableRow key={item.dapil_nama}>
                <TableCell className='px-0' align='center'>
                  {getTableRowNumber(index)}
                </TableCell>
                <TableCell className='px-0 capitalize' align='left'>
                  {item.dapil_nama}
                </TableCell>
                <TableCell className='px-0' align='center'>
                  {item.tingkat}
                </TableCell>
                <TableCell className='px-0' align='center'>
                  {item.tahun}
                </TableCell>
                <TableCell className='px-0' align='center'>
                  {item.suara_caleg}
                </TableCell>
                <TableCell className='px-0' align='center'>
                  {item.suara_partai}
                </TableCell>
                <TableCell align='center'>
                  <Link to={'/pemilu_terakhir/rekap_suara/' + item.code}>
                    <GeneralButton
                      variant='contained'
                      color='primary'
                      text='Dashboard'
                      otherClass='radius-10'
                    />
                  </Link>
                </TableCell>
              </TableRow>
            ))
        ) : (
          <TableRow>
            <TableCell colSpan={7} align='center'>
              Data kosong
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}

export default TableProvince
