import React, { useState, useEffect } from "react";
import { Card, Grid, InputLabel, TextField } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import TablePembayaran from "./TablePembayaran";
import GeneralButton from "app/components/GeneralButton";
import { CheckCircleOutline } from "@material-ui/icons";
import { saveContactPaymentConfirmation, saveNominalSubscription  } from "app/redux/actions/PengaturanActions";
import ModalError from "app/components/modal/ModalError";
import ModalSuccess from "app/components/modal/ModalSuccess";

const Setting = () => {
    const [errorModal, setErrorModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const history = useHistory();
    const [state, setState] = useState({
        contact_email: '',
        contact_phone: '',
        subscription_nominal: '',
    });
    const [errorField, setErrorField] = useState({});


    const handleChange = (e) => {
        e.persist();
        const { name, value } = e.target;

        setState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
    };

    const getData = () => {
    };

    useEffect(() => {
        // getData();
    }, []);

    const handleSubmit = (save, message) => {
        save(state)
        .then((res) => {
          setSuccessModal(true)
          setSuccessMessage(message)
          setState({
            contact_email: '',
            contact_phone: '',
            subscription_nominal: '',
          });
          history.push("/master_data/setting");
        })
        .catch((err) => {
          setErrorField(err.response.data.data);
          setErrorModal(true);
        });
        setSuccessModal(true)
        setErrorModal(true)
        console.log(state);
    }

    const handleCloseModal = () => {
        setErrorModal(false);
        setSuccessModal(false);
    };

    return (
        <div className="p-40">
            <div className="mb-sm-30">
                <h2 className="fw-700 fw-bold">Setting</h2>
            </div>

            <Card className="w-100 overflow-auto p-24">
                <div className="mb-sm-30">
                    <h2 className="h2-subtitle">Bank Pembayaran</h2>
                </div>
                <TablePembayaran />
            </Card>

            <Card style={{ padding: "20px", marginBottom: "20px" }}>
                <Grid container className="mt-2 p-2" spacing={2}>
                    <Grid
                        item
                        lg={12}
                        md={12}
                        xs={12}
                        sm={12}
                        className="flex justify-content-between align-items-center flex-wrap"
                    >
                        <h2 className="h2-subtitle">Contact Konfirmasi Pembayaran</h2>
                        <GeneralButton
                            variant="contained"
                            color="primary"
                              onClick={() => handleSubmit(saveContactPaymentConfirmation, 'Berhasil Menyimpan Contact Konfrimasi Pembayaran')}
                            icon={<CheckCircleOutline />}
                            text="Simpan"
                        />
                    </Grid>

                    <Grid item lg={6} md={6} xs={12} sm={12}>
                        <InputLabel htmlFor="contact_email">
                            <h5 className="font-size-14">Email</h5>
                        </InputLabel>
                        <TextField
                            required={true}
                            size="small"
                              value={state.contact_email}
                            name="contact_email"
                            className={`w-100`}
                            InputProps={{
                                style: {
                                    borderRadius: 5,
                                },
                            }}
                            placeholder="Masukan Email"
                            variant="outlined"
                              onChange={handleChange}
                            type="text"
                        />
                        <small className="text-danger">{errorField?.contact_email}</small>
                    </Grid>

                    <Grid item lg={6} md={6} xs={12} sm={12}>
                        <InputLabel htmlFor="contact_phone">
                            <h5 className="font-size-14">Nomor Telepon</h5>
                        </InputLabel>
                        <TextField
                            required={true}
                            size="small"
                              value={state.contact_phone}
                            name="contact_phone"
                            className={`w-100`}
                            InputProps={{
                                style: {
                                    borderRadius: 5,
                                },
                            }}
                            placeholder="Masukan Nomor Telepon"
                            variant="outlined"
                              onChange={handleChange}
                            type="text"
                        />
                        <small className="text-danger">{errorField?.contact_phone}</small>
                    </Grid>
                </Grid>
            </Card>

            <Card style={{ padding: "20px", marginBottom: "20px" }}>
                <Grid container className="mt-2 p-2" spacing={2}>
                    <Grid
                        item
                        lg={12}
                        md={12}
                        xs={12}
                        sm={12}
                        className="flex justify-content-between align-items-center flex-wrap"
                    >
                        <h2 className="h2-subtitle">Paket Berlangganan</h2>
                        <GeneralButton
                            variant="contained"
                            color="primary"
                              onClick={() => handleSubmit(saveNominalSubscription, 'Berhasil Menyimpan Paket Berlangganan')}
                            icon={<CheckCircleOutline />}
                            text="Simpan"
                        />
                    </Grid>

                    <Grid item lg={6} md={6} xs={12} sm={12}>
                        <InputLabel htmlFor="subscription_nominal">
                            <h5 className="font-size-14">Harga Paket</h5>
                        </InputLabel>
                        <TextField
                            required={true}
                            size="small"
                              value={state.subscription_nominal}
                            name="subscription_nominal"
                            className={`w-100`}
                            InputProps={{
                                style: {
                                    borderRadius: 5,
                                },
                            }}
                            placeholder="Masukan Harga Paket"
                            variant="outlined"
                              onChange={handleChange}
                            type="text"
                        />
                        <small className="text-danger">{errorField?.subscription_nominal}</small>
                    </Grid>
                </Grid>
            </Card>

            <ModalError
                open={errorModal}
                handleClose={handleCloseModal}
            />
            <ModalSuccess
                open={successModal}
                handleClose={handleCloseModal}
                message={successMessage}
            />
        </div>
    );
};

export default Setting;
