import { Card } from "@material-ui/core";
import React from "react";
import TableProvinsi from "./provinsi/TableProvinsi";
import TableKotaKab from "./kota_kabupaten/TableKotaKab";
import TableKecamatan from "./kecamatan/TableKecamatan";
import TableKelurahan from "./kelurahan/TableKelurahan";

const MasterDataDaerah = () => {
  return (
    <>
      <Card className="w-100 overflow-auto p-24 mb-3" elevation={6}>
        <TableProvinsi />
        <TableKotaKab />
        <TableKecamatan />
        <TableKelurahan />
      </Card>
    </>
  );
};

export default MasterDataDaerah;
