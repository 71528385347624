/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Grid,
  TableContainer,
  Icon,
  InputAdornment,
  TextField,
  Box,
} from "@material-ui/core";
import shortid from "shortid";
import MenuActions from "../components/MenuActions";
import { connect, useDispatch, useSelector } from "react-redux";
import GeneralButton from "app/components/GeneralButton";
import { Import } from "app/components/icon";
import ModalImport from "./components/ModalImport";
import { Link } from "react-router-dom";
import { AddCircleOutline } from "@material-ui/icons";
import Swal from "sweetalert2";
import { getDpt, importDpt } from "app/redux/actions/DptActions";
import { useQueryParams } from "helpers/useQueryParams.hook";

const TableDPT = () => {
  const dispatch = useDispatch();
  const { queryParams, setQueryParams } = useQueryParams(
    {
      page: 1,
      limit: 10,
      search: "",
      tanggal: "",
      waktu: "",
      status: "",
      relawan_min: "",
      relawan_max: "",
    },
    {
      page: 1,
      limit: 10,
      search: "",
      tanggal: "",
      waktu: "",
      status: "",
      relawan_min: "",
      relawan_max: "",
    }
  );
  const [state, setState] = useState({
    loading: true,
    searchTgl: 0,
    page: 0,
    rowsPerPage: 10,
    rowsPerPageOptions: [5, 10, 50, 100],
  });
  const [openImport, setOpenImport] = useState(false);
  const { dataDpt, dataDptPagination } = useSelector(
    state => state.dpt
  );

  function handleSearch({
    page,
    limit,
    search,
    tanggal,
    waktu,
    status,
    relawan_min,
    relawan_max
  }) {
    setQueryParams({
      ...(page && { page }),
      ...(limit && { limit }),
      ...(search && { search }),
      ...(tanggal && { tanggal }),
      ...(waktu && { waktu }),
      ...(status && { status }),
      ...(relawan_min && { relawan_min }),
      ...(relawan_max && { relawan_max }),
    });
  }

  const getData = () => {
    setState((prevState) => ({ ...prevState, loading: true }));

    const { ...filteredParams } = queryParams;
    dispatch(getDpt(filteredParams))
      .then(() => {
        setState((prevState) => ({ ...prevState, loading: false }));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setState((prevState) => ({ ...prevState, loading: false }));
      });
    console.log(filteredParams)
    dispatch(getDpt(filteredParams));
  };

  const [search, setSearch] = useState("");

  const getTableRowNumber = (index) => {
    return state.page * queryParams.limit + index + 1;
  };

  const updatePagePagination = () => {
    setState({ ...state, page: 0, paramsChange: !state.paramsChange });
  };

  const handleSearchChange = (e) => {
    const { name, value } = e.target;

    const updatedSearch = {
      ...queryParams,
      page: 1,
      [name]: value,
    };

    updatePagePagination();
    handleSearch(updatedSearch);
  };

  const submitSearch = (event) => {
    if (event.keyCode === 13 || event.which === 13) {
      setState({ ...state, paramsChange: !state.paramsChange });
    }
  };

  const setRowsPerPage = (event) => {
    const newLimit = event.target.value;
    handleSearch({ ...queryParams, page: 1, limit: newLimit });
    updatePagePagination();
  };

  const handleChangePage = (event, newPage) => {
    handleSearch({
      ...queryParams,
      page: newPage + 1,
      limit: queryParams.limit,
    });
    setState({ ...state, page: newPage, paramsChange: !state.paramsChange });
  };

  const handleClose = () => {
    setOpenImport((prev) => !prev);
  };

  const handleSubmitImport = (data) => {
    importDpt(data)
      .then((res) => {
        Swal.fire("Berhasil", "Data berhasil diimport", "success");
        handleClose();
        getData();
      })
      .catch((err) => {
        Swal.fire("Gagal", "Data gagal diimport", "error");
        handleClose();
      });
  };

  useEffect(() => {
    getData();
  }, [dispatch, state.paramsChange]);

  return (
    <>
      <Grid
        container
        spacing={3}
        className="mb-2 d-flex justify-content-between align-items-center"
      >
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TextField
            size="small"
            variant="outlined"
            color="primary"
            fullWidth
            placeholder="Cari"
            onChange={handleSearchChange}
            onKeyDown={submitSearch}
            value={queryParams.search}
            name="search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon color="disabled">search</Icon>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6} container spacing={2} justify="flex-end">
          <Grid item>
            <GeneralButton
              variant="outlined"
              color="primary"
              textColor="#9E9E9E"
              border="1px solid #9E9E9E"
              text="Import DPT"
              icon={<Import color="#9E9E9E" />}
              // info="Unggah file Excel"
              onClick={handleClose}
            />
          </Grid>
          <Grid item>
            <Link to="/master_data/data_lainnya/dpt/tambah">
              <GeneralButton
                variant="contained"
                color="primary"
                text="Tambah DPT"
                icon={<AddCircleOutline fontSize="small" />}
              />
            </Link>
          </Grid>
        </Grid>
      </Grid>

      <Grid item lg={12} md={12}>
        <TableContainer>
          <Table style={{ minWidth: 700 }}>
            <TableHead className="t-header">
              <TableRow>
                <TableCell align="center">No</TableCell>
                <TableCell align="center">Nama Daftar Pemilih Tetap</TableCell>
                <TableCell align="center">Total</TableCell>
                <TableCell align="center">Aksi</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="t-body">
              {state.loading ? (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    Loading ...
                  </TableCell>
                </TableRow>
              ) : dataDpt?.length > 0 ? (
                dataDpt
                  // .filter((item) =>
                  //   item.daftar_pemilih_tetap_nama
                  //     .toLowerCase()
                  //     .includes(search.toLowerCase())
                  // )
                  // .slice(
                  //   state.page * state.rowsPerPage,
                  //   state.page * state.rowsPerPage + state.rowsPerPage
                  // )
                  .map((item, index) => (
                    <TableRow key={index}>
                      <TableCell className="px-0" align="center">
                        {getTableRowNumber(index)}
                      </TableCell>
                      <TableCell className="px-0" align="center">
                        {item.daftar_pemilih_tetap_nama}
                      </TableCell>
                      <TableCell className="px-0" align="center">
                        {item.daftar_pemilih_tetap_total}
                      </TableCell>
                      <TableCell align="center">
                        <MenuActions
                          editPath={`/master_data/data_lainnya/dpt/edit/${item.daftar_pemilih_tetap_code}`}
                          item={item}
                          state={item}
                        />
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    Data kosong
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>

          <div style={{ minWidth: 700 }}>
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" flexDirection="row" p={1} alignItems="center">
                <Box p={1}>
                  <div className="flex">
                    <p>Rows per page : </p>
                    <select
                      labelid="simple-select"
                      value={queryParams.limit}
                      name="rowsPerPage"
                      onChange={setRowsPerPage}
                      variant="standard"
                      className="border-none"
                    >
                      {state.rowsPerPageOptions.map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                </Box>
              </Box>
              <Box>
                <TablePagination
                  className="px-16"
                  rowsPerPageOptions={[]}
                  component="div"
                  count={dataDptPagination.total || 0}
                  rowsPerPage={queryParams.limit}
                  labelRowsPerPage={""}
                  page={dataDptPagination.page - 1 || 0}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={setRowsPerPage}
                />
              </Box>
            </Box>
          </div>
        </TableContainer>
      </Grid>

      <ModalImport
        open={openImport}
        handleClose={handleClose}
        handleSubmit={handleSubmitImport}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    dataDpt: state.dpt.dataDpt,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getDpt: () => dispatch(getDpt()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableDPT);
