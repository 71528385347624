// import { FileCopy, Wallpaper } from "@material-ui/icons";
import React, { useState } from "react";
import Swal from "sweetalert2";

const InputFileImg = ({
  uploadFoto,
  acceptFile,
  maxSize,
  preview,
  noPadding = false,
  disabled = false,
}) => {
  const [FileName, setFileName] = useState(null);
  const FileType = {
    pdf: "application/pdf",
    image: "image/*, image/jpg, image/jpeg, image/png",
    doc: ".doc, .docx",
    pdfImg: "application/pdf, image/*, image/jpg, image/jpeg",
    ppt: ".ppt, .pptx",
    excel: ".xlsx, .csv",
  };
  let fileInput = React.createRef();

  const getExtension = (filename) => {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  };

  const checkImage = (filename) => {
    var ext = getExtension(filename);
    if (acceptFile === "pdf") {
      switch (ext.toLowerCase()) {
        case "pdf":
          return true;
        default:
          return false;
      }
    } else if (acceptFile === "image") {
      switch (ext.toLowerCase()) {
        case "jpg":
        case "png":
        case "jpeg":
          return true;
        default:
          return false;
      }
    } else if (acceptFile === "doc") {
      switch (ext.toLowerCase()) {
        case "doc":
        case "docx":
          return true;
        default:
          return false;
      }
    } else if (acceptFile === "pdfImg") {
      switch (ext.toLowerCase()) {
        case "jpg":
        case "jpeg":
        case "pdf":
          return true;
        default:
          return false;
      }
    } else if (acceptFile === "ppt") {
      switch (ext.toLowerCase()) {
        case "ppt":
        case "pptx":
          return true;
        default:
          return false;
      }
    } else if (acceptFile === "excel") {
      switch (ext.toLowerCase()) {
        case "xlsx":
        case "csv":
          return true;
        default:
          return false;
      }
    }
  };

  const kbConverter = (size) => {
    return size * 1024;
  };

  const handleImageChange = (e) => {
    let files = e.target.files || e.dataTransfer.files;

    if (!files.length) return;
    if (checkImage(e.target.files[0].name)) {
      let file = files[0];
      let reader = new FileReader();
      if (maxSize && kbConverter(maxSize) < file.size) {
        Swal.fire({
          icon: "warning",
          title: "Error",
          text: "the file is too large",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }
      reader.readAsDataURL(file);
      const initFile = URL.createObjectURL(file);
      setFileName(file.name);
      reader.onloadend = () => {
        uploadFoto(reader.result, initFile);
      };
    } else {
      Swal.fire({
        icon: "warning",
        title: "Error",
        text: "Invalid File Format",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const getPlaceholderMessage = () => {
    let type = "";
    switch (acceptFile) {
      case "pdf":
        type = "file PDF";
        break;
      case "image":
      case "pdfImg":
        type = "foto";
        break;
      case "doc":
        type = "file Document";
        break;
      case "ppt":
        type = "file PPT";
        break;
      case "excel":
        type = "file Excel";
        break;
      default:
        type = "file";
        break;
    }

    return `Seret & jatuhkan ${type} di sini atau`
  }
  return (
    <div className={noPadding ? "" : "wrap-border-file"}>
      <div className="_input-file d-flex align-items-center justify-content-center flex-column position-relative py-5">
        {preview ? (
          <div className="container-preview w-100">
            <img
              src={preview}
              alt="preview"
              className="w-100 h-100 img-preview"
            />
          </div>
        ) : (
          <div className="content"></div>
        )}
        <div
          className={`position-absolute d-flex flex-column justify-content-center align-items-center h-100 w-100 ${preview ? "preview-active" : ""
            }`}
        >
          {!disabled ? (
            <>
              {acceptFile === "image" ? (
                <img alt="wallpaper" src="/assets/images/illustrations/wallpaper.png" />
              ) : (
                <img alt="document" src="/assets/images/illustrations/document.png" />
              )}
              <span className="placeholder-file">
                <h6 className="m-0 text-center">
                  {FileName ? FileName : getPlaceholderMessage()}
                </h6>
              </span>
              <span className="placeholder-file">
                <h6 className="text-main text-decoration-underline">
                  {FileName ? FileName : "Klik Disini"}
                </h6>
              </span>
              <input
                type="file"
                name="foto"
                onChange={handleImageChange}
                ref={fileInput}
                accept={FileType[acceptFile]}
                disabled={disabled}
              />
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default InputFileImg;
