import { API, setAuthToken } from "config/API.js";
import { GET_ALL_CITY, GET_CITY, GET_CITY_NAME } from "../constant.js";

export const getAllCity = (params = "") => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("jwt_token");
      setAuthToken(token);
      const res = await API.post(`/city?${new URLSearchParams(params)}`);
      dispatch({
        type: GET_ALL_CITY,
        payload: res.data || [],
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_CITY,
        payload: [],
      });
      throw error;
    }
  };
};

export const getCity = (body) => {
  return (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.post(`/city/list`, body)
      .then((res) => {
        dispatch({
          type: GET_CITY,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_CITY,
          payload: [],
        });
      });
  };
};

export const getCityName = (body) => {
  return (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.post(`/city-name`, body)
      .then((res) => {
        dispatch({
          type: GET_CITY_NAME,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_CITY_NAME,
          payload: [],
        });
      });
  };
};

export const addCity = (body) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.post("/admin/city/tambah", body);
}

export const updateCity = (body) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.post("/admin/city/update", body);
}
