import React from 'react'
import ReactLoading from 'react-loading'

export default function Loader () {
  return (
    <div className='w-full'>
      <ReactLoading
        type='spin'
        color='#000'
        height={20}
        width={20}
        className='mx-auto'
      />
    </div>
  )
}
