import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Card,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { Box } from "@mui/material";
import Swal from "sweetalert2";
import { addDistrict } from "app/redux/actions/DistrictActions";
import { getCity } from "app/redux/actions/CityActions";
import { getProvince } from "app/redux/actions/ProvinceActions";
import HeadTitle from "../../components/HeadTitle";

const AddKecamatan = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState({
    province_id: "",
    regency_id: "",
    name: "",
  });
  const [errorField, setErrorField] = useState({});
  const { dataProvince } = useSelector(
    (state) => state.province
  );
  const { dataCity } = useSelector(
    (state) => state.city
  );

  useEffect(() => {
    dispatch(getProvince());
    dispatch(getCity({
      province_code: state.province_id,
    }));
  }, [dispatch, state.province_id]);

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.name === "name" ?
        e.target.value.toUpperCase() :
        e.target.value,
    }));
  };

  const handleSubmit = () => {
    addDistrict(state)
      .then((res) => {
        console.log(res);
        Swal.fire({
          title: '<div class="custom-swal-title">Data berhasil disimpan</div>',
          buttonsStyling: false,
          icon: "success",
          customClass: {
            confirmButton: "custom-success-button",
          },
        });
        history.push("/master_data/data_lainnya");
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: '<div class="custom-swal-title">Data gagal disimpan!</div>',
          buttonsStyling: false,
          icon: "error",
          customClass: {
            confirmButton: "custom-error-button",
          },
        });
        setErrorField(err.response.data.data);
      });
  };

  return (
    <>
      <Box className="p-40">
        <HeadTitle
          title="Tambah Nama Kecamatan"
          onClick={handleSubmit}
          backPath="/master_data/data_lainnya"
          btnText="Simpan Data"
        />

        <Card className="w-100 overflow-auto p-24 mb-3">
          <Grid container className="p-2 " spacing={2}>
            <Grid item lg={6} md={6} xs={12} sm={12}>
              <InputLabel htmlFor="province_id">
                <h5 className="font-size-14">Nama Provinsi</h5>
              </InputLabel>
              <Select
                SelectDisplayProps={{
                  style: {
                    paddingTop: 10,
                    paddingBottom: 10,
                    borderRadius: 5,
                  },
                }}
                size="small"
                labelid="province_id"
                value={state.province_id}
                onChange={handleChange}
                variant="outlined"
                className="w-100"
                name="province_id"
                displayEmpty
              >
                <MenuItem key={"x"} value={""} disabled>
                  Pilih Provinsi
                </MenuItem>
                {dataProvince?.map((item) => (
                  <MenuItem key={item.id} value={item.id} selected={item.id === state.province_id}>{item.name}</MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item lg={6} md={6} xs={12} sm={12}>
              <InputLabel htmlFor="regency_id">
                <h5 className="font-size-14">Nama Kota Kabupaten</h5>
              </InputLabel>
              <Select
                SelectDisplayProps={{
                  style: {
                    paddingTop: 10,
                    paddingBottom: 10,
                    borderRadius: 5,
                  },
                }}
                size="small"
                labelid="regency_id"
                value={state.regency_id}
                onChange={handleChange}
                variant="outlined"
                className="w-100"
                name="regency_id"
                displayEmpty
              >
                <MenuItem key={"x"} value={""} disabled>
                  Pilih Kota Kabupaten
                </MenuItem>
                {dataCity?.map((item) => (
                  <MenuItem key={item.id} value={item.id} selected={item.id === state.regency_id}>{item.name}</MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item lg={6} md={6} xs={12} sm={12}>
              <InputLabel htmlFor="name">
                <h5 className="font-size-14">Nama Kecamatan</h5>
              </InputLabel>
              <TextField
                required={true}
                size="small"
                name="name"
                labelid="name"
                className={`w-100`}
                value={state.name}
                InputProps={{
                  style: {
                    borderRadius: 5,
                  },
                }}
                placeholder="Nama Kecamatan"
                variant="outlined"
                type="text"
                error={errorField?.name !== ""}
                helperText={errorField?.name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item lg={6} md={6} xs={12} sm={12}></Grid>
          </Grid>
        </Card>
      </Box>
    </>
  );
};

export default AddKecamatan;
